import React from 'react'
import { i18n } from 'shared/i18n'
import Tabs from 'react-materialize/lib/Tabs'
import Tab from 'react-materialize/lib/Tab'
import {
    Selector
} from './common'
import {
    logError,
} from '../utils'
import './ItemsTabs.css'

// export const ItemsTabs = ({ items, activeItemId, onItemSelect, children }) => {
export class ItemsTabs extends React.Component {
    componentDidMount() {
        // console.log('ItemsTabs componentDidMount', JSON.stringify(this.props))
    }
    render() {
        let { items, activeItemId, onItemSelect, collapseIfMoreThan, children } = this.props
        // console.log('ItemsTabs items', items, activeItemId)
        const onChange = (idx, e) => {
            const itemName = e.target.text
            let item = items.find(it => i18n().t(it.name) === itemName)
            if (!item) {
                logError('Item not found on tab change', { target: e.target, idx: idx })
                return alert('Sorry, there was an error. Please refresh and try again')
            }
            setTimeout(() => onItemSelect(item.id), 250) // let the animation go
        }
        if (!collapseIfMoreThan || items.length <= collapseIfMoreThan) return (
            <div className="items-tabs">
                <Tabs className="tabs-fixed-width z-depth-1"
                    onChange={onChange}
                    tabOptions={{
                        //swipeable: true,
                    }}
                    key={'tabs' + Date.now()} // TODO it's needed because active tab is not highlighted otherwise
                >
                    {items.map(it =>
                        <Tab key={it.name} title={i18n().t(it.name)} active={it.id === activeItemId}>
                            {children}
                        </Tab>)
                    }
                </Tabs>
            </div>
        )
        return <div className="items-selector">
            <Selector
                items={items.map(it => ({ id: it.id, name: i18n().t(it.name) }))}
                selected={activeItemId}
                onSelect={itemId => onItemSelect(itemId)}
            />
        </div>
    }
}