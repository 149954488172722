import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import {
    notificationsEnabled, notificationsDisabled,
} from '../reducers/user_profile'
import Button from 'react-materialize/lib/Button'
import Card from 'react-materialize/lib/Card'
import Icon from 'react-materialize/lib/Icon'
import './AskAboutNotifications.css'

class _AskAboutNotifications extends React.Component {
    render() {
        let { description, onEnable, onDisable } = this.props
        return <div className='ask-about-notifications'>
            <Card
                actions={[
                    <Button flat key='no' onClick={onDisable}><Trans>Don't notify</Trans></Button>,
                    <Button flat key='yes' onClick={onEnable}><Trans>Yes, notify me</Trans></Button>
                ]}
            >
                <div className="title"><Icon small>notifications</Icon>&nbsp;&nbsp;<span><Trans>Push Notifications</Trans></span></div>
                <br />
                <p className="flow-text"><Trans>{description}</Trans></p>
                <br />
                <p className="flow-text"><Trans>Would you like to receive notifications on this device?</Trans></p>
            </Card>
        </div>
    }
}

export const AskAboutNotifications = connect(
    null,
    dispatch => {
        return {
            onEnable: () => dispatch(notificationsEnabled()),
            onDisable: () => dispatch(notificationsDisabled()),
        }
    }
)(_AskAboutNotifications)
