export const CHANGE_DAYS_OFFSET = 'CHANGE_DAYS_OFFSET'
export const changeDaysOffset = daysOffset => ({
    type: CHANGE_DAYS_OFFSET,
    daysOffset,
})

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const changeLanguage = language => ({
    type: CHANGE_LANGUAGE,
    language,
})

export const NEW_VERSION_AVAILABLE = 'NEW_VERSION_AVAILABLE'
export const newVersionAvailable = (value = true) => ({
    type: NEW_VERSION_AVAILABLE,
    value
})


export const REFRESH_ALL = 'REFRESH_ALL'
export const refreshAll = (heartbeat = Date.now()) => ({
    type: REFRESH_ALL,
    heartbeat,
})

export const CHANGE_LOCATION = 'CHANGE_LOCATION'
export const changeLocation = (country, city) => ({
    type: CHANGE_LOCATION,
    country,
    city,
})
export const AUTODETECT_LOCATION = 'AUTODETECT_LOCATION'
export const autodetectLocation = () => ({
    type: AUTODETECT_LOCATION,
})

export const APPLY_SITE_ID = 'APPLY_SITE_ID'
export const applySiteId = siteId => ({
    type: APPLY_SITE_ID,
    siteId,
})
export const APPLY_ADMIN_SITE_ID = 'APPLY_ADMIN_SITE_ID'
export const applyAdminSiteId = siteId => ({
    type: APPLY_ADMIN_SITE_ID,
    siteId,
})


const defaultState = {
    daysOffset: 0,
    language: 'en',
    newVersionAvailable: false,
    heartbeat: 0,
    //TODO move to separate reducer - loader, maybe in place of sites
    country: null,
    city: null,
    applySiteId: null,
    applyAdminSiteId: null,
}

export const reducerUI = (state = defaultState, action) => {
    switch (action.type) {
        case CHANGE_DAYS_OFFSET: {
            const { daysOffset } = action
            return { ...state, daysOffset }
        }
        case CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.language,
            }
        }
        case NEW_VERSION_AVAILABLE: {
            return {
                ...state,
                newVersionAvailable: action.value,
            }
        }
        case REFRESH_ALL: {
            return {
                ...state,
                heartbeat: action.heartbeat,
            }
        }
        case CHANGE_LOCATION: {
            const { country, city } = action
            return {
                ...state,
                country,
                city,
            }
        }
        case APPLY_SITE_ID: {
            return {
                ...state,
                applySiteId: action.siteId,
            }
        }
        case APPLY_ADMIN_SITE_ID: {
            return {
                ...state,
                applyAdminSiteId: action.siteId,
            }
        }
        default:
            return state
    }
}