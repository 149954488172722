
import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next';
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'
import {
    ContactUs, ContactUsButton,
} from './common/ContactUs'
import {
    NavLink,
} from 'react-router-dom'
import './WhyBookingGood.css'

export const RegisterButton = ({ Link }) => <a href={`/pricing`}>
    <Button>
        <Trans>Register sport club</Trans>
    </Button>
</a>

const cardsDataEn = [
    {
        icon: 'event',
        title: 'Clients can book online',
        rows: [
            'Search by club or sport in your city, convenient filters for coach. Find new clients and decrease losses for late cancellation.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'settings',
        title: 'Manage your club, facilities and bookings',
        rows: [
            'Manage everything from your desktop or mobile. Flexible settings and customization of facilities, coaches and bookings.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'code',
        title: 'Embedding into site and integrations',
        rows: [
            'Clients can make online reservations directly from your site. API for integrations with 3rd party systems, custom development.'
        ],
        action: '__contact_us__',
    },
    // {
    //     icon: 'build',
    //     title: 'Custom booking engine for your business',
    //     rows: [
    //         'Use our API to manage availability and bookings of your business',
    //     ],
    //     action: '__contact_us__',
    // },
]
const cardsDataBg = [
    {
        icon: 'event',
        title: 'Клиентите могат да запазят час онлайн',
        rows: [
            'Търсене по спорт или клуб в твоя град, удобни филтри за треньор. Намери нови клиенти и минимизирай загубите от късната отмяна на часовете.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'settings',
        title: 'Управление на клуба, програмата и клиентите',
        rows: [
            'Управлявай всичко от компютъра или мобилното си устройство. Гъвкави настройки за спортовете, съоръженията, треньорите и резервациите.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'code',
        title: 'Вграждане в съществуващ сайт и интеграции',
        rows: [
            'Клиентите могат да правят резервации директно от сайта ти. API за интеграции с външни системи, възможност за допълнителна разработка.'
        ],
        action: '__contact_us__',
    },
    // {
    //     icon: 'build',
    //     title: 'Разработка на система за резервации',
    //     rows: [
    //         'Можем да разработим специализирана система за резервации за вашия бизнес.',
    //         'Може да използвате и само нашето API за управление на вашите ресурси и резервации.',
    //     ],
    //     action: '__contact_us__',
    // },
]
const cardsDataRu = [
    {
        icon: 'event',
        title: 'Клиенты могут резервировать онлайн',
        rows: [
            'Поиск по спорту или клубу в вашем городе, удобные фильтры для тренеров. Найдите новых клиентов и уменьшите убытки при поздней отмене резерваций.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'settings',
        title: 'Управление клубом, расписанием и резервациями',
        rows: [
            'Доступ с компьютера или мобильного устройства. Удобные настройки расписания услуг клуба и тренеров, извещения о важных событиях.',
        ],
        action: '__read_more__',
    },
    {
        icon: 'code',
        title: 'Возможность встраивания в существующий сайт и интеграции',
        rows: [
            'Клиенты могут резервировать непосредственно с вашего сайта. Есть API для интеграции с внешними системами, возможность дополнительной разработки.'
        ],
        action: '__contact_us__',
    },
]
const cardsData = {
    'en': cardsDataEn,
    'bg': cardsDataBg,
    'ru': cardsDataRu,
}

const __WhyBookingGoodCards = ({ language, readMoreLink }) => {
    const actionLink = data => {
        switch (data.action) {
            case '__contact_us__': return <ContactUs />
            case '__read_more__': return <a href={readMoreLink}><Trans>Read more</Trans>...</a>
            default:
                return ''
        }
    }
    let content = (cardsData[language] || cardsData['en']).map((data, i) => {
        return <div className="why-card z-depth-1" key={i}>
            <div className="why-card-icon">
                <Icon medium>{data.icon}</Icon>
            </div>
            <div className="why-card-heading">{data.title}</div>
            <div className="why-card-content">
                <ul>
                    {data.rows.map((row, ii) => <li key={ii}>{row}</li>)}
                </ul>
            </div>
            <div className="why-card-action">{actionLink(data)}</div>
        </div>
    })
    return <div className="why-bg-cards">
        {content}
    </div>
}

export const WhyBookingGoodCards = connect(
    (state, props) => ({
        language: state.ui.language,
        readMoreLink: props.readMoreLink,
    })
)(__WhyBookingGoodCards)

export const RegisterSection = () => {
    return <div id="register-section" className="register-section">
        <div className="question">
            <Trans>Do you need online booking system for your club?</Trans>&nbsp;<a href="#register-section" onClick={e => {
                e.preventDefault()
                document.getElementById('register-section').scrollIntoView({ behavior: 'smooth' })
            }}><Trans>Learn more</Trans><Icon>expand_more</Icon></a>
        </div>
        <div>
            <WhyBookingGoodCards readMoreLink="/why-booking-good" />
        </div>
        <div className="register-club">
            {/* <RegisterButton /> */}
            <ContactUsButton />
        </div>
    </div>
}


const whysEn = {
    heading: 'Why Booking Good?',
    data: [
        "Welcome to BookingGood!",
        "We're a young company trying to make the world a better place by... building a platform for online reservations and club management. Our purpose is to make the reservation process quick and easy for both businesses and customers.",
        "We love modern technologies and we're not afraid to use them to build a great platform. We hope you'll join us on this journey.",
        " ",
        "So how can we help your business?",
        {
            title: 'Schedule and bookings updated in realtime',
            body: 'You don\'t have to refresh the site to get the actual information. What you see is always up to date.'
        },
        {
            title: 'Find new clients',
            body: 'Your club is visible to all site\'s visitors in your city - new people will learn about you and your services and may become your clients.'
        },
        {
            title: 'Notification for hour becoming available',
            body: 'Some hours are more desirable for the clients and are usually booked when they want them. They can watch the busy time period they are interested in and will receive a notification if there is a cancellation.'
        },
        {
            title: 'Recover from late cancellation',
            body: 'Sometimes a clients can cancel at the last moment - fortunately your clients who want this hour will receive a notification and someone will be able to book it. It\'s a great way to minimize the losses from late cancellations and make your clients happier.'
        },
        {
            title: 'Ability to use the platform for part of your space',
            body: 'You doubt that the platform will be right fit for you and are not ready to migrate to it? We totally understand! You can try it for only part of your facilities. Want to use it only for recovering from late cancellations? That\'s fine too! Your clients can watch they hours they want and receive a notification when you announce freed space. And if you decide to move to our system, this will happen automatically.'
        },
        {
            title: 'Flexible configurations of work time, prices and activities',
            body: 'It can be set individually for each sport facility or coach. We also support vacations and different work times for special occasions.'
        },
        {
            title: 'Recurring bookings',
            body: "Set recurring bookings for your regular clients. If they cannot make it on a specific date it's easy to cancel just this occasion."
        },
        {
            title: 'Verified phone numbers',
            body: "Clients' phone numbers are verified to reduce the fraud risk. Each new client is allowed to make a limited number of bookings. Bad guys can be blocked."
        },
        {
            title: 'Use the site from Web and Mobile',
            body: "The site is specifically adapted for mobile usage to provide a great experience. Just open it in the web browser on your mobile device and choose Add to Home screen."
        },
        {
            title: 'Bookings with confirmation',
            body: "Depending on the scale and specifics of your business you may want to approve bookings made on the short notice or every single one."
        },
        {
            title: 'Push and desktop notifications',
            body: "You may choose to receive notifications for any new and cancelled bookings, and the ones requiring confirmation."
        },
        {
            title: 'Real-time sync with Google Calendar',
            body: "You and your personnel are used to seeing your bookings in Google Calendar? Good. You can activate real-time synchronization with Google Calendar - all your bookings and recurring bookings, as well as cancellations are synced within 5-10 seconds."
        },
        {
            title: 'Migration from other platforms',
            body: "We can assist you with migration from your existing platform. Migration from Google Calendar is supported out of box."
        },
        {
            title: 'Shared facilities',
            body: "It is possible to have one facility used for different activities. Complex cases with different capacities for each of them are also supported."
        },
        {
            title: 'Embed booking timetable into your site',
            body: "You may choose to embed timetable for all your sports or just selected one. It's also possible to embed upcoming bookings of currently signed user."
        },
        {
            title: 'Developers API',
            body: "We have an API for integration with external platforms. Contact us to learn how."
        },
        {
            title: 'Custom booking system',
            body: "We can develop custom booking system for your business. Contact us to learn how."
        },
        '',
        {
            title: 'FAQ',
            body: ""
        },
        {
            title: 'Do you have a mobile app?',
            body: "You can install the app to your Home screen - just open the site in your web browser and on most devices it will suggest you to install it. You can also install it manually - on Android it's in Chrome menu -> Add to Home screen, on iPhone it's in Share -> Add to Home. This technology is called Progressive Web App and it's embraced by Google and other big companies. For the moment we don't have an app in App Store or Play Store."
        },
        // {
        //     title: 'Of all names why BookingGood?',
        //     body: 'All other good names are already taken. This was the last one left.'
        // }
    ]
}
const whysBg = {
    heading: 'Защо да избереш Booking Good?',
    data: [
        "Здравей, радваме се да те видим на BookingGood!",
        "BookingGood е платформа за управление на спортни клубове и онлайн резервации. Нашата цел е дългият и досаден процес на търсене и запазване на час да стане бърз, лесен и предимно незабележим.",
        "Ние сме млада компания и обичаме да използваме съвременните технологии, за да правим живота по-лесен.",
        " ",
        "Как точно можем да сме полезни на бизнеса ти?",
        {
            title: 'Винаги актуална програма и резервации',
            body: 'Не е нужно да презареждаш страницата, информацията се опреснява автоматично.'
        },
        {
            title: 'Привличане на нови клиенти',
            body: 'Клубът е видим за всички посетители на сайта - така хората научават за вас и вашите услуги и имат възможност да запазят час и да ви станат клиенти.'
        },
        {
            title: 'Известяване когато някой час се освобождава',
            body: 'Някои часове са по-популярни и желани от клиентите, но обикновено са заети и те не могат да ги запазят. За щастие, вече могат да ги наблюдават и да получават нотификации, когато някой час се освободи.'
        },
        {
            title: 'Минимизиране на загуби при късна отмяна на час',
            body: 'Случва се един клиент да отмени час много късно - в този случай други хора, които го искат, ще получат нотификация, че има свободно място и ще могат веднага да го запазят. Това е страхотен начин да се минимизират загубите от късно отменени часове, а клиентите ви ще бъдат по-доволни.'
        },
        {
            title: 'Възможност за използване на платформата за част от часовете ви',
            body: 'Използвате друга платформа и се притеснявате на минете на нещо ново? Разбираме ви напълно! Може да пробвате платформата само с част от вашите ресурси. Искате да се възползвате само от механизъма с известяването на клиентите при късно отменени часове? Ще се радваме да ви бъдем полезни. Клиентите ви ще могат да наблюдават часовете, които ги интересуват и ще получават нотификации, когато вие обявите освободило се място. И ако решите да ползвате платформата ни за всичките си резервации, това ще се случва автоматично.'
        },
        {
            title: 'Гъвкави настройки на работното време, цените и активностите',
            body: 'Можеш да нагласиш работното време на всеки спорт и треньор. Ваканции и промени по време на празници и специални събития също се поддържат.'
        },
        {
            title: 'Постоянни часове',
            body: "Можеш да настройваш постоянни часове за своите клиенти, както и да ги отменяш само за конкретни дати."
        },
        {
            title: 'Проверени телефонни номера',
            body: "Телефонните номера на клиентите се верифицират, за да се предотвратят злоупотреби. Нови клиенти могат да правят ограничено количество резерации, докато не изградят добра история. Некоректните клиенти могат да се блокират."
        },
        {
            title: 'Изполвай сайта на компютъра и от мобилното си устройство',
            body: "Сайтът е адаптиран специално за работа с мобилни устройства. За по-удобно избери Add to Home screen в менюто на браузъра на мобилното си устройство."
        },
        {
            title: 'Одобрение на резервациите',
            body: "В зависимост от спецификата на твоя бизнес, може да избереш да потърждаваш резервациите, които правят клиентите."
        },
        {
            title: 'Мобилни и десктоп нотификации',
            body: "Може да избереш да получаваш нотификации на телефона или на компютъра за всяка нова или отменена резервация, както и за тези, които изискват одобрение. Може да се настрои да е само за случаи, когато остава малко време до началото на резервацията и трябва да реагираш по-бързо."
        },
        {
            title: 'Възможност за синхронизация в реално време с Google Calendar',
            body: "Свикнали сте да ползвате Google Calendar във вашата работа? Поддържаме възможност за синхронизация в реално време на избраните от вас съоръжения. Всичките ви резервации, постоянни часове и отменени часове се синхронизират в Google Calendar до 5-10 секунди."
        },
        {
            title: 'Миграция от други платформи',
            body: "В случай че използваш друга платформа, можем да съдействаме с пренасянето на данните. Импорт от Google Calendar се поддържа стандартно."
        },
        {
            title: 'Споделени съоръжения',
            body: "Възможно е едно съоръжение да се използва за различни спортове по едно и също време и да има различен капацитет за всеки от тях."
        },
        {
            title: 'Вграждане в сайт',
            body: "Графикът за онлайн резервации може да се вгради да се появява директно на сайта на клуба - за всички спортове или за отделен. Съществува опция и да се показват направените резервации на клиента."
        },
        {
            title: 'API',
            body: "Имаме API, което позволява интеграция с външни системи. Свържи се с нас за повече подробности."
        },
        {
            title: 'Разработка на система за резервации за твоя бизнес',
            body: "Имаме възможност да разработим и специализирана система. Свържи се с нас за повече подробности."
        },
        '',
        {
            title: 'Често Задавани Въпроси',
            body: ""
        },
        {
            title: 'Имате ли мобилно приложение?',
            body: "Можеш да инсталираш уеб приложението като отвориш сайта в браузъра на мобилното си устройство - в повечето случаи самият браузър ще предложи да инсталира приложението. Има възможност да се инсталира и ръчно - на Андроид от менюто на браузъра чрез опция Add to Home Screen, на iPhone - през Share -> Add to Home. Технологията се нарича Progressive Web App и се насърчава активно от Google и много други големи компании. В момента нямаме приложение в Apple App Store или Google Play Store."
        },
        // {
        //     title: 'Защо се казвате Booking Good?',
        //     body: 'Имената на услуги за онлайн резервации се избират учудващо трудно. Последното останало хубаво име беше това.'
        // }
    ]
}
const whysRu = {
    heading: 'Почему именно Booking Good?',
    data: [
        "Привет, рады вас видеть на BookingGood!",
        "BookingGood - онлайн платформа для управления клубом и онлайн резерваций. Наша цель - помочь спортивным клубам и их клиентам сделать долгий и трудоемкий процесс поиска и бронирования быстрым и легким для всех.",
        "Мы молодая компания, разбираемся в современных технологиях и стремимся использовать их разумно и еффективно, чтобы создать действительно полезный продукт.",
        " ",
        "Чем мы можем помочь вашему бизнесу?",
        {
            title: 'Всегда актуальные расписание и резервации',
            body: 'Не нужно перезагружать страницу, чтобы увидить последнюю информацию - это происходит автоматически без перезагрузки.'
        },
        {
            title: 'Новые клиенты для вашего клуба',
            body: 'Ваш клуб будет видим для всех посетителей сайта - таким образом они узнают о вас и возможно станут вашими клиентами.'
        },
        {
            title: 'Уведомления об освободившемся месте',
            body: 'Определенные часы более популярны среди клиентов и обыкновенно заняты. Ваши клиенты будут иметь возможность следить за периодами, которые их интересуют и получать уведомления, когда час освобождается.'
        },
        {
            title: 'Поздняя отмена резервации',
            body: 'Случается, что клиент поздно отменяет свою резервацию - к счастью другие ваши клиенты, которые хотят забронировать в это время получат уведомление от том, что место освободилось и смогут его забронировать. Это очень удобный способ справиться с убытками от неожиданных отмен резерваций и ваши клиенты также будут довольны.'
        },
        {
            title: 'Возможность использования платформы частично',
            body: 'Вы ползьзуетесь другим софтуером и вам трудно решиться перейти на другую платформу? Мы все прекрасно понимаем! Мы предвидели возможность попробовать нашу платформу, объявляя только часть ваших ресурсов. Хотите воспользоваться только механизмом уведомлений при подней отмене бронирования? Без проблем, мы рады помочь. Ваши клиенты будуть следить за интересующими их периодами и получат уведомления, когда вы объявите свободное место. И если вы решите перейти на нашу платформу полностью, это будет происходить автоматично.'
        },
        {
            title: 'Удобные настройки услуг клуба и цен, расписания занятий или рабочего времени',
            body: 'У каждого спорта и тренера - свое рабочее время. Изменения в программе во время отпусков, праздников или турниров также поддерживаются.'
        },
        {
            title: 'Постоянные резервации',
            body: "Можно настроить постоянные резервации и при необходимости отменять их в конкретные дни."
        },
        {
            title: 'Подверждение телефонных номеров',
            body: "Телефонные номера клиентов подверждаются отправкой кода по СМС, тем самым повышая надежность платформы и защищая от нелояльных конкурентов. У новых клиентов ограничения в количестве резерваций, есть возможность блокировать недобросовестных клиентов."
        },
        {
            title: 'Одобрение резерваций',
            body: "В зависимости от специфики вашего бизнеса есть возможность подтверждения вами резерваций, которые создают сами клиенты. Можно ограничить подтверждения только для случаев, когда остается мало времени до начала"
        },
        {
            title: 'Доступно с компъютера и с телефона',
            body: "Сайт специально адаптирован для работы с мобилными устройствами. Для большего удобства можно выбрать Add to Home screen в меню браузера на телефоне и использовать сайт как мобильное приложение."
        },
        {
            title: 'Мобильные и десктоп уведомления',
            body: "Есть возможность получения уведомлений на телефоне или на компютере при каждой новой или отмененной резервации, как и для тех, которые ждут подтверждения. Можно ограничить уведомления только для случаев, когда остается мало времени до начала."
        },
        {
            title: 'Синхронизация в реальном времени с Google Calendar',
            body: "Вы привыкли работать с Google Календарем? Нет проблем, мы поддерживаем синхронизацию с ним - все ваши бронирования, постоянные бронирования и отмены будут отражены в Google Календаре в течени 5-10 секунд."
        },
        {
            title: 'Перенос данных со старых систем',
            body: "Если вы пользуетесь другой платформой и желаете перейти к нам, мы можем помочь с переносом данных со старой системы. Есть стандартная поддержка импортирования из Google Calendar."
        },
        {
            title: 'Многофункциональные пространства',
            body: "Возможно, что в зависимости от заявок клиентов одно пространство используется для различных видов спорта или занятий. Можно настроить различную вместимость для каждого из них."
        },
        {
            title: 'Система резерваций на вашем сайте',
            body: "Есть возможность встроить расписание непосредственно в ваш сайт - для всех услуг или для каждой по отдельности. Так ваши клиенты смогуть бронировать час, не выходя с вашего сайта."
        },
        {
            title: 'API',
            body: "У нас есть API, позволяющее интеграцию с внешними системами. Свяжитесь с нами для получения дополнительной информации."
        },
        {
            title: 'Разработка специализированной системы онлайн резерваций для вашего бизнеса',
            body: "Мы можем разработать систему с учетом особенностей вашего бизнеса. Свяжитесь с нами."
        },
        '',
        {
            title: 'Часто задаваемые вопросы',
            body: ""
        },
        {
            title: 'У вас есть мобильное приложение?',
            body: "Есть возможность инсталлировать наше web приложение, открыв сайт в браузере на мобильном устройстве - чаще всего браузер сам предложит инсталлировать приложение. Можно это сделать и вручную - на Андроид выбираете Add to Home Screen в меню браузера, на iPhone - через Share -> Add to Home. Технология, которую мы используем, называется Progressive Web Apps и поддерживается активно компанией Google и многими другими крупными компаниями. На данный момент у нас нет приложения в Apple App Store или Google Play Store."
        },
        // {
        //     title: 'Защо се казвате Booking Good?',
        //     body: 'Имената на услуги за онлайн резервации се избират учудващо трудно. Последното останало хубаво име беше това.'
        // }
    ]
}
const whysByLang = {
    'en': whysEn,
    'bg': whysBg,
    'ru': whysRu,
}

const __WhyBookingGood = ({ language }) => {
    const whyForLang = (lang) => {
        lang = whysByLang[lang] ? lang : 'en'
        const langData = whysByLang[lang]

        return <div style={{ display: language === lang ? 'block' : 'none' }}>
            <h1>{langData.heading}</h1>
            <div className="whys">
                {langData.data.map(why => {
                    if (typeof why === 'string') return <p>{why}</p>
                    return <div>
                        <div className="why-strong">{why.title}</div>
                        <p>{why.body}</p>
                    </div>
                })}
            </div>
        </div>
    }

    return <div>
        {whyForLang('en')}
        {whyForLang('bg')}
        {whyForLang('ru')}
    </div>
}
export const WhyBookingGood = connect(
    state => ({
        language: state.ui.language,
    })
)(__WhyBookingGood)
