import React from 'react'
import { Trans } from 'react-i18next';
import Button from 'react-materialize/lib/Button'
import Input from 'react-materialize/lib/Input'
import './TimeRangeControl.css'

const formatTableHour = dateStr => dateStr.substr(0, 5)

export const TimeRangeControl = ({ startTime, endTime, followingEndTimes, onChangeEndTime, possibleStartTimes = null, iconForStartTimeOption, onChangeStartTime }) => {
    let prevEndTime, nextEndTime
    let index = followingEndTimes.indexOf(endTime)
    if (index > -1) {
        prevEndTime = followingEndTimes[index - 1]
        nextEndTime = followingEndTimes[index + 1]
    } else {
        // currently selected endTime must be beyond possible values - give chance to return to latest possible
        prevEndTime = followingEndTimes[followingEndTimes.length - 1]
    }
    console.log({ startTime, endTime, followingEndTimes })
    return <div className="time-range-control">
        <div className="time-box">
            <div className="label"><Trans>Start time</Trans></div>
            {possibleStartTimes
                ? <Input
                    type="select"
                    className="time-dropdown"
                    onChange={e => onChangeStartTime(e.target.value)}
                    value={startTime}
                >
                    {possibleStartTimes.map(time => {
                        let icon
                        if (iconForStartTimeOption) {
                            icon = iconForStartTimeOption(time)
                        }
                        return <option
                            key={time}
                            value={time}
                            data-icon={icon}
                        >
                            {formatTableHour(time)}
                        </option>
                    })}
                </Input>
                : <div className="time">{formatTableHour(startTime)}</div>
            }
        </div>
        <div className="time-box">
            <div className="label"><Trans>End time</Trans></div>
            <Button flat icon="remove"
                disabled={!prevEndTime}
                onClick={() => onChangeEndTime(prevEndTime)}
            />
            <div className="time end-time">{formatTableHour(endTime)}</div>
            <Button flat icon="add"
                disabled={!nextEndTime}
                onClick={() => onChangeEndTime(nextEndTime)}
            />
        </div>
    </div>
}