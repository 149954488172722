const reSlugSiteId = /.+-(\d+)$/;
module.exports.siteIdFromSlug = slug => {
    const res = reSlugSiteId.exec(slug)
    if (!res || !res[1]) return null
    return res[1]
}

const reSep = /[\s"'/]/g;

const siteSlug = site => site.name.toLowerCase().replace(reSep, '-') + '-' + site.id
module.exports.siteSlug = siteSlug
module.exports.siteURL = (site, itemId, forNextJs = false) => {
    if (!forNextJs) return `/places/${siteSlug(site)}/${itemId || ''}`

    return `/place?siteId=${site.id}${itemId ? '&itemId=' + itemId : ''}`
}

const nameToTag = name => name.toLowerCase().replace(reSep, '-')
module.exports.nameToTag = nameToTag
module.exports.tagURL = (name, forNextJs = false) => {
    if (!forNextJs) return `/tag/${nameToTag(name)}/`

    return `/?tag=${nameToTag(name)}`
}

module.exports.siteHasEnabledNotifications = site => {
    const reNotif = /^notification_/
    const hasEnabledNotification = item => item.config && Object.keys(item.config)
        .some(opt => opt.match(reNotif) && item.config[opt] === true)

    for (const item of [...site.items, ...site.supplementaryItems]) {
        if (hasEnabledNotification(item)) {
            return true
        }
    }
}
