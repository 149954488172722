export const USER_BOOKINGS_UPDATED = 'USER_BOOKINGS_UPDATED'
export const userBookingsUpdated = (bookings, sitesLimits, watchers) => {
    return {
        type: USER_BOOKINGS_UPDATED,
        bookings,
        sitesLimits,
        watchers,
    }
}

export const NOTIFICATIONS_ENABLED = 'NOTIFICATIONS_ENABLED'
export const notificationsEnabled = () => ({
    type: NOTIFICATIONS_ENABLED,
})
export const NOTIFICATIONS_DISABLED = 'NOTIFICATIONS_DISABLED'
export const notificationsDisabled = () => ({
    type: NOTIFICATIONS_DISABLED,
})
export const ASK_ABOUT_NOTIFICATIONS = 'ASK_ABOUT_NOTIFICATIONS'
export const askAboutNotifications = () => ({
    type: ASK_ABOUT_NOTIFICATIONS,
})

export const CHANGE_USER_DETAILS = 'CHANGE_USER_DETAILS'
export const changeUserDetails = details => ({
    type: CHANGE_USER_DETAILS,
    details,
})
export const CHANGE_USER_DETAILS_FAILED = 'CHANGE_USER_DETAILS_FAILED'
export const changeUserDetailsFailed = error => ({
    type: CHANGE_USER_DETAILS_FAILED,
    error,
})
// export const USER_CONSENT_CHANGED = 'USER_CONSENT_CHANGED'
// export const userConsentChanged = consent => ({
//     type: USER_CONSENT_CHANGED,
//     consent,
// })
export const NOTIFICATIONS_PREFERENCES_LOADED = 'NOTIFICATIONS_PREFERENCES_LOADED'
export const notificationsPreferencesLoaded = (supported, enabled, preferences) => ({
    type: NOTIFICATIONS_PREFERENCES_LOADED,
    supported,
    enabled,
    preferences,
})
export const CHANGE_NOTIFICATIONS_PREFERENCES = 'CHANGE_NOTIFICATIONS_PREFERENCES'
export const notificationsPreferencesChanged = (enabled, preferences) => ({
    type: CHANGE_NOTIFICATIONS_PREFERENCES,
    enabled,
    preferences,
})

const defaultState = {
    bookings: [],
    askAboutNotifications: false,
    error: null,
    sitesLimits: null,
    // consent: undefined,
    notifications: {},
    watchers: {},
}

export const reducerUserProfile = (state = defaultState, action) => {
    switch (action.type) {
        case USER_BOOKINGS_UPDATED:
            return {
                ...state,
                bookings: action.bookings,
                sitesLimits: action.sitesLimits,
                watchers: action.watchers,
            }
        // case USER_CONSENT_CHANGED:
        //     return {
        //         ...state,
        //         consent: action.consent,
        //     }
        case ASK_ABOUT_NOTIFICATIONS:
            return {
                ...state,
                askAboutNotifications: true,
            }
        case NOTIFICATIONS_ENABLED:
        case NOTIFICATIONS_DISABLED:
            return {
                ...state,
                askAboutNotifications: false,
                notifications: {
                    ...state.notifications,
                    enabled: (action.type === NOTIFICATIONS_ENABLED)
                }
            }
        case NOTIFICATIONS_PREFERENCES_LOADED: {
            const { supported, enabled, preferences } = action
            return {
                ...state,
                notifications: {
                    supported,
                    enabled,
                    preferences,
                }
            }
        }
        case CHANGE_USER_DETAILS:
            return {
                ...state,
                error: null,
            }
        case CHANGE_USER_DETAILS_FAILED:
            return {
                ...state,
                error: action.error,
            }
        default:
            return state
    }
}