export class Site {
    id
    name

    items = []
    supplementaryItems
    sharedItems

    currency
    address
    website
    phone
    site_description
    logoURL
    full_description

    hidden

    constructor(id, name) {
        this.id = id.toString()
        this.name = name
    }
}