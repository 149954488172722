const moment = require('moment')
const { STATUS } = require('./types')
const {
    formatDatetime,
    formatDate,
    formatTime,
    extractDate,
    extractTime,
    minutesToMoment,
    ObjectMap,
    ObjectFilter,
} = require('./utils')


const hour = formatTime

module.exports.getSupplementaryItems = (site, primaryItemId) => {
    return (site.supplementaryItems || []).filter(it => it.supplementaryTo.indexOf(primaryItemId) !== -1)
}
module.exports.getSharedItemCapacity = (site, sharedItemId) => {
    let it = site.sharedItems.find(it => it.id === sharedItemId)
    return it ? it.capacity : null
}

const unwrapSchedule = schedule => {
    let daysOfWeekSchedule = { ...schedule.data }

    if (schedule.mode === 'every_day') {
        daysOfWeekSchedule = {}
        for (let i = 0; i < 7; ++i) {
            daysOfWeekSchedule[i.toString()] = schedule.data
        }
    } else if (schedule.mode === 'every_week') {
        let lastData
        for (let day of [1, 2, 3, 4, 5, 6, 0]) {
            if (daysOfWeekSchedule[day]) {
                lastData = daysOfWeekSchedule[day]
            } else {
                daysOfWeekSchedule[day] = lastData
            }
        }
    }
    return daysOfWeekSchedule
}

const getScheduleExceptionForDate = (schedule_exceptions, dateStr) => {
    // console.log('getScheduleExceptionForDate', schedule_exceptions, dateStr)
    if (!schedule_exceptions) return null
    for (let ex of schedule_exceptions) {
        if (ex.from_date <= dateStr && ex.to_date >= dateStr) {
            // console.log('found!!', ex.schedule)
            return ex.schedule
        }
    }
}
const getEventsForDate = (events, dateStr) => {
    if (!events) return null
    return events.filter(ev => (extractDate(ev.fromDate) <= dateStr && extractDate(ev.toDate) >= dateStr))
}

const generateTimelotsFromSchedule = (schedule, date, defaultCapacity, events) => {
    let timeslotsObj = {}

    const defaultStepMinutes = schedule.bookingStepMinutes
    let daysOfWeekSchedule = unwrapSchedule(schedule)

    // for (let date = moment(fromDate).startOf('day'); date.isSameOrBefore(toDate); date.add(1, 'days')) {
    date = moment(date)
    let dayslots = daysOfWeekSchedule[date.day().toString()]
    if (dayslots) {
        dayslots.forEach(slot => {
            // console.log('Generate for slot', slot)
            let init = date.clone().startOf('day')
            let startTime, endTime, step = slot.step || defaultStepMinutes || 60;
            for (let mm = slot.start; mm <= slot.end - step; mm += step) {
                startTime = minutesToMoment(mm, init)
                endTime = minutesToMoment(mm + step, init)
                // let ts = new Timeslot(formatDatetime(start), formatDatetime(end))
                const start = formatDatetime(startTime),
                    end = formatDatetime(endTime)
                let ts = {
                    ...slot,
                    start,
                    end,
                    step,
                }
                if (slot.price) ts.price = slot.price;
                if (slot.capacity !== undefined) {
                    ts.capacity = +slot.capacity
                } else {
                    ts.capacity = +defaultCapacity
                }
                if (events) {
                    let matchingEvents = events
                        .filter(ev => ev.toDate > start && ev.fromDate < end)
                    if (matchingEvents.length) {
                        let evCap = 0
                        matchingEvents.forEach(ev => evCap += ev.capacity)

                        ts.events = matchingEvents
                        if (ts.capacity < evCap) ts.capacity = evCap
                    }
                }
                timeslotsObj[hour(startTime)] = ts
            }
        })
    }
    return timeslotsObj
}

module.exports.generateTimelotsForItemForDate = (item, date) => {
    const { schedule, schedule_exceptions, capacity, events } = item
    let dateStr = formatDate(date)
    return generateTimelotsFromSchedule(getScheduleExceptionForDate(schedule_exceptions, dateStr) || schedule, date, capacity, getEventsForDate(events, dateStr))
}

const generateTimelotsFromScheduleForDate = (schedule, schedule_exceptions, dateStr, capacity, events) => {
    return generateTimelotsFromSchedule(getScheduleExceptionForDate(schedule_exceptions, dateStr) || schedule, dateStr, capacity, getEventsForDate(events, dateStr))
}
module.exports.generateTimelotsFromScheduleForDate = generateTimelotsFromScheduleForDate

module.exports.generateTimelotsFromScheduleForRange = (schedule, schedule_exceptions, daysOffsetFrom, daysOffsetTo, capacity, events) => {
    let scheduleByDates = {}
    for (let i = daysOffsetFrom; i < daysOffsetTo; ++i) {
        let date = moment().add(i, 'days')
        let dateStr = formatDate(date)
        scheduleByDates[dateStr] = generateTimelotsFromScheduleForDate(schedule, schedule_exceptions, dateStr, capacity, events)
    }
    return scheduleByDates
}

const generateWorktimeFromSchedule = (schedule, date) => {
    let daysOfWeekSchedule = unwrapSchedule(schedule)
    date = moment(date)
    let dayslots = daysOfWeekSchedule[date.day().toString()] || []
    return dayslots.map(slot => ({
        start: formatDatetime(minutesToMoment(slot.start, date)),
        end: formatDatetime(minutesToMoment(slot.end, date)),
    }))
}
module.exports.generateWorktimeFromSchedule = generateWorktimeFromSchedule

module.exports.generateWorktimeForItemForDate = (item, date) => {
    let dateStr = formatDate(date)
    const { schedule, schedule_exceptions } = item
    return generateWorktimeFromSchedule(getScheduleExceptionForDate(schedule_exceptions, dateStr) || schedule, date)
}
module.exports.generateWorktimeFromScheduleForRange = (schedule, schedule_exceptions, daysOffsetFrom, daysOffsetTo) => {
    let worktimeByDate = {}
    for (let i = daysOffsetFrom; i < daysOffsetTo; ++i) {
        let date = moment().add(i, 'days')
        let dateStr = formatDate(date)
        worktimeByDate[dateStr] = generateWorktimeFromSchedule(getScheduleExceptionForDate(schedule_exceptions, dateStr) || schedule, date)
    }
    return worktimeByDate
}

// dayOfWeek: 0-6, fromTime/toTime: string in format HH:mm or HH:mm:ss...
module.exports.generateRecurringRule = (dayOfWeek, fromTime, toTime, quantity = 1, mode = 'every_week') => {
    const t = time => moment(time, 'HH:mm').format('HH:mm:ss')
    return [mode, dayOfWeek, t(fromTime), t(toTime), quantity].join(';')
}
const parseRecurringRule = rule => {
    let [mode, dayOfWeek, fromTime, toTime, quantity] = rule.split(';')
    const t = time => moment(time, 'HH:mm').format('HH:mm:ss')
    return { mode, dayOfWeek: +dayOfWeek, fromTime: t(fromTime), toTime: t(toTime), quantity: +quantity }
}
module.exports.parseRecurringRule = parseRecurringRule

module.exports.generateBookingFromRecurringRule = (ruleInfo, dateFrom, dateTo, siteId, itemId) => {
    let rule = ruleInfo.rule
    let recurringId = ruleInfo.id
    dateFrom = moment(dateFrom)
    dateTo = moment(dateTo)
    let validFrom = moment(ruleInfo.validFrom)
    let validUntil = moment(ruleInfo.validUntil)

    // let [mode, dayOfWeek, fromTime, toTime, quantity] = rule.split(';')
    let { mode, dayOfWeek, fromTime, toTime, quantity } = parseRecurringRule(rule)
    // console.log('rule', recurringId, [mode, dayOfWeek, fromTime, toTime, quantity])
    if (mode !== 'every_week') {
        console.error('Unsupported recurring rule mode:', mode)
        throw new Error('Unsupported recurring rule mode: ' + mode)
    }
    dayOfWeek = +dayOfWeek
    let day = (dateFrom.day() <= dayOfWeek ? dayOfWeek : 7 + dayOfWeek)
    let date = dateFrom.clone().day(day)
    let [hh, mm] = fromTime.split(':')
    let [hh2, mm2] = toTime.split(':')

    let bookings = []
    while (date.isBefore(dateTo)) {
        // let booking = new Booking(
        let booking = {
            fromDate: formatDatetime(date.hour(hh).minute(mm).second(0).millisecond(0)),
            toDate: formatDatetime(date.hour(hh2).minute(mm2).second(0).millisecond(0)),
            siteId,
            itemId,
        }
        // )
        booking.quantity = quantity
        booking.status = STATUS.CONFIRMED

        booking.id = `r${recurringId}`
        booking.recurringId = recurringId

        if (ruleInfo.originalItemId) {
            booking.originalItemId = ruleInfo.originalItemId
            booking.originalItemMultiplier = ruleInfo.originalItemMultiplier || 1
        }
        if (ruleInfo.supplItemId) {
            booking.supplItemId = ruleInfo.supplItemId
        }
        if (ruleInfo.site_private_note) {
            booking.site_private_note = ruleInfo.site_private_note
        }

        if (date.isSameOrAfter(validFrom, 'day') && date.isSameOrBefore(validUntil, 'day'))
            bookings.push(booking)
        date = date.clone().add(7, 'days')
    }
    return bookings
}

//obsolete
// module.exports.mergeTimetableWithBookings = (timetable, bookingsByDate, sharedItemMultiplier = 1, sharedItemCapacity = undefined) => {
//     let newTimetable = {}
//     for (let date in timetable) {
//         newTimetable[date] = {}
//         for (let hour in timetable[date]) {
//             let bookings = bookingsByDate[date] ? bookingsByDate[date][hour] : undefined
//             newTimetable[date][hour] = {
//                 ...timetable[date][hour]
//             }

//             if (bookings) {
//                 // console.log('bookingsByDate[date][hour]', bookings)
//                 if (!sharedItemCapacity) {
//                     newTimetable[date][hour].free = newTimetable[date][hour].capacity - bookings.length
//                 } else {
//                     let sharedItemFree = Math.floor((sharedItemCapacity - bookings.length) / sharedItemMultiplier)
//                     newTimetable[date][hour].free = Math.min(sharedItemFree, newTimetable[date][hour].capacity)
//                 }
//             } else {
//                 newTimetable[date][hour].free = newTimetable[date][hour].capacity
//             }
//         }
//     }
//     return newTimetable
// }

//obsolete
// module.exports.mergeTimetablesWithSupplementary = (timetable, supplementaryTimetables) => {
//     let newTimetable = {}
//     for (let date in timetable) {
//         newTimetable[date] = {}
//         for (let hour in timetable[date]) {
//             let free = timetable[date][hour].free
//             if (!free) {
//                 newTimetable[date][hour] = null
//                 continue
//             }

//             let freeSuppl = 0
//             for (let supplTT of supplementaryTimetables) {
//                 if (supplTT[date] && supplTT[date][hour] && supplTT[date][hour].free) {
//                     ++freeSuppl
//                 }
//             }

//             if (!freeSuppl) {
//                 newTimetable[date][hour] = null
//                 continue
//             }

//             let ts = { ...timetable[date][hour] }
//             ts.free = Math.min(free, freeSuppl)
//             newTimetable[date][hour] = ts
//         }
//     }
//     return newTimetable
// }

const qua = (booking, ignoreMultiplier = false) => (booking.quantity || 1) * (ignoreMultiplier ? 1 : (booking.originalItemMultiplier || 1))

const calculateEffectiveBusy = (bookings, ignoreMultiplier) => {
    let checkpoints = {}
    for (let booking of bookings) {
        checkpoints[booking.fromDate] = true
    }
    // console.log('calculateEffectiveBusy', bookings, checkpoints)
    let maxBusy = 0
    for (let datetime in checkpoints) {
        let busy = 0
        for (let booking of bookings) {
            if (booking.fromDate <= datetime && booking.toDate > datetime) {
                busy += qua(booking, ignoreMultiplier)
            }
            if (busy > maxBusy) {
                maxBusy = busy
            }
        }
    }
    return maxBusy
}
module.exports.calculateEffectiveBusy = calculateEffectiveBusy

const by = key => (l, r) => {
    const lk = l[key], rk = r[key]
    if (lk === rk) return 0
    return lk < rk ? -1 : 1
}
module.exports.mapBookingsToTimetable = (timetable, bookings, sharedItemMultiplier = 1, sharedItemCapacity = undefined, originalItemId = undefined, includeBookings = false, includeUnmapped = false) => {
    // console.log('!!!', { timetable, bookings, sharedItemMultiplier, sharedItemCapacity, originalItemId })
    bookings = bookings.sort(by('toDate'))
    const bookingsLength = bookings.length
    let bookingsIndex = 0
    // let totalComparisons = 0
    let filledTimetable = {}
    for (let date in timetable) {
        let dayTimetable = timetable[date]
        filledTimetable[date] = {}

        let dateBookings
        if (includeUnmapped) {
            dateBookings = {}
            let nextDate = formatDate(moment(date).add(1, 'day'))
            for (let i = bookingsIndex; i < bookingsLength; ++i) {
                let bb = bookings[i]
                if (bb.fromDate > date && bb.toDate < nextDate)
                    dateBookings[bb.id] = bb
            }

        }

        for (let hour in dayTimetable) {
            let ts = {
                ...timetable[date][hour],
            }
            let matchingBookings = []
            let hasPartialBooking = false

            let busySlots = 0
            let itemBookings = 0
            let pending = 0

            for (let i = bookingsIndex; i < bookingsLength; ++i) {
                const booking = bookings[i]

                // ++totalComparisons

                // console.log('!!!booking', booking, ts)
                if (booking.toDate <= ts.start) {
                    bookingsIndex = i
                    // break
                    continue
                }

                if (booking.fromDate < ts.end) { // && booking.toDate > ts.start) {
                    // console.log('!!!!@@touchdown!')
                    matchingBookings.push(booking)

                    if (booking.fromDate !== ts.start || booking.toDate !== ts.end) {
                        hasPartialBooking = true
                    }

                    if (booking.status === STATUS.PENDING) {
                        ++pending
                    }

                    if (includeUnmapped) {
                        delete dateBookings[booking.id]
                    }
                }
            }

            if (hasPartialBooking) {
                busySlots = calculateEffectiveBusy(matchingBookings)
                if (originalItemId) {
                    itemBookings = calculateEffectiveBusy(matchingBookings.filter(bb => bb.originalItemId === originalItemId), true)
                }
            } else {
                for (let booking of matchingBookings) {
                    let quantity = qua(booking)
                    busySlots += quantity

                    if (!originalItemId || booking.originalItemId === originalItemId) {
                        itemBookings += (booking.quantity || 1)
                    }
                }
            }

            if (sharedItemCapacity) {
                let sharedItemFree = Math.floor((sharedItemCapacity - busySlots) / sharedItemMultiplier)
                let itemFree = ts.capacity - itemBookings
                ts.free = Math.min(sharedItemFree, itemFree)
                ts.ownBusy = itemBookings
                // if (busySlots) console.log('!!!', { busySlots, sharedItemMultiplier, sharedItemFree, sharedItemCapacity, free: ts.free, start: ts.start, itemFree, ts })
            } else {
                ts.free = ts.capacity - busySlots
                ts.ownBusy = busySlots
            }

            if (includeBookings) ts.bookings = matchingBookings

            ts.pending = pending
            // console.log('!!!ts', ts)
            filledTimetable[date][hour] = ts
        }

        if (includeUnmapped) {
            let unmapped = Object.keys(dateBookings)
                .map(id => dateBookings[id])
                .filter(booking => {
                    if (!originalItemId) return true
                    return booking.originalItemId === originalItemId
                })

            if (unmapped.length) {
                // console.log('unmapped bookings', dateBookings, unmapped)
                unmapped.forEach(booking => {
                    let hour = extractTime(booking.fromDate)
                    filledTimetable[date][hour] = filledTimetable[date][hour] || {
                        unmapped: true,
                        start: booking.fromDate,
                        end: booking.toDate,
                        capacity: -1,
                        free: 0,
                        ownBusy: booking.quantity || 1,
                        bookings: []
                    }
                    filledTimetable[date][hour].bookings.push(booking)
                    --filledTimetable[date][hour].free
                })
            }
        }
    }
    //TODO optimize this algorithm
    // console.log('!!!totalComparisons', totalComparisons)
    return filledTimetable
}


// cb: (timeslot, date, hour)->newTimeslot
module.exports.forEachTimeslot = (timetable, cb) => {
    for (let date in timetable) {
        let dayTimetable = timetable[date]
        for (let hour in dayTimetable) {
            let timeslot = dayTimetable[hour]

            let newTimeslot = cb(timeslot, date, hour)
            if (newTimeslot) {
                dayTimetable[hour] = newTimeslot
            }
        }
    }
}

const mergeRanges = arr => {
    return arr
        .sort(by('start'))
        .reduce(
            (acc, val) => {
                let last = acc[acc.length - 1]
                if (!last || last.end !== val.start) {
                    acc.push(val)
                } else {
                    last.end = val.end
                }
                return acc
            },
            []
        )
}
module.exports.mergeRanges = mergeRanges

module.exports.mapByAvailSupplementary = (filledTimetable, supplRangesById, supplBookingsById) => {
    // console.log('!!!supplRanges', supplRangesById, 'supplBookings', supplBookingsById, 'filledTimetable', filledTimetable)

    supplRangesById = ObjectMap(
        supplRangesById,
        rangesByDate => ObjectMap(
            rangesByDate,
            ranges => mergeRanges(ranges)
        )
    )
    return ObjectMap(filledTimetable, (dateSlots, date) => {
        return ObjectMap(dateSlots, (ts, hour) => {
            // if (!ts.free) return { ...ts }
            if (!ts.free) return null

            let availSupplIds = []
            for (let id in supplRangesById) {
                let itemBookings = supplBookingsById[id] || []
                itemBookings = itemBookings.filter(b => b.status !== 'CANCELED')
                let itemRanges = supplRangesById[id] || {}
                let itemDayRange = itemRanges[date]

                if (!itemDayRange) continue
                if (!itemDayRange.some(range => ts.start >= range.start && ts.end <= range.end)) continue

                if (itemBookings.some(booking => ts.start < booking.toDate && ts.end > booking.fromDate)) continue

                availSupplIds.push(id)

            }

            if (!availSupplIds.length) return null

            return {
                ...ts,
                free: Math.min(ts.free, availSupplIds.length),
                availSupplIds,
            }

        })
    })
}


module.exports.isMatchDateHour = (booking, startDatetime, endDatetime) => {
    // console.log({ startDatetime, endDatetime, booking })
    // return dh >= booking.fromDate && dh < booking.toDate
    return booking.fromDate < endDatetime && booking.toDate > startDatetime
}
module.exports.extractTimetableSubset = (timetable, startDatetime, endDatetime) => {
    let obj = ObjectMap(
        timetable,
        (dayTimetable, date) => ObjectFilter(
            dayTimetable,
            (ts, hour) => {
                let datetime = `${date} ${hour}`
                return datetime >= startDatetime && datetime < endDatetime
            }
        )
    )
    return ObjectFilter(
        obj,
        (dayTimetable) => {
            return Object.keys(dayTimetable).length > 0
        }
    )
}

const enhanceSupplItem = (booking, site) => {
    if (!booking.supplItemId) return booking
    let supplItem = site.supplementaryItems.find(it => it.id === booking.supplItemId) || {}
    return {
        ...booking,
        supplItem,
    }
}
const enhanceOriginalItem = (booking, site) => {
    if (!booking.originalItemId) return booking
    // console.log(site)
    let originalItem = site.items.find(it => it.id === booking.originalItemId) || {}
    return {
        ...booking,
        originalItem,
    }
}
const enhanceRecurringBooking = (booking, rules) => {
    if (!booking.recurringId) return booking
    let rule = rules[booking.recurringId] || {}
    let { userId, supplItemId, site_private_note } = rule
    return {
        ...booking,
        userId,
        supplItemId,
        site_private_note,
    }
}
const enhanceBookingDetails = (booking, adminBookings) => {
    let bb = adminBookings[booking.id]
    if (!bb) return booking
    return {
        ...bb,
        ...booking,
    }
}

const resolveUser = (booking, adminUsers) => {
    let user = adminUsers[booking.userId] || {}
    return {
        ...booking,
        user,
    }
}
module.exports.applyAdminChain = (bookings, site, adminRecurringRules, adminBookings, adminUsers) => {
    return bookings
        .map(b => enhanceRecurringBooking(b, adminRecurringRules))
        .map(b => enhanceBookingDetails(b, adminBookings))
        .map(b => enhanceSupplItem(b, site))
        .map(b => enhanceOriginalItem(b, site))
        .map(b => resolveUser(b, adminUsers))
}

