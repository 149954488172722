import React from 'react'
import { connect } from 'react-redux'
import { i18n } from 'shared/i18n'
import {
    Trans,
} from 'react-i18next'
import {
    newVersionAvailable
} from '../../reducers'
import { Popup } from './Popup'
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'

class CC extends React.Component {
    show() {
        this.rr && this.rr.show()
    }
    componentDidUpdate() {
        if (this.props.show) this.show()
    }
    componentDidMount() {
        if (this.props.show) this.show()
    }
    render() {
        const { onReload, onCancel } = this.props
        const cancel = () => {
            this.rr && this.rr.hide()
            onCancel()
        }
        return <Popup ref={rr => this.rr = rr}
            header={i18n().t('New version is available')}
            actions={[
                <Button style={{ backgroundColor: '#4caf50' }} onClick={onReload}><Trans>Update</Trans><Icon left>refresh</Icon></Button>,
                <Button flat onClick={cancel}><Trans>Cancel</Trans></Button>,
            ]}
        >
            <p className="flow-text"><Trans>You only need to refresh the page to update</Trans></p>
        </Popup>
    }
}

export const NewVersionAvailable = connect(
    state => {
        return {
            show: state.ui.newVersionAvailable
        }
    },
    dispatch => {
        return {
            onReload: () => window.location.reload(true),
            onCancel: () => dispatch(newVersionAvailable(false)),
        }
    }
)(CC)