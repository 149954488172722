import React from 'react'
import { Trans } from 'react-i18next';
import cx from 'classnames'
import { STATUS } from 'shared/types'
import './BookingStatus.css'

export const BookingStatus = ({ status, showConfirmed, floating }) => {
    const st = (classname, text) => (
        <div className={cx({
            'booking-status': true,
            'booking-status-floating': !!floating,
            [classname]: true,
        })}><Trans>{text}</Trans></div>
    )
    switch (status) {
        case STATUS.CONFIRMED:
            return showConfirmed ? st('booking-confirmed', 'Confirmed') : <div />

        case STATUS.PENDING:
            return st('pending-approval', 'Pending confirmation')
        case STATUS.REJECTED:
            return st('booking-rejected', 'Rejected')
        case STATUS.CANCELED:
            return st('booking-canceled', 'Canceled')
        case STATUS.MISSED:
            return st('booking-missed', 'Missed booking')
        default:
            return <div />
    }
}