import React from 'react';
import { Trans } from 'react-i18next';
import {
    NavLink,
    withRouter,
} from 'react-router-dom';
import Navbar from 'react-materialize/lib/Navbar'
import Dropdown from 'react-materialize/lib/Dropdown'
import Icon from 'react-materialize/lib/Icon'
import { LangSwitch } from './LangSwitch'
import {
    NotificationsIcon,
    ConnectedNotificationsBadge,
} from './NotificationsPanel'
import {
    Popup,
} from './Popup'
import cx from 'classnames';
import './Navigation.css'

const logoColor = (hint) => {
    switch (hint) {
        case '?blue':
        case '?green':
        case '?altgreen':
        case '?red':
        case '?orange':
        case '?yellow':
            return hint.substr(1)
        default: return 'yellow'
    }
}

export const Navigation = withRouter(({ options, adminOptions, adminPathBase, profile, brand, notifications, onSignOut, match, location, history }) => {
    const isActive = path => location.pathname.indexOf(path) !== -1
    const isActiveStrict = path => location.pathname === path
    return <Navbar
        brand={brand || <NavLink to="/">
            <img style={{ maxHeight: 35, marginTop: 12 }} src={`/static/assets/img/logo_${logoColor(location.search)}250.png`} alt='BookingGood Sport' />
        </NavLink>}
        href=""
        className={cx({ 'has-notifications': notifications && notifications.length })}
        right
        options={
            {
                closeOnClick: true,
                draggable: true,
            }
        }
    >

        {options.map(([path, name]) => (
            <li key={path} className={(location.pathname.indexOf(path) !== -1) ? 'active' : ''}>
                <NavLink to={path}>
                    <Trans>{name}</Trans>
                </NavLink>
            </li>
        ))}

        {!!adminOptions && <li className={cx({
            "hide-on-med-and-down": true,
            "active": isActive(adminPathBase),
        })}>
            <NavLink to={adminOptions[0][0]}>
                <Trans>Administration</Trans>
            </NavLink>
        </li>}
        {!!adminOptions && [
            <li className="divider hide-on-large-only" key={400}></li>,
            <li className="hide-on-large-only" key={41}>
                <a className="subheader"><Trans>Administration</Trans></a>
            </li>,
        ]}
        {!!adminOptions && adminOptions.map(([url, title]) => <li key={url} className={cx({
            "hide-on-large-only": true,
            "active": isActiveStrict(url),
        })}>
            <NavLink to={url}><Trans>{title}</Trans></NavLink>
        </li>)}

        {!!notifications && <li className="navbar-notifications hide-on-med-and-down">
            <NotificationsIcon />
        </li>}
        <li className="hide-on-med-and-down">
            <LangSwitch dropdown />
        </li>
        {profile && <li className="hide-on-med-and-down">
            <Dropdown
                trigger={
                    <button className="link-button nav-button"><Icon>account_box</Icon></button>
                }
                options={{
                    belowOrigin: true
                }}
            >
                <li><NavLink to="/profile"><Trans>Profile</Trans></NavLink></li>
                <li><NavLink to="/settings"><Trans>Settings</Trans></NavLink></li>
                <li><button className="link-button dropdown-link-button" onClick={e => onSignOut && onSignOut()}><Trans>Sign Out</Trans></button></li>
            </Dropdown>
        </li>}
        {profile && [
            <li className="divider hide-on-large-only" key={10}></li>,
            <li className="hide-on-large-only" key={11}>
                <NavLink to="/notifications">
                    <Trans>Notifications</Trans>
                    <ConnectedNotificationsBadge line />
                </NavLink>
            </li>,
            <li className="divider hide-on-large-only" key={1}></li>,
            <li className="hide-on-large-only" key={2}><a className="subheader"><Trans>Language</Trans></a></li>,
            <li className="hide-on-large-only" key={21}><LangSwitch /></li>,
            <li className="divider hide-on-large-only" key={3}></li>,
            <li className="hide-on-large-only" key={4}><a className="subheader">{profile.displayName || profile.email || profile.phoneNumber}</a></li>,
            <li className="hide-on-large-only" key={42}><NavLink to="/profile"><Trans>Profile</Trans></NavLink></li>,
            <li className="hide-on-large-only" key={43}><NavLink to="/settings"><Trans>Settings</Trans></NavLink></li>,
            <li className="hide-on-large-only" key={5}><button className="link-button" onClick={e => onSignOut && onSignOut()}><Trans>Sign Out</Trans></button></li>
        ]}
        {!profile && [
            <li className="divider hide-on-large-only" key={3}></li>,
            <li className="hide-on-large-only" key={2}><a className="subheader"><Trans>Language</Trans></a></li>,
            <li className="hide-on-large-only" key={21}><LangSwitch /></li>,
        ]}
    </Navbar>
})

export class AdminSitesSelector extends React.Component {
    render() {
        const { currentId, sites, onSelect } = this.props
        const show = e => {
            e.preventDefault()
            this.popup.show()
        }

        return <div className="admin-site-selector">
            <button className="link-button nav-button" onClick={show}><Icon>more_vert</Icon></button>
            <Popup ref={rr => this.popup = rr}>
                <ul className="admin-sites-list">
                    {sites.map(site => <li key={site.id} className={cx({
                        "site-selector-item": true,
                        "selected": currentId === site.id,
                    })}>
                        <button style={{ display: 'flex' }} className="link-button" onClick={e => {
                            e.preventDefault()
                            onSelect(site.id)
                        }}>
                            <img className="site-logo-big" src={site.logoURL} alt={`${site.name} logo`} />
                            &nbsp;
                            <span>{site.name}</span>
                        </button>
                    </li>)}
                    <li>
                        <NavLink to="/register_club" onClick={() => this.popup.hide()}>
                            <Icon>add</Icon>
                        </NavLink>
                    </li>
                </ul>
            </Popup>
        </div>
    }
}

