import React from 'react'
import Button from 'react-materialize/lib/Button'
import { Trans } from 'react-i18next'

export const ContactUs = ({ text, forMoreInfo }) => {
    const link = <a href={"mailto:info@bookinggood.net"}><Trans>{text || 'Contact us'}</Trans></a>
    if (forMoreInfo) {
        return <span>
            {link}&nbsp;<Trans>for more info</Trans>
        </span>
    }
    return link
}

export const ContactUsButton = () => <a href="mailto:info@bookinggood.net">
    <Button className="action-button">
        <Trans>Contact us</Trans>
    </Button>
</a>
