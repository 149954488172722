export const APPLY_SITES_FILTER = 'APPLY_SITES_FILTER'
export const applySitesFilter = (sitesFilter) => ({// {items, cities}
    type: APPLY_SITES_FILTER,
    sitesFilter,
})

export const APPLY_SEARCH = 'APPLY_SEARCH'
export const applySearch = (search) => ({
    type: APPLY_SEARCH,
    search,
})

const defaultState = {
    sitesFilter: null,
    search: '',
}

export const reducerSearch = (state = defaultState, action) => {
    switch (action.type) {
        case APPLY_SITES_FILTER: {
            return {
                ...state,
                sitesFilter: action.sitesFilter,
            }
        }
        case APPLY_SEARCH: {
            return {
                ...state,
                search: action.search,
            }
        }
        default:
            return state
    }
}
