const moment = require('moment')

module.exports.capitalize = s => s[0].toUpperCase() + s.slice(1)

module.exports.formatDatetime = date => moment(date).format('YYYY-MM-DD HH:mm:ss')
module.exports.formatDate = date => moment(date).format('YYYY-MM-DD')
module.exports.formatTime = date => moment(date).format('HH:mm:ss')
module.exports.extractDate = datetimeStr => {
    let [date] = datetimeStr.split(' ')
    return date
}
module.exports.extractTime = (datetimeStr, shortFormat) => {
    let time = datetimeStr.split(' ')[1]
    if (shortFormat) time = time.slice(0, 5)
    return time
}
module.exports.minutesToMoment = (min, baseDate = undefined) => {
    let init = moment(baseDate).startOf('day')
    return init.minutes(min)
}

const convertToTimezone = (date, timezoneOffset) => {
    date = new Date(date)
    let currentTzOffset = date.getTimezoneOffset() * -1
    if (timezoneOffset === currentTzOffset) {
        // console.log('Offsets are equal!!!')
        return date
    }
    date.setMinutes(date.getMinutes() + timezoneOffset - currentTzOffset)
    // console.log('time after applied timezone difference', timeNow, siteTzOffset)
    return date
}
module.exports.convertToTimezone = convertToTimezone
module.exports.getTimeNowForSite = site => {
    let timeNow = new Date()
    return convertToTimezone(timeNow, site.timezoneOffset)
}


const dateFormat = 'YYYY-MM-DD'
module.exports.isDateVaild = date => date && moment(date, dateFormat).isValid()

const isDatetimePassed = (datetime, timeNow) => moment(datetime, 'YYYY-MM-DD HH:mm:ss').isBefore(timeNow)
module.exports.isDatetimePassed = isDatetimePassed
module.exports.isPassed = (date, hour, timeNow) => isDatetimePassed(date + hour, timeNow)

module.exports.logError = (err, context) => {
    console.error(err, context)
}

const normalizePhoneNumber = phone => phone.replace(/[^\d+]/g, '')
module.exports.normalizePhoneNumber = normalizePhoneNumber

const insertAtNegative = (str, char, negativeIndex) => {
    if (str.length < Math.abs(negativeIndex))
        return str;
    return str.slice(0, negativeIndex) + char + str.slice(negativeIndex)
}
module.exports.formatPhoneNumber = phone => {
    if (!phone) return phone
    phone = normalizePhoneNumber(phone)
    if (phone.length > 10) {
        phone = insertAtNegative(phone, ' ', -9)
    }
    if (phone.length > 6) {
        phone = insertAtNegative(phone, ' ', -6)
    }
    if (phone.length > 3) {
        phone = insertAtNegative(phone, ' ', -3)
    }
    return phone
}


module.exports.ArrayToObjectByKey = (arr, key) => arr.reduce((acc, val) => { acc[val[key]] = val; return acc }, {})

module.exports.sortBy = (key, reverse = false) => (l, r) => {
    const lk = l[key], rk = r[key]
    if (lk === rk) return 0
    if (reverse) {
        return lk < rk ? 1 : -1
    }
    return lk < rk ? -1 : 1
}



module.exports.ObjectValues = obj => Object.keys(obj).map(key => obj[key])
module.exports.ObjectMap = (obj, cb) => {
    let newObj = {}
    for (let key in obj) {
        newObj[key] = cb(obj[key], key)
    }
    return newObj
}
module.exports.ObjectFilter = (obj, cb) => {
    let newObj = {}
    for (let key in obj) {
        if (cb(obj[key], key)) {
            newObj[key] = obj[key]
        }
    }
    return newObj
}



module.exports.lang2country = lang => {
    switch (lang.toLowerCase()) {
        case 'en': return 'US';
        default:
            return lang.toUpperCase()
    }
}

module.exports.country2lang = country => {
    switch (country) {
        case 'Bulgaria':
            return 'bg';
        case 'Russian Federation':
        case 'Russia':
        case 'Ukraine':
            return 'ru';
        default:
            return 'en'
    }
}


const recognizeLanguage = input => {
    input = input.toLowerCase()
    switch (input) {
        case 'bg':
        case 'ru':
            // case 'ua':
            return input
        default:
            return 'en'
    }
}
module.exports.recognizeLanguage = recognizeLanguage

const reLang = /^\/(bg|en|ru)(\/|$)/
module.exports.stripLangFromUrlPath = url => url.replace(reLang, '/')
module.exports.extractLangFromUrlPath = url => {
    let res = reLang.exec(url)
    // console.log('PATH res', res)
    if (res) {
        const lang = recognizeLanguage(res[1])
        return lang
    }
    return null
}

module.exports.delay = msec => new Promise(done => setTimeout(() => done(), msec))

module.exports.isServer = () => (typeof window === 'undefined')