import React from 'react'
import { i18n } from 'shared/i18n'
import {
    normalizePhoneNumber,
} from '../utils'
import WithIcon from './WithIcon'

export const generateSiteDescription = site => site.items.map(it => i18n().t(it.name)).join(', ')

export const SiteShortDescription = ({ site }) => <span>{site.description || generateSiteDescription(site)}</span>

export const SiteWebsite = ({ site, withIcon, hideURL }) => {
    if (!site.website) return <div />

    let content = !hideURL
        ? <a target="_blank" href={site.website}>{site.website}</a>
        : <a href="" onClick={e => {
            e.preventDefault()
            window.open(site.website, '_blank')
        }}>{site.website}</a>

    if (withIcon) return <WithIcon icon="home">{content}</WithIcon>
    return <div>{content}</div>
}
export const SitePhone = ({ withIcon, site }) => {
    if (!site.phone) return <div />
    let content = <a href={'tel:' + normalizePhoneNumber(site.phone)}>{site.phone}</a>
    if (withIcon) return <WithIcon icon="phone">{content}</WithIcon>
    return <div>{content}</div>
}
export const SiteAddress = ({ withIcon, withCity, site }) => {
    if (!site.address) return <div />
    let { address, city } = site
    if(withCity) {
        address = `${i18n().t(city)}, ${address}`
    }
    return withIcon ? <WithIcon icon="place">{address}</WithIcon> : <div>{address}</div>
}
export const SiteEmail = ({ withIcon, site }) => {
    if (!site.email) return <div />
    const content = <a href={`mailto:${site.email}`}>{site.email}</a>
    return withIcon ? <WithIcon icon="email">{content}</WithIcon> : <div>{content}</div>
}
