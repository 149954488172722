import React from 'react'
import Modal from 'react-materialize/lib/Modal'
import Button from 'react-materialize/lib/Button'
import { Trans } from 'react-i18next'

class InvisibleTrigger extends React.Component {
    click() {
        const { onClick } = this.props
        onClick && onClick({ preventDefault: () => { } })
    }
    render() {
        return <span />
    }
}

export const CloseButton = () => <Button flat className="modal-close"><Trans>Close</Trans></Button>

export class Popup extends React.Component {
    show() {
        this.refs.trigger.click()
        this.opened = true
    }
    hide() {
        if (this.opened) {
            this.refs.modal.hideModal()
            this.opened = false
        }
    }
    componentDidMount() {
        if (this.props.auto)
            this.show()
    }
    componentWillUnmount() {
        this.autohidden = true
        this.hide()
    }
    render() {
        let { header, actions = [<CloseButton />], overflowAuto = true, maxWidth = 500, children, fixedFooter, options = {}, onClose } = this.props
        this.trigger = <InvisibleTrigger ref="trigger" />
        return <Modal ref="modal" trigger={this.trigger} header={header} actions={actions}
            fixedFooter={fixedFooter}
            style={{ overflowY: overflowAuto ? 'auto' : 'unset', maxWidth }}
            modalOptions={{
                complete: () => { onClose && onClose(!!this.autohidden) },
                ...options,
            }}
        >
            {children}
        </Modal>
    }
}