import {
    put,
    take,
    delay,
} from 'redux-saga/effects'
import {
    eventChannel,
    buffers,
} from 'redux-saga'
import {
    getScreenWidth,
    getScreenSize,
    screenSizeChange,
} from '../reducers/screen_size'



export function* screenSizeWatcher() {
    let size;
    let channel = eventChannel(
        emit => {
            window.addEventListener('resize', event => {
                const width = getScreenWidth()
                const size = getScreenSize(width)
                emit({ width, size })
            });

            // emit once initially
            const width = getScreenWidth()
            const size = getScreenSize(width)
            emit({ width, size })
            return () => { }
        },
        buffers.sliding(1)
    )
    while (true) {
        const { size, width } = yield take(channel)
        yield put(screenSizeChange(size, width))
        yield delay(300)
    }
}