import React from 'react'
import Icon from 'react-materialize/lib/Icon'
import { Trans } from 'react-i18next'
import {
    ContactUs,
} from './common'
import "./HelpHints.css"

export const NotificationsNotSupported = () => <div className="warning-not-supported">
    <div><Icon>warning</Icon></div>
    <div>
        <Trans i18nKey="notifications-not-supported"
            values={{
                link: 'BookingGood Notifier',
            }}
            components={[
                <a href="https://itunes.apple.com/us/app/bookinggood-notifier/id1408085105?ls=1&mt=8">BookingGood Notifier</a>,
            ]}
        ></Trans>
        &nbsp;
        <ContactUs forMoreInfo />.
    </div>
</div>