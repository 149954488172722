import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import {
    Trans,
} from 'react-i18next'
import Footer from 'react-materialize/lib/Footer'
import Icon from 'react-materialize/lib/Icon'
import './SiteFooter.css'

export const SiteFooterHosted = () => <Footer
    className="site-footer"
    moreLinks={
        <span>
            <span>Powered by <a href="https://www.bookinggood.net">BookingGood</a></span>
            <span> - </span>
            <span className="links" key={1}>
                <a href="https://www.iubenda.com/privacy-policy/89587853">Privacy</a>
                <span>, </span>
                <a href="https://www.iubenda.com/privacy-policy/89587853/cookie-policy">Cookie</a>
                <span> and </span>
                <a href="/terms">Terms</a>
            </span>
        </span>
    }
/>

export const SiteFooter2 = () => <Footer
    className="site-footer"
    copyrights={`© ${new Date().getFullYear()} Born Brave LTD, All rights reserved.  `}
    moreLinks={
        [<span className="links" key={1}>
            <a href="https://www.iubenda.com/privacy-policy/89587853">Privacy</a>
            <span>, </span>
            <a href="https://www.iubenda.com/privacy-policy/89587853/cookie-policy">Cookie</a>
            <span> and </span>
            <a href="/terms">Terms</a>
        </span>,
        <span className="contact-us right" key={2}>
            <a className="facebook-icon" href="https://www.facebook.com/bookinggood.net">
                <img alt="facebook-page" src="/static/assets/img/facebook.svg" />
            </a>
            <a href={"mailto:info@bookinggood.net"}>
                <Icon >email</Icon>
                <Trans>Contact us</Trans>
            </a>
        </span>]
    }
/>

export class SiteFooter extends Component {
    componentDidMount() {
        this.element = document.getElementById('footer')
    }
    render() {
        if (!this.element) {
            return null
        }
        return ReactDOM.createPortal(
            <SiteFooter2 />
            , this.element // document.getElementById('footer')
        )
    }
}