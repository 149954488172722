import React from 'react';
import { Trans } from 'react-i18next';
import { i18n } from 'shared/i18n'
import { connect } from 'react-redux';
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'
import Card from 'react-materialize/lib/Card'
import CardPanel from 'react-materialize/lib/CardPanel'
import {
    NavLink
} from 'react-router-dom';
import {
    createSelector
} from 'reselect'
import {
    applySitesFilter,
    applySearch,
    changeLocation,
} from '../../reducers'
import {
    BodyContainer,
    Selector,
} from '../../components/common'
import {
    SiteShortDescription,
    SiteWebsite,
    SitePhone,
    SiteAddress,
    SiteEmail,
} from '../../components/SiteElements'
import MyBookings from '../../components/MyBookings'
import {
    siteURL,
    tagURL,
    nameToTag,
} from 'shared/sites'
import {
    selectorFilterSitesCoaches,
    selectorUniqueCities,
    selectorCityUniqueTags,
} from '../../sagas/search'
import cx from 'classnames'
import './Dashboard.css'

// const siteDetail = (name, data) => {
//     return <Row>
//         <Col s={4} m={1} l={1}>{name}</Col>
//         <Col s={8} m={3} l={3}>{data}</Col>
//     </Row>
// }





const InviteYourClub = ({ question }) => {
    const subjInvite = i18n().t('Online booking platform'),
        bodyInvite = i18n().t(`Hey, \n\nthere's this new online booking platform, https__//sport.bookinggood.net - it's pretty cool, you should try them!\n\nCheers`).replace('https__', 'https:'),
        linkInvite = <a href={`mailto:?subject=${encodeURIComponent(subjInvite)}&body=${encodeURIComponent(bodyInvite)}`}><Trans>Send an invitation</Trans></a>
    const subjContact = i18n().t('Sport club details'),
        linkContactUs = <a href={`mailto:info@bookinggood.net?subject=${encodeURIComponent(subjContact)}`}><Trans>contact us</Trans></a>
    return <div className="invite-your-club"><span><Trans>{question}</Trans>&nbsp;{linkInvite}&nbsp;<Trans>or</Trans>&nbsp;{linkContactUs}</span></div>
}

const SiteDescription = ({ site }) => <div className="place-description">
    <SiteShortDescription site={site} />
</div>
const SiteFullDescription = ({ site }) => <div className="place-full-description">{site.full_description}</div>
const SiteLogo = ({ site }) => <img className="site-logo-big" src={site.logoURL} alt={site.name + ' logo'} />
const SiteName = ({ site }) => <div className="place-name">{site.name}</div>
const SiteNameSmall = ({ site }) => <div className="place-name-small">{site.name}</div>

const MySiteAddress = ({ site, withIcon }) => <div className="place-address">
    <SiteAddress site={site} withIcon={withIcon} />
</div>
const SiteFullDetails = ({ site }) => <div className="place-full-details">
    <SiteName site={site} />
    <SiteDescription site={site} />
    <SiteAddress site={site} withIcon />
    <SiteWebsite site={site} hideURL withIcon />
    <SitePhone site={site} withIcon />
    <SiteEmail site={site} withIcon />
    <SiteFullDescription site={site} />
    {/* {site.address && siteDetail(<Trans>Address</Trans>, site.address)}
    {site.website && siteDetail(<Trans>Website</Trans>, <a target="_blank" href={site.website}>{site.website}</a>)}
    {site.phone && siteDetail(<Trans>Tel</Trans>, <a href={'tel:' + normalizePhoneNumber(site.phone)}>{site.phone}</a>)} */}
</div>

const dummyClickHandler = e => e.preventDefault()

const SiteView = site => {
    return <li className="place" key={site.id}>
        <Card
            header={
                <div className="card-header">
                    <div className="place-info">
                        <div className="place-name-descr">
                            <NavLink to={siteURL(site)}>
                                <SiteName site={site} />
                            </NavLink>
                            <SiteDescription site={site} />
                        </div>
                        <NavLink to={siteURL(site)}>
                            <SiteLogo site={site} />
                        </NavLink>
                    </div>
                </div>
            }
            reveal={
                <SiteFullDetails site={site} />
            }
            actions={[
                <a href="" onClick={dummyClickHandler} className="activator" key="info">
                    <Trans>Info</Trans>
                </a>,
                <NavLink to={siteURL(site)} key="book now">
                    <Trans>Book now</Trans>
                </NavLink>
            ]}
        >
            <div>
                <MySiteAddress site={site} />
            </div>
        </Card>
    </li>
}


const PlacesResults = connect(
    (state, props) => ({
        sites: selectorFilterSitesCoaches(state, props.tag).sites,
        language: state.ui.language,
    }),
)(
    ({ sites }) => {
        return <div>
            <h1><Trans>Clubs</Trans></h1>
            <ul className="places-results">
                {sites && sites.map(site => SiteView(site))}
                <li className="place" key="invite">
                    <CardPanel>
                        <InviteYourClub question="Cannot find your club?" />
                    </CardPanel>
                </li>
            </ul>
        </div>
    }
)


const CoachDescription = ({ items }) => <div className="place-description">
    {items.map((it, i) => (<span key={it.name}>{!!i && ', '}<Trans>{it.name}</Trans></span>))}
</div>

const CoachView = coachInfo => {
    const { coach, site, items } = coachInfo
    return <li className="place coach" key={coach.id}>
        <Card
            header={
                <div className="card-header">
                    <div className="place-info">
                        <div className="place-name-descr">
                            <SiteName site={coach} />
                            <CoachDescription items={items} />
                        </div>
                        <SiteLogo site={site} />
                    </div>
                </div>
            }
            reveal={
                <SiteFullDetails site={site} />
            }
            actions={[
                <a href="" onClick={dummyClickHandler} className="activator" key="info">
                    <Trans>Info</Trans>
                </a>,
                <NavLink to={`${siteURL(site, (items[0] || {}).id)}?coachId=${coach.id}`} key="book now">
                    <Trans>Book now</Trans>
                </NavLink>
            ]}
        >
            <div>
                <SiteNameSmall site={site} />
                <MySiteAddress site={site} />
            </div>
        </Card>
    </li>
}


const CoachesResults = connect(
    (state, props) => ({
        coaches: selectorFilterSitesCoaches(state, props.tag).coaches,
        language: state.ui.language,
    }),
)(
    ({ coaches }) => {
        return <div>
            <h1><Trans>Coaches</Trans></h1>
            <ul className="places-results">
                {coaches && coaches.map(coach => CoachView(coach))}
                <li className="place coach" key="invite">
                    <CardPanel>
                        <InviteYourClub question="Cannot find your coach?" />
                    </CardPanel>
                </li>
            </ul>
        </div>
    }
)


// let countries = [
//     'Bulgaria',
//     // 'Demoland',
//     // 'Cannot find your country?'
// ]

const selectorCountries = createSelector(
    state => state.data.countries,
    state => state.ui.country,
    (countries, country) => {
        let array = Object.keys(countries).map(key => ({
            id: key,
            name: countries[key],
        }))
        if (!countries[country]) {
            array.push({
                id: country,
                name: country,
            })
        }
        return array
    }
)

const Filters = connect(
    (state, props) => {
        let country = state.ui.country
        let city = state.ui.city

        let { sitesFilter } = state.search
        if (sitesFilter && sitesFilter.cities) {
            city = state.search.sitesFilter.cities[0]
        }
        return {
            tags: selectorCityUniqueTags(state, city),
            cities: selectorUniqueCities(state),
            countries: selectorCountries(state),
            sitesFilter: state.search.sitesFilter,
            country,
            city,
            language: state.ui.language, // to force update if language changes
            tag: props.tag,
        }
    },
    dispatch => {
        return {
            onChange: sitesFilter => dispatch(applySitesFilter(sitesFilter)),
            onChangeLocation: (country, city, clearCitiesFilter) => {
                if (clearCitiesFilter) {
                    dispatch(applySitesFilter({ cities: null }))
                }
                dispatch(changeLocation(country, city))
            },
        }
    }
)(
    ({ tags, cities, countries, tag, sitesFilter, country, city, onChange, onChangeLocation }) => {
        const _onChange = partial => onChange({
            ...sitesFilter,
            ...partial,
        })
        let filteredCity = null
        if (sitesFilter && sitesFilter.cities && sitesFilter.cities[0]) {
            filteredCity = sitesFilter.cities[0]
        }
        return <div className="filters">
            <div className="country-city-control z-depth-1">
                <Selector
                    showArrow={false}
                    showBackgroundColor={false}
                    items={countries}
                    selected={country}
                    onSelect={newCountry => {
                        if (newCountry !== country)
                            onChangeLocation(newCountry, null)
                    }}
                />
                <span>,</span>
                <Selector
                    showBackgroundColor={false}
                    items={cities}
                    selected={filteredCity || city}
                    highlightSelected={!!filteredCity}
                    onSelect={newCity => {
                        if (newCity !== city)
                            onChangeLocation(country, newCity, (sitesFilter && sitesFilter.cities))
                    }}
                />
            </div>
            <BasicFilter
                title="Activities"
                items={tags}
                selected={(tag && tags.filter(it => nameToTag(it) === tag)) || (sitesFilter && sitesFilter.tags)}
                onSelect={items => _onChange({ items })}
            />
        </div>
    }
)


const placeholders = [
    // 'Tennis in Sofia',
    // 'Badminton in Sofia from 19:00 to 21:00',
    // 'Any sport in Plovdiv'
    // 'Any sport in Sofia from 17:00 to 19:00'
    'Sport, coach or club name',
    'Try "Squash in Sofia"',
]
class Search extends React.Component {
    state = {
        placeholder: placeholders[0]
    }

    componentDidMount() {
        this.__index = 0
        this.intervalPlaceholder = setInterval(() => {
            this.setState({ placeholder: placeholders[(++this.__index) % placeholders.length] })
        }, 3000)
    }
    componentWillUnmount() {
        clearInterval(this.intervalPlaceholder)
    }
    render() {
        const { search, onChange, className = '' } = this.props
        const { placeholder } = this.state
        const onEnter = e => {
            e.preventDefault()
            this.input && this.input.blur()
        }
        return <form className={`search-bar ${className}`} onSubmit={onEnter}>
            <input ref={rr => this.input = rr}
                name="bookinggood-search"
                value={search}
                onChange={e => onChange(e.target.value)}
                placeholder={placeholder}
            />
            <Icon>search</Icon>
        </form>

    }
}

const SearchBar = connect(
    state => {
        return {
            search: state.search.search,
        }
    },
    dispatch => {
        return {
            onChange: search => dispatch(applySearch(search))
        }
    }
)(Search)

const Dashboard = ({ tag }) => {
    return <BodyContainer wide key={1}>
        <div className="dashboard">
            <div className="my-bookings-panel">
                <MyBookings />
            </div>
            {/* <SearchBar className="mobile" /> */}
            <Filters tag={tag} />
            <div className="places">
                {/* <SearchBar className="desktop" /> */}
                <PlacesResults tag={tag} />
                <CoachesResults tag={tag} />
            </div>
        </div>
    </BodyContainer >
}
export default Dashboard


const BasicFilter = ({ title, items, selected, onSelect }) => {
    let selectedObj = {}
    if (selected) {
        selected.forEach(ss => selectedObj[ss] = true)
    }
    return <div>
        <div className="mobile-filter">
            <Selector
                items={[
                    { id: null, name: selected ? 'Clear' : title, comp: selected ? <NavLink to="/"><Trans>Clear</Trans></NavLink> : null },
                    null,
                    ...items.map(it => ({ id: it, name: it, comp: <NavLink to={tagURL(it)}><Trans>{it}</Trans></NavLink> })),
                ]}
                selected={selected && selected[0]}
                highlightSelected
                // onSelect={val => onSelect(val ? [val] : null)}
                onSelect={val => onSelect(null)}
            />
        </div>
        <div className="desktop-filter">
            <h1><Trans>{title}</Trans></h1>
            <ul>
                {!!selected && <li key="reset">
                    <NavLink to="/">
                        <Button
                            flat
                            onClick={() => onSelect(null)}
                        >
                            <Trans>Clear</Trans><Icon right>backspace</Icon>
                        </Button>
                    </NavLink>
                    <li key="separator" className="separator"><hr /></li>
                </li>}
                {items.map(it => {
                    const isSelected = !!selectedObj[it]
                    return <li key={it} className={cx({ selected: isSelected })}>
                        <NavLink to={tagURL(it)}>
                            <Button
                                flat
                                // onClick={() => onSelect([it])}
                                onClick={() => onSelect(null)}
                                title={it}
                            >
                                <Trans>{it}</Trans>
                            </Button>
                        </NavLink>
                    </li>
                })}
            </ul>
        </div>
    </div>
}
