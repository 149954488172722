const defaultState = {
    countries: {
        'Bulgaria': 'Bulgaria',
    },
}

export const COUNTRIES_UPDATED = 'COUNTRIES_UPDATED'
export const countriesUpdated = countries => ({
    type: COUNTRIES_UPDATED,
    countries,
})

export const reducerData = (state = defaultState, action) => {
    switch (action.type) {
        case COUNTRIES_UPDATED: {
            const { countries } = action
            return { ...state, countries }
        }
        default:
            return state
    }
}