module.exports.dbPath = {
    managementUsers: (userId) => {
        return `/management-accounts/${userId}/sites/`
    },
    sites: () => {
        return 'sites/'
    },
    item: (siteId, itemId) => {
        return `sites/${siteId}/items/${itemId}/`
    },
    supplementaryItem: (siteId, itemId) => {
        return `sites/${siteId}/supplementary-items/${itemId}/`
    },
    sharedItem: (siteId, itemId) => {
        return `sites/${siteId}/shared-items/${itemId}/`
    },
    siteBookings(siteId) {
        return `/sites-bookings/${siteId}/`
    },
    siteRecurringBookings(siteId) {
        return `/sites-bookings-recurring/${siteId}/`
    },
    itemBookings(siteId, itemId) {
        return `/sites-bookings/${siteId}/${itemId}/`
    },
    itemRecurringBookings(siteId, itemId) {
        return `/sites-bookings-recurring/${siteId}/${itemId}/`
    },
    siteAdminBookings: siteId => {
        return `/sites-bookings-private/${siteId}/`
    },
    siteAdminUsers: siteId => {
        return `/site-users/${siteId}/`
    },
    siteAdminRecurringBookings(siteId) {
        return `/sites-bookings-recurring-private/${siteId}/`
    },
    siteAdminActivity(siteId) {
        return `/sites-activity-private/${siteId}/`
    },
    siteAdminCalendarSyncInfo(siteId) {
        return `/sites-calendar-sync-private/${siteId}/`
    },
    userProfile: userId => {
        return `/user-profiles/${userId}/`
    },
    userUpcoming: userId => {
        return `/user-profiles/${userId}/upcoming/`
    },
    userPermissionForSite: (userId, siteId) => `/user-profiles/${userId}/perms/id${siteId}/`,
    userPermissionForSites: (userId) => `/user-profiles/${userId}/perms/`,
    userConsent: (userId) => `/user-profiles/${userId}/concent/`,
    userWatchers: (userId) => `/user-profiles/${userId}/watchers/`,
    userRecurring: userId => {
        return `/user-profiles/${userId}/recurring/`
    },
}
