import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Trans } from 'react-i18next';
import moment from 'moment'
import {
    NavLink,
} from 'react-router-dom';
import Dropdown from 'react-materialize/lib/Dropdown'
import Icon from 'react-materialize/lib/Icon'
import { ButtonWithDoubleClickProtection } from './ButtonWithDoubleClickProtection'
import Col from 'react-materialize/lib/Col'
import Row from 'react-materialize/lib/Row'
import {
    adminAcceptBooking, adminRejectBooking,
} from '../../api'
import {
    notificationsEnabled,
    notificationsDisabled,
} from '../../reducers/user_profile'
import { sortBy } from '../../utils'
import { ToastNotification } from './ToastNotification'
import { ContactUs } from './ContactUs'
import { SwitchInput } from './SwitchInput'
import { NotificationsNotSupported } from '../HelpHints'
import cx from 'classnames';
import './NotificationsPanel.css'

function decapitalize(s) {
    return s[0].toLowerCase() + s.slice(1);
}

const Notification = ({ title, commands }) => <Row className="notification z-depth-1">
    <Col s={12} m={6} className="title">
        {title}
    </Col>
    <Col s={12} m={6} className="commands">
        {commands}
    </Col>
</Row>

const PendingBookingNotification = ({ booking, item, site, supplItem, user, onAccept, onReject }) => {
    let userLink = <NavLink to={`/admin/clients/id/${user.id}`}>{user.displayName}</NavLink>
    let descr = <Trans>{item.name}</Trans>
    if (supplItem) descr = <span>{descr} <Trans>with</Trans> {supplItem.name}</span>

    let date = decapitalize(moment.parseZone(booking.fromDate + site.timezoneOffsetSuffix).calendar())

    return <Notification
        title={<div>
            <div>{userLink}</div>
            <div>{descr}, {date}</div>
        </div>}
        commands={<div>
            <ButtonWithDoubleClickProtection className="accept" onClick={onAccept}><Trans>Accept</Trans></ButtonWithDoubleClickProtection>
            <ButtonWithDoubleClickProtection className="reject" onClick={onReject}><Trans>Reject</Trans></ButtonWithDoubleClickProtection>
        </div>}
    />
}

const SyncErrorNotification = ({ itemName }) => {
    return <Notification
        title={<div>
            {itemName} - <Trans>Error synchronizing with Google Calendar!</Trans>
            <br />
            <ContactUs />
        </div>}
    />
}

export const getNotifications = createSelector(
    [
        state => state.site_admin || {},
        state => state.sites,
        state => state.auth && state.auth.user,
        state => state.ui.applySiteId || (state.sites && state.sites.length && state.sites[0].id),
    ],
    (site_admin, sites, currentUser, currentSiteId) => {
        let users = site_admin.users
        let { pending_bookings, sync_calendar_info } = site_admin

        if (!users || !sites || !pending_bookings || !currentUser) return []

        // let restrictToSupplItemIds
        // if(currentUser.specialPermissionsInSites

        let sitesById = {}
        let itemsById = {}
        sites.forEach(ss => {
            sitesById[ss.id] = ss
            ss.items.forEach(it => itemsById[it.id] = it)
        })

        let timeNow = moment()

        let notifications = pending_bookings
            .sort(sortBy('fromDate'))
            .map(booking => {
                let user = users[booking.userId]
                let site = sitesById[booking.siteId]
                if (!site || !user) return null

                const sitePerm = currentUser.specialPermissionsInSites[site.id]
                if (!sitePerm) {
                    console.log('Notifications - weird - site perm not found', site, currentUser, booking)
                    return null
                }
                if (!booking.supplItemId
                    && !sitePerm.permissions.includes('admin')
                    && !sitePerm.permissions.includes('front_office')
                ) {
                    return null
                }
                if (booking.supplItemId
                    && sitePerm.supplItemIds
                    && sitePerm.permissions.includes('coach')
                    && !sitePerm.supplItemIds.includes(booking.supplItemId) // other coach
                ) {
                    return null
                }

                // if (mtz.tz(booking.fromDate, site.timezone).isBefore(timeNow)) return null
                let date = booking.fromDate + site.timezoneOffsetSuffix
                // console.log('!!! date', date)
                if (moment.parseZone(date).isBefore(timeNow)) return null

                let itemId = booking.originalItemId || booking.itemId
                let item = site.items.find(it => it.id === itemId)
                let supplItem = booking.supplItemId && site.supplementaryItems.find(it => it.id === booking.supplItemId)
                return {
                    type: 'pending',
                    data: { booking, user, item, site, supplItem },
                }
            }).filter(ii => !!ii)

        const sitePermForUser = currentUser.specialPermissionsInSites[currentSiteId]
        if(!sitePermForUser || !sitePermForUser.permissions) {
            console.log('Notifications - weird - site permissions for given site not found', {currentUser, currentSiteId, sitePermForUser})
            return []
        }
        const canSeeCalendarNotifications = sitePermForUser.permissions.includes('admin') || sitePermForUser.permissions.includes('front_office')
        if (sync_calendar_info && canSeeCalendarNotifications) {
            for (let itemId in sync_calendar_info) {
                if (sync_calendar_info[itemId].error) {

                    const item = itemsById[itemId] || { name: 'n/a' }
                    notifications.push({
                        type: 'sync_calendar',
                        data: {
                            itemName: item.name,
                            error: sync_calendar_info[itemId].error
                        },
                    })
                }
            }
        }
        return notifications
    }
)

const handleActions = () => {
    const onAccept = async notif => {
        try {
            await adminAcceptBooking(notif.booking.id)
        } catch (err) {
            ToastNotification.error('Operation has failed, try again later')
        }
    }
    const onReject = async notif => {
        try {
            await adminRejectBooking(notif.booking.id)
        } catch (err) {
            ToastNotification.error('Operation has failed, try again later')
        }
    }
    return {
        onAccept,
        onReject,
    }
}


export const NotificationsBadge = ({ number, corner, line }) => {
    let classes = {
        'notifications-badge': true,
        'badge-corner': !!corner,
        'badge-line': !!line,
    }
    return !!number ? <div className={cx(classes)}>{number}</div> : <div />
}
export const ConnectedNotificationsBadge = connect(
    (state, props) => {
        let number = getNotifications(state).length
        return {
            number,
            ...props,
        }
    },
)(NotificationsBadge)


const renderNotification = (notif, onAccept, onReject) => {
    switch (notif.type) {
        case 'pending':
            return <PendingBookingNotification {...notif.data}
                onAccept={() => onAccept(notif.data)}
                onReject={() => onReject(notif.data)}
            />
        case 'sync_calendar':
            return <SyncErrorNotification {...notif.data} />
        default:
            return <div>{JSON.stringify(notif)}</div>
    }
}

const __NotificationsIcon = ({ notifications = [], onAccept, onReject }) => {
    return <Dropdown
        trigger={
            <button className="link-button nav-button">
                <div className="notifications-icon">
                    <Icon>notifications</Icon>
                    <ConnectedNotificationsBadge corner />
                </div>
            </button>
        }
        options={{
            belowOrigin: true,
            closeOnClick: false,
            stoppropagation: true,
        }}
    >
        {/* {notifications.map(notif => <li><a>{notif.fromDate}</a></li>)} */}
        {notifications.map((notif, i) => <li key={i + '-' + Date.now()}>
            {renderNotification(notif, onAccept, onReject)}
        </li>)}
        <li>
            <div className="see-all">
                <NavLink to="/notifications"><Trans>See all</Trans></NavLink>
            </div>
        </li>
    </Dropdown>
}

const __NotificationsList = ({ notifications, onAccept, onReject }) => {
    return <div className="notifications-list">
        {notifications.map((notif, i) => <div key={i + '-' + Date.now()}>
            {renderNotification(notif, onAccept, onReject)}
        </div>)}
    </div>
}

export const NotificationsIcon = connect(
    state => ({ notifications: getNotifications(state) }),
    dispatch => handleActions,
)(__NotificationsIcon)

export const NotificationsList = connect(
    state => ({ notifications: getNotifications(state) }),
    dispatch => handleActions,
)(__NotificationsList)



export class __NotificationSettings extends React.Component {
    render() {
        const { supported, enabled, preferences } = this.props.notifications
        console.log('__NotificationSettings', { supported, enabled, preferences })
        const { onChangeEnabled } = this.props
        if (!supported) {
            return <NotificationsNotSupported />
        }
        return <div>
            <SwitchInput
                active={enabled}
                label="Notifications"
                onChange={value => onChangeEnabled(value)}
            />
        </div>
    }
}
export const NotificationSettings = connect(
    state => {
        const { notifications } = state.user_profile
        return {
            notifications,
        }
    },
    dispatch => {
        return {
            onChangeEnabled: enabled => dispatch(enabled ? notificationsEnabled() : notificationsDisabled())
        }
    }
)(__NotificationSettings)