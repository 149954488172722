import {
    put,
    take,
    takeEvery,
    cancel,
    fork
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {
    userUpdated,
    REFRESH_USER,
    LOGOUT,
} from '../reducers'
import {
    dbPath
} from 'shared/firebase'
import {
    instantiateUser
} from '../models'
import * as Sentry from '@sentry/browser'

const reSiteIdPrefix = /^site-/
const checkForAdminAccess = (userId) => {
    let path = dbPath.managementUsers(userId)

    return firebase.database().ref(path)
        .once('value')
        .then(snap => snap.val())
        .then(specPermFirebase => {
            if (!specPermFirebase) return null
            let specialPermissionsInSites = {}
            for (let key in specPermFirebase) {
                const data = specPermFirebase[key]
                let { siteId, permissions, supplItemIds = null } = data
                // console.log({ siteId, permissions, supplItemIds }, typeof supplItemIds)
                if (!siteId) { // old format
                    siteId = key.replace(reSiteIdPrefix, '')
                    permissions = data
                }
                specialPermissionsInSites[siteId] = {
                    permissions,
                    supplItemIds,
                }
            }
            return specialPermissionsInSites
        })
}

export function createIdTokenUpdateChannel() {
    const listener = eventChannel(
        emit => {
            let unsubscribe = firebase.auth().onIdTokenChanged(auth => {
                console.log(auth)
                if (auth) {
                    Promise.all([
                        checkForAdminAccess(auth.uid),
                        auth.getIdToken()
                    ])
                        .then(([specialPermissionsInSites, accessToken]) => {
                            emit({
                                auth,
                                accessToken,
                                specialPermissionsInSites
                            })
                        })
                } else {
                    emit({
                        auth: null
                    })
                }
            })
            return unsubscribe
        }
    )
    return listener
}

// const setAccessToken = (accessToken) => {
//     document.cookie = `new_access_token=${accessToken}`;
// }

export function* mainLoop() {
    const channel = createIdTokenUpdateChannel()
    while (true) {
        console.log('wait for auth channel')
        const data = yield take(channel)

        if (data.auth) {
            const { uid, displayName, phoneNumber, photoURL, email } = data.auth
            const hasPassword = !!data.auth.providerData.find(pp => pp.providerId === 'password')
            const { accessToken, specialPermissionsInSites } = data

            Sentry.configureScope((scope) => {
                scope.setUser({ id: uid })
            });

            let user = instantiateUser(uid, displayName, phoneNumber, photoURL, email)
            user.hasPassword = hasPassword
            if (specialPermissionsInSites) {
                user.specialPermissionsInSites = specialPermissionsInSites
            }
            // setAccessToken(accessToken)
            localStorage.setItem('accessToken', accessToken)
            yield put(userUpdated(
                user,
                accessToken,
            ))
        } else {
            yield put(userUpdated(null))
            // setAccessToken('')
            localStorage.setItem('accessToken', '')
        }
    }
}

function* logout() {
    firebase.auth().signOut()
}

export function* authUpdateSaga() {
    yield takeEvery(LOGOUT, logout)
    while (true) {
        let _ = yield fork(mainLoop)
        yield take(REFRESH_USER)
        yield cancel(_)
    }
}