import React from 'react'
import { Trans } from 'react-i18next'
import {
    Selector
} from './common'

export const SelectCoach = ({ supplementaryItems, selected, title, hideAny, hideSelectNone, onSelect, className }) => {
    if (!supplementaryItems.length) {
        return <span><Trans>No available coach</Trans></span>
    }
    let items = []
    if (!selected) {
        items.push({ id: '', name: title || 'Select coach' })
    } else if (!hideSelectNone) {
        items.push({ id: '', name: 'None' })
    }


    if (supplementaryItems.length > 1 && !hideAny) {
        items.push(null) // divider
        items.push({ id: '__any__', name: 'Any' })
        items.push(null) // divider
    }

    items = [...items, ...supplementaryItems]

    return <Selector
        items={items}
        selected={selected}
        highlightSelected
        onSelect={val => onSelect(val || null)}
    />
}