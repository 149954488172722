import React from 'react'
import GoogleMapReact from 'google-map-react';

export const GOOGLE_API_KEY = 'AIzaSyBu6Eax2Oc-aW5M30E80wZncaubWWuqZOo'

export const generateFullAddress = site => `${site.country},${site.city},${site.address},${site.name}`

const PinImg = () => <img style={{ display: 'block' }} alt="here" src="/static/assets/img/pin.png" />

const Pin = ({ lat, lng }) => <div lat={lat} lng={lng} style={{ position: 'absolute', transform: 'translate(-50%, -100%)', }}>
    <PinImg />
</div>

export class GoogleMaps extends React.Component {
    static defaultProps = {
        center: {
            lat: 42.69,
            lng: 23.32
        },
        zoom: 18,
    }

    defaultOptions = {
        overviewMapControl: true,
        // streetViewControl: true,
        rotateControl: true,
        mapTypeControl: true,
        styles: [
            // { // disables poi
            //     featureType: 'poi',
            //     elementType: 'labels',
            //     stylers: [{ visibility: 'off' }],
            // },
        ],
    }

    constructor(props) {
        super(props)
        this.state = {
            defaultCenter: props.coordinates || props.center
        }
    }
    // renderMarkers = (map, maps) => {
    //     let { coordinates } = this.state
    //     new maps.Marker({
    //         position: coordinates,
    //         map,
    //         title: ''
    //     });
    // }
    render() {
        const { height = '350px', width, zoom, showCenter, onChange } = this.props
        const { defaultCenter } = this.state
        const { coordinates } = this.props
        const centerStyle = {
            position: 'absolute',
            zIndex: 1,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -100%)',
        }
        return <div style={{ height, width, position: 'relative' }}>
            {showCenter && <div style={centerStyle}>
                <PinImg />
            </div>}
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                defaultCenter={defaultCenter}
                center={coordinates}
                defaultZoom={zoom}
                options={this.defaultOptions}
                // onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                // yesIWantToUseGoogleMapApiInternals
                onChange={onChange}
            >
                {!showCenter && coordinates && <Pin key={Date.now()} lat={coordinates.lat} lng={coordinates.lng} />}
            </GoogleMapReact>
        </div>
    }
}

export class GoogleMapsEmbed extends React.Component {
    render() {
        const { height = '350px', width = '100%', address } = this.props
        return <div>
            <div>{address}</div>
            <iframe
                title="maps"
                frameborder="0"
                style={{
                    height,
                    width,
                    border: 0,
                }}
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${encodeURIComponent(address)}`}
                allowfullscreen>
            </iframe>
        </div>
    }
}