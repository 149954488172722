import isEqual from 'lodash.isequal'

export const SITES_UPDATED = 'SITES_UPDATED'
export const sitesUpdated = (sites) => {
    return {
        type: SITES_UPDATED,
        sites,
    }
}

export const ITEM_EVENT_SAVED = 'ITEM_EVENT_SAVED'
export const itemEventSaved = (siteId, itemId, event) => ({
    type: ITEM_EVENT_SAVED,
    siteId, itemId,
    event,
})

export const reducerSites = (state = null, action) => {
    switch (action.type) {
        case SITES_UPDATED: {
            if (!isEqual(state, action.sites)) {
                return action.sites
            }
            return state;
        }
        case ITEM_EVENT_SAVED: {
            return state.map(site => {
                if (site.id !== action.siteId) return site
                return {
                    ...site,
                    items: site.items.map(item => {
                        if (item.id !== action.itemId) return item
                        return {
                            ...item,
                            events: item.events.map(event => {
                                if (event.id !== action.event.id) return event
                                return action.event
                            })
                        }
                    })
                }
            })
        }
        default:
            return state
    }
}
