export const SCREEN_SIZE_CHANGE = 'SCREEN_SIZE_CHANGE'
export const screenSizeChange = (size, width) => ({
    type: SCREEN_SIZE_CHANGE,
    size,
    width,
})

export const getScreenWidth = () => (typeof window !== 'undefined' ? window.innerWidth : 1280)

export const getScreenSize = (width) => {
    if (width <= 350) {
        return 'XS'
    } else if (width <= 500) {
        // } else if (width <= 600) {
        return 'S'
    } else if (width <= 992) {
        return 'M'
    } else if (width < 1280) {
        return 'L'
    } else if (width <= 1600) {
        return 'XL'
    } else if (width <= 1920) {
        return 'XXL'
    }
    return 'XXXL'
}

export const scheduleDaysToShow = (size, wide) => {
    if (!wide) {
        switch (size) {
            case 'XS':
            case 'S':
                return 1
            case 'M':
                return 2
            case 'L':
            case 'XL':
                return 3
            default:
                return 4
        }
    }

    switch (size) {
        case 'XS': return 1
        case 'S': return 2
        case 'M': return 3
        case 'L': return 3
        case 'XL': return 4
        case 'XXL': return 6
        default:
            return 7
    }
}

const defaultState = {
    width: getScreenWidth(),
    size: getScreenSize(getScreenWidth()),
}

export const reducerScreenSize = (state = defaultState, action) => {
    switch (action.type) {
        case SCREEN_SIZE_CHANGE:
            return {
                size: action.size,
                width: action.width,
            }
        default:
            return state
    }
}