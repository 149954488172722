import moment from 'moment'
import {
    put,
    take,
    cancel,
    fork,
    takeEvery,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/database'
import {
    USER_UPDATED,
    userBookingsUpdated,
    CHANGE_USER_DETAILS, changeUserDetailsFailed,
    // userConsentChanged,
} from '../reducers'
import {
    Booking
} from '../models/booking'
import {
    dbPath
} from 'shared/firebase'
import {
    generateBookingFromRecurringRule,
} from 'shared/bookings'
import {
    formatDate,
    extractDate,
} from 'shared/utils'


export function* userUpcomingChannel(userId) {
    console.log('userUpcomingChannel', userId)
    const channel = eventChannel(
        emit => {
            let today = formatDate(new Date())
            let momentEndDate = moment().add(2, 'weeks')
            let endDate = formatDate(momentEndDate)

            let ref = firebase.database().ref(dbPath.userUpcoming(userId))
                .orderByChild('fromDate')
                .startAt(today)
                .endAt(endDate)
            let refRecurring = firebase.database().ref(dbPath.userRecurring(userId))
                .orderByChild('validUntil')
                .startAt(today)
            let refSitePerms = firebase.database().ref(dbPath.userPermissionForSites(userId))
            let refWatchers = firebase.database().ref(dbPath.userWatchers(userId))
                .orderByChild('toDate')
                .startAt(today)

            let bookings, recurringRules, overrideRecurringForDate = {}, sitesPerms, watchers
            const notify = () => {
                if (!bookings || !recurringRules || !sitesPerms || !watchers) return
                let allBookings = [...bookings]

                for (let key in recurringRules) {
                    let ruleInfo = recurringRules[key]
                    let recurringId = ruleInfo.id //key.slice(1)
                    let recBookings = generateBookingFromRecurringRule(ruleInfo, today, endDate, ruleInfo.siteId, ruleInfo.itemId)

                    for (let booking of recBookings) {
                        let date = extractDate(booking.fromDate)
                        if (!overrideRecurringForDate[`${recurringId}-${date}`])
                            allBookings.push(booking)
                    }
                }
                emit({ allBookings, sitesPerms, watchers })
            }

            refRecurring.on('value', snap => {
                recurringRules = snap.val() || {}
                for (let key in recurringRules) {
                    let rule = recurringRules[key]
                    if (momentEndDate.isBefore(moment(rule.validFrom))) {
                        delete recurringRules[key]
                    }
                }
                console.log('user recurring', recurringRules)
                notify()
            })
            ref.on('value', snap => {
                let data = snap.val() || {}
                console.log('User', userId, 'upcoming', data)
                bookings = []
                for (let bookingId in data) {
                    const { fromDate, toDate, siteId, itemId, supplItemId, status, recurringId } = data[bookingId]
                    let booking = new Booking(fromDate, toDate, siteId, itemId)
                    booking.id = bookingId
                    booking.userId = userId
                    booking.status = status
                    if (supplItemId !== undefined) booking.supplItemId = supplItemId;
                    if (recurringId) booking.recurringId = recurringId

                    bookings.push(booking)

                    if (booking.recurringId) {
                        let date = extractDate(booking.fromDate)
                        overrideRecurringForDate[`${booking.recurringId}-${date}`] = booking
                    }
                }
                notify()
            })
            refSitePerms.on('value', snap => {
                let data = snap.val() || {}
                let stripPrefix = /^[^\d]+/
                let perms = {}
                for (let key in data) {
                    let val = data[key]
                    let siteId = key.replace(stripPrefix, '')
                    perms[siteId] = val
                }
                sitesPerms = perms
                console.log('sites perms', sitesPerms)
                notify()
            })
            refWatchers.on('value', snap => {
                let data = snap.val() || {}
                watchers = {}
                for (let key in data) {
                    let watcher = data[key]
                    if (watcher.active) {
                        watchers[watcher.groupId] = watcher
                    } else {
                        delete watchers[watcher.groupId]
                    }
                }
                console.log('watchers', watchers)
                notify()
            })
            return () => {
                refRecurring.off()
                ref.off()
                refSitePerms.off()
                refWatchers.off()
            }
        }
    )
    while (true) {
        let { allBookings, sitesPerms, watchers } = yield take(channel)
        yield put(userBookingsUpdated(allBookings, sitesPerms, watchers))
    }
}

let userId;
let watcher //, watcherConsent;

function* fbChangeUserDetails(action) {
    //let user = yield select(state => state.auth.user)
    let user = firebase.auth().currentUser
    let phoneNumber = action.details.phoneNumber
    if (phoneNumber !== user.phoneNumber) {
        try {
            yield user.linkWithPhoneNumber(phoneNumber, new firebase.auth.RecaptchaVerifier('gaga-verifier'))
        } catch (err) {
            yield put(changeUserDetailsFailed(err.message))
        }
    }

}

// function* userConsentChannel(userId) {
//     const channel = eventChannel(
//         emit => {
//             let refConsent = firebase.database().ref(dbPath.userConsent(userId))

//             refConsent.on('value', snap => {
//                 let consent = snap.val() || null
//                 emit({ consent })
//             })
//             return () => {
//                 refConsent.off()
//             }
//         }
//     )
//     while (true) {
//         let { consent } = yield take(channel)
//         yield put(userConsentChanged(consent))
//     }
// }

export function* userUpcomingLoader() {
    yield takeEvery(CHANGE_USER_DETAILS, fbChangeUserDetails)
    while (true) {
        let action = yield take(USER_UPDATED)
        if (!action.user) {
            if (watcher) {
                yield cancel(watcher);
                yield put(userBookingsUpdated([], {}))
            }
            watcher = userId = null
            // if (watcherConsent) yield cancel(watcherConsent);
            // watcher = watcherConsent = userId = null

            // yield put(userConsentChanged(null))
            // yield put(userConsentChanged(undefined)) // should be undefined, because it means not--yet-loaded, while null is not-given

            continue
        }

        if (userId !== action.user.uid && watcher) yield cancel(watcher);
        // if (userId !== action.user.uid && watcherConsent) yield cancel(watcherConsent);

        userId = action.user.uid
        watcher = yield fork(userUpcomingChannel, userId)
        // watcherConsent = yield fork(userConsentChannel, userId)
    }
}
