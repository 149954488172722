import React from 'react';
import Preloader from 'react-materialize/lib/Preloader'
// import {
//     CircularProgress
// } from 'material-ui';

export const Loading = ({ fitToMax }) => {
    // return <CircularProgress />
    return <div style={{
        flex: fitToMax ? '1 0 auto' : undefined,
        display: 'flex',
        height: '100%',
        // width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Preloader flashing />
    </div>
}
