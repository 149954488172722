import React from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next'
import cx from 'classnames'
import './SideNavigation.css'

export const SideNavigation = withRouter(({ options, location }) => {
    console.log('sidenav', location)
    return <ul>
        {options.map(([url, title]) => {
            return <li className={location.pathname.indexOf(url) === 0 ? 'active' : ''} key={title}>
                <NavLink to={url}><Trans>{title}</Trans></NavLink>
            </li>
        })}
    </ul>
})

export const ContainerWithSideNav = ({ options, wide, children }) => {
    return <div className="side-nav-container">
        <div className="side-panel z-depth-1 hide-on-med-and-down">
            <div key={1}>
                <SideNavigation options={options} />
            </div>
        </div>
        <div className={cx({
            "main-panel": true,
            "capped": !wide,
        })}>
            {wide
                ? children
                : <div className="main-panel-inner">{children}</div>
            }
        </div>
    </div>
}
