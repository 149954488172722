import isEqual from 'lodash.isequal'

export const USER_UPDATED = 'USER_UPDATED'
export const userUpdated = (user, accessToken) => {
    return {
        type: USER_UPDATED,
        user,
        accessToken,
    }
}

export const REFRESH_USER = 'REFRESH_USER'
export const refreshUser = () => ({
    type: REFRESH_USER,
})

export const LOGOUT = 'LOGOUT'
export const logout = () => ({
    type: LOGOUT,
})

export const reducerAuth = (state = null, action) => {
    // console.log('reducerUser action', action)
    switch (action.type) {
        case USER_UPDATED: {
            if (!state || !isEqual(state.user, action.user)) {
                return {
                    user: action.user
                }
            }
            return state;
        }
        default:
            return state
    }
}
