import React from 'react'
import Icon from 'react-materialize/lib/Icon'
import './WithIcon.css'

export default ({ icon, children }) => {
    return <div className="with-icon">
        <Icon>{icon}</Icon>
        {children}
    </div>
}
