import React from 'react';
import { Trans } from 'react-i18next';
import Button from 'react-materialize/lib/Button'
import Input from 'react-materialize/lib/Input'
import { connect } from 'react-redux';
import {
    refreshUser,
} from '../reducers'
import firebase from 'firebase/app';
import 'firebase/auth'
import './Login.css';
import './SetOrChangePhoneNumber.css'

class __SetOrChangePhoneNumber extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: props.phoneNumber,
            errorVerify: null,
            verificationCode: '',
            errorConfirm: null,
            confirmResult: null,
            inProgress: false,
        }
    }
    verify = () => {
        let { phoneNumber } = this.state
        if (!phoneNumber || phoneNumber[0] !== '+' || phoneNumber.length < 8) {
            return this.setState({ errorVerify: 'Phone number seems invalid, should be with country code like +359888000111' })
        }
        let user = firebase.auth().currentUser
        this.setState(
            { errorVerify: null, inProgress: true, showRecaptcha: true },
            () => {
                user.linkWithPhoneNumber(phoneNumber, new firebase.auth.RecaptchaVerifier('recaptcha-verifier'))
                    .then(confirmResult => this.setState({ confirmResult }))
                    .catch(err => {
                        this.setState({ errorVerify: err.message })
                        alert(err.message)
                    })
                    .then(() => this.setState({ inProgress: false, showRecaptcha: false }))
            })
    }
    confirm = () => {
        let { confirmResult, verificationCode } = this.state
        let { onDone } = this.props
        this.setState({ errorConfirm: null, inProgress: true })
        console.log('Confirm with code', verificationCode)
        confirmResult.confirm(verificationCode)
            .then(() => {
                console.log('Confirmed')
                this.setState({ confirmResult: null, inProgress: false })
                onDone()
            })
            .catch(err => {
                this.setState({ errorConfirm: err.message, inProgress: false })
                alert(err.message)
            })
    }
    unlink = () => {
        let { onDone } = this.props
        let user = firebase.auth().currentUser
        user.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID)
            .then(() => onDone())
    }
    componentWillReceiveProps(props) {
        let { phoneNumber } = props
        if (phoneNumber !== this.state.phoneNumber)
            this.setState({ phoneNumber })
    }
    componentDidMount() {
        const { language } = this.props
        firebase.auth().languageCode = language
    }
    render() {
        let { showLabels } = this.props
        let { phoneNumber, verificationCode, confirmResult, errorVerify, errorConfirm, inProgress, showRecaptcha } = this.state
        const onSubmit = e => { e.preventDefault(); confirmResult ? this.confirm() : this.verify() }
        const showConfirm = !!confirmResult
        const showVerify = !confirmResult && phoneNumber !== this.props.phoneNumber
        // const showUnlink = !!this.props.phoneNumber

        return <form className="change-phone-form" onSubmit={onSubmit}>
            {!!showLabels && <div>
                {confirmResult
                    ? <Trans>Please enter received verification code</Trans>
                    : <Trans>Please enter your phone number. You will receive SMS with verification code.</Trans>}
            </div>}
            <div>{confirmResult
                ? <Input
                    type="number"
                    placeholder="Verification code"
                    value={verificationCode}
                    onChange={(e, val) => this.setState({ verificationCode: val })}
                    error={errorConfirm}
                />
                : <Input
                    type="tel"
                    placeholder="e.g. +359888000111"
                    value={phoneNumber || ''}
                    onChange={(e, val) => this.setState({ phoneNumber: val || null })}
                    error={errorVerify}
                />
            }
                <p className="error"><Trans>{errorVerify}</Trans></p>
                <p className="error"><Trans>{errorConfirm}</Trans></p>
            </div>
            <div>
                {showConfirm && <Button disabled={!verificationCode || verificationCode.length < 6 || inProgress} ><Trans>Confirm</Trans></Button>}
                {showVerify && <Button disabled={!phoneNumber || inProgress}  ><Trans>Verify</Trans></Button>}
                {/* {showUnlink && <Button type="button" onClick={this.unlink}><Trans>Unlink</Trans></Button>} */}
            </div>
            {showRecaptcha && <div id='recaptcha-verifier' style={{ marginTop: 5 }}></div>}
        </form>
    }
}
export const SetOrChangePhoneNumber = connect(
    state => {
        const { language } = state.ui
        return {
            language,
        }
    },
    (dispatch, ownProps) => ({
        onDone: () => {
            dispatch(refreshUser())
            ownProps.onConfirm && ownProps.onConfirm()
        }
    })
)(__SetOrChangePhoneNumber)