export class Item {
    id
    name
    capacity

    schedule

    siteId

    sharedItemId
    sharedItemMultiplier

    supplementaryTo

    config

    constructor(id, name) {
        this.id = id.toString()
        this.name = name
    }
}