import React from 'react'
import { Loading } from './common'
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'

const onReload = () => window.location.reload()

export class AsyncLoader extends React.Component {
    state = {
        Module: null,
        err: null,
    }
    componentWillMount = async () => {
        // let { filename } = this.props
        let { provider, name } = this.props
        try {
            // let module = await import(filename)
            // let module = await import('./premium.jsx')
            let module = await provider()
            this.setState({ Module: module[name || 'default'] })
        } catch (err) {
            this.setState({ err })
        }
    }
    render() {
        const { Module, err } = this.state
        const { key, provider, name, ...params } = this.props
        console.log('params', params)
        if (err) {
            console.log('Error loading module:', err)
            const style = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                flex: '1 0 auto',
                fontWeight: 300,
                fontSize: '1.3em',
                lineHeight: '2.5em',
            }

            return <div style={style}>
                <div>Oops, we had an issue loading this page. Please reload the page.</div>
                <div><a href={"mailto:info@bookinggood.net"}>Contact us</a> if you continue to experience this problem.</div>
                <br />
                <Button style={{ backgroundColor: '#4caf50' }} onClick={onReload}>Reload<Icon left>refresh</Icon></Button>
            </div>
        }

        if (!Module) {
            return <Loading />
        }
        return <Module {...params} />
    }
}