import React from 'react'
import Button from 'react-materialize/lib/Button'
import './IncreaseDecreaseControl.css'

const IncreaseDecreaseControl = ({ value, min, max, onIncrease, onDecrease }) => {
    return <div className="increase-decrease-control">
        <Button flat icon="remove"
            disabled={value <= min}
            onClick={() => onDecrease()}
        />
        <div className="incr-decr-value">{value}</div>
        <Button flat icon="add"
            disabled={value >= max}
            onClick={() => onIncrease()}
        />
    </div>
}

export default IncreaseDecreaseControl