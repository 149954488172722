const storageName = user => `Notifications-${user.email}`

// {token, storedOnServer}|false|null
export const checkUserNotificationsPermissions = user => {
    let perms = localStorage.getItem(storageName(user))
    if (perms === false) return false
    if (!perms) return null
    return JSON.parse(perms)
}

export const disableNotifications = user => {
    console.log('notifications disabled')
    localStorage.setItem(storageName(user), false)
}
export const storeLocally = (user, token, storedOnServer) => {
    localStorage.setItem(storageName(user), JSON.stringify({ token, storedOnServer }))
}