import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import { setInstance } from 'shared/i18n'
setInstance(i18n)

if (typeof window !== 'undefined') {
    i18n
        .use(Backend)
}

i18n
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        fallbackLng: 'en',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: '#!@',

        // debug: true,
        debug: false,

        load: 'languageOnly',
        preload: ['en', 'bg'],

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            wait: true
        }
    });


export default i18n;