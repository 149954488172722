export class Booking {
    id

    siteId
    itemId
    supplItemId
    userId


    // string?
    fromDate
    toDate

    status

    quantity = 1

    constructor(fromDate, toDate, siteId, itemId) {
        this.fromDate = fromDate
        this.toDate = toDate
        this.siteId = siteId
        this.itemId = itemId
    }
}
