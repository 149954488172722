import {
    createSelector
} from 'reselect'
import {
    mapBookingsToTimetable,
    generateTimelotsFromScheduleForRange,
    applyAdminChain,
} from 'shared/bookings'

export const selectorTimetable = createSelector(
    params => params.item,
    params => params.daysOffset,
    params => params.daysToShow,
    (item, daysOffset, daysToShow) => {
        const { schedule, schedule_exceptions, events, capacity } = item
        // console.log('!!!', { schedule, capacity, daysOffset, daysToShow })

        const daysOffsetTo = daysOffset + daysToShow
        return schedule
            ? generateTimelotsFromScheduleForRange(schedule, schedule_exceptions, daysOffset, daysOffsetTo, capacity, events)
            : null
    }
)
export const selectorMapBookingsToTimetable = createSelector(
    params => params.bookings,
    params => params.timetable,
    params => params.sharedItemMultiplier || 1,
    params => params.sharedItemCapacity || null,
    params => params.originalItemId || null,
    params => params.includeBookings || null,
    params => params.includeUnmapped || null,
    (bookings, timetable, sharedItemMultiplier, sharedItemCapacity, originalItemId, includeBookings, includeUnmapped) => {
        return mapBookingsToTimetable(timetable, bookings, sharedItemMultiplier, sharedItemCapacity, originalItemId, includeBookings, includeUnmapped)
    }
)

export const selectorApplyAdminChain = createSelector(
    params => params.bookings,
    params => params.site,
    params => params.adminRecurringRules,
    params => params.adminBookings,
    params => params.adminUsers,
    (bookings, site, adminRecurringRules, adminBookings, adminUsers) =>
        applyAdminChain(bookings, site, adminRecurringRules, adminBookings, adminUsers)
)