import moment from 'moment'
// import * as sharedUtils from 'shared/utils'
export {
    formatDatetime,
    formatDate,
    formatTime,
    extractDate,
    extractTime,
    convertToTimezone,
    getTimeNowForSite,
    ObjectMap,
    ObjectFilter,
    ObjectValues,
    ArrayToObjectByKey,
    sortBy,
    logError,
    normalizePhoneNumber,
    formatPhoneNumber,
    isPassed,
    isDatetimePassed,
    isDateVaild,
} from 'shared/utils'


export const ajax_get = (url) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true)
        // xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.setRequestHeader('new_access_token', localStorage.getItem('accessToken'))
        xhr.responseType = 'json'
        xhr.onload = e => {
            if (xhr.status === 200) {
                console.log(xhr.response);
                resolve(xhr.response)
            } else {
                console.log('Error, status:', xhr.status)
                reject(xhr.statusText)
            }
        }
        xhr.onerror = () => {
            reject('Network error')
        }
        xhr.send();
    })
}

const ajaxPostOrPut = (url, data, method) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url, true)
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.setRequestHeader('new_access_token', localStorage.getItem('accessToken'))
        xhr.responseType = 'json'
        xhr.onload = e => {
            if (xhr.status === 200) {
                console.log(xhr.response);
                resolve(xhr.response)
            } else {
                console.log('Error, status:', xhr.status)
                reject(xhr.statusText)
            }
        }
        xhr.onerror = () => {
            reject('Network error')
        }
        xhr.send(JSON.stringify(data));
    })
}

export const ajax_post = (url, data) => ajaxPostOrPut(url, data, 'POST')
export const ajax_put = (url, data) => ajaxPostOrPut(url, data, 'PUT')

export const ajax_delete = (url) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', url, true)
        xhr.setRequestHeader('new_access_token', localStorage.getItem('accessToken'))
        xhr.onload = e => {
            if (xhr.status === 200) {
                console.log(xhr.response);
                resolve(xhr.response)
            } else {
                console.log('Error, status:', xhr.status)
                reject(xhr.statusText)
            }
        }
        xhr.onerror = () => {
            reject('Network error')
        }
        xhr.send();
    })
}

export const getCookie = name => {
    const cookies = document.cookie.split(/;\s*/)
    const regex = new RegExp(name + '=(.*)')
    for (let cc of cookies) {
        const res = cc.match(regex)
        if (res && res[1]) {
            return decodeURIComponent(res[1])
        }
    }
}