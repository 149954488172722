const defaultState = null

export const SITE_ADMIN_USERS_UPDATED = 'SITE_ADMIN_USERS_UPDATED'
export const siteAdminUsersUpdated = users => {
    return {
        type: SITE_ADMIN_USERS_UPDATED,
        users,
    }
}

export const SITE_ADMIN_BOOKINGS_UPDATED = 'SITE_ADMIN_BOOKINGS_UPDATED'
export const siteAdminBookingsUpdated = bookings => {
    return {
        type: SITE_ADMIN_BOOKINGS_UPDATED,
        bookings,
    }
}

export const SITE_ADMIN_PENDING_BOOKINGS_UPDATED = 'SITE_ADMIN_PENDING_BOOKINGS_UPDATED'
export const siteAdminPendingBookingsUpdated = bookings => {
    return {
        type: SITE_ADMIN_PENDING_BOOKINGS_UPDATED,
        bookings,
    }
}

export const SITE_ADMIN_RECURRING_BOOKINGS_UPDATED = 'SITE_ADMIN_RECURRING_BOOKINGS_UPDATED'
export const siteAdminRecurringBookingsUpdated = rules => {
    return {
        type: SITE_ADMIN_RECURRING_BOOKINGS_UPDATED,
        rules,
    }
}

export const SITE_ADMIN_SYNC_CALENDAR_INFO_UPDATED = 'SITE_ADMIN_SYNC_CALENDAR_INFO_UPDATED'
export const siteAdminSyncCalendarInfoUpdated = sync_calendar_info => {
    return {
        type: SITE_ADMIN_SYNC_CALENDAR_INFO_UPDATED,
        sync_calendar_info,
    }
}

export const SITE_ADMIN_ACTIVITY_UPDATED = 'SITE_ADMIN_ACTIVITY_UPDATED'
export const siteAdminActivityUpdated = activity => {
    return {
        type: SITE_ADMIN_ACTIVITY_UPDATED,
        activity,
    }
}

export const SITE_ADMIN_SUBSCRIPTION_INFO_UPDATED = 'SITE_ADMIN_SUBSCRIPTION_INFO_UPDATED'
export const siteAdminSubscriptionInfoUpdated = subscriptionInfo => {
    return {
        type: SITE_ADMIN_SUBSCRIPTION_INFO_UPDATED,
        subscriptionInfo,
    }
}
export const SITE_ADMIN_UPDATE_SUBSCRIPTION_INFO = 'SITE_ADMIN_UPDATE_SUBSCRIPTION_INFO'
export const siteAdminUpdateSubscriptionInfo = () => {
    return {
        type: SITE_ADMIN_UPDATE_SUBSCRIPTION_INFO,
    }
}

export const SITE_ADMIN_UNLOAD_ALL = 'SITE_ADMIN_UNLOAD_ALL'
export const siteAdminUnloadAll = () => {
    return {
        type: SITE_ADMIN_UNLOAD_ALL,
    }
}

export const reducerSiteAdmin = (state = defaultState, action) => {
    switch (action.type) {
        case SITE_ADMIN_USERS_UPDATED:
            return {
                ...state,
                users: action.users,
            }
        case SITE_ADMIN_BOOKINGS_UPDATED:
            return {
                ...state,
                bookings: action.bookings,
            }
        case SITE_ADMIN_PENDING_BOOKINGS_UPDATED:
            return {
                ...state,
                pending_bookings: action.bookings,
            }
        case SITE_ADMIN_RECURRING_BOOKINGS_UPDATED:
            return {
                ...state,
                rules: action.rules,
            }
        case SITE_ADMIN_SYNC_CALENDAR_INFO_UPDATED:
            return {
                ...state,
                sync_calendar_info: action.sync_calendar_info,
            }
        case SITE_ADMIN_ACTIVITY_UPDATED:
            return {
                ...state,
                activity: action.activity,
            }
        case SITE_ADMIN_SUBSCRIPTION_INFO_UPDATED:
            return {
                ...state,
                subscriptionInfo: action.subscriptionInfo,
            }
        case SITE_ADMIN_UNLOAD_ALL:
            return defaultState
        default:
            return state;
    }
}