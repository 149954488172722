import React from 'react'
import { Trans } from 'react-i18next';
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'
import Dropdown from 'react-materialize/lib/Dropdown'
import NavItem from 'react-materialize/lib/NavItem'
import cx from 'classnames'
import './Selector.css'

// items -> [ {id, name, comp, img} or name ]
export const Selector = ({ items, selected, highlightSelected, showBackgroundColor = true, showArrow = true, placeholder = null, onSelect }) => {
    let selectedName = placeholder;
    let navItems = items.map((it, i) => {
        if (it === null) return <NavItem divider key={i} />
        let { id, name, comp, img } = typeof it === 'object' ? it : { id: it, name: it }
        if (id === selected || selectedName === null) {
            selectedName = name
        }
        if (comp) {
            return <li key={'' + i + Date.now()}>{comp}</li>
        }
        return <NavItem key={i + '-' + id}
            className={cx({ active: selected === id })}
            onClick={() => onSelect(id)}>
            <Trans>{name}</Trans>
            {img && <img className="right" src={img} />}
        </NavItem>
    })
    return <div className={cx({
        'fm-selector': true,
        'fm-selector-with-background': showBackgroundColor,
        'fm-selected': !!(selected && highlightSelected),
    })}>
        <Dropdown
            // alt icon: expand_more
            trigger={<Button flat><Trans>{selectedName}</Trans>{showArrow ? <span className="arrow-drop-down">▼</span> : ''}</Button>}
        >
            {navItems}
        </Dropdown>
    </div>
}