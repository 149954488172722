import React from 'react'
import { Trans } from 'react-i18next';
import Button from 'react-materialize/lib/Button'
import cx from 'classnames'
import './DaySwitcher.css'

export const DaySwitcher = ({ disablePrev, disableNext, compact, warningHighlight = false, onPrev, onNext, onToday }) => {
    return <div className={cx({
        "day-switcher": true,
        "compact": !!compact,
        "warning-highlight": warningHighlight,
    })}>
        <Button
            className="today"
            flat={!!compact}
            onClick={onToday}
        >
            <Trans>Today</Trans>
        </Button>
        <Button
            disabled={!!disablePrev}
            onClick={onPrev}
            flat={!!compact}
            icon="chevron_left"
        />
        <Button
            disabled={!!disableNext}
            onClick={onNext}
            flat={!!compact}
            icon="chevron_right"
        />
    </div>
}