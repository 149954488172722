import {
    put,
    take,
    fork,
    cancel,
    call,
    delay,
} from 'redux-saga/effects'
import {
    eventChannel, buffers,
} from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'
import {
    dbPath
} from 'shared/firebase'
import { STATUS } from 'shared/types'
import {
    USER_UPDATED,
    siteAdminUsersUpdated, siteAdminBookingsUpdated,
    siteAdminPendingBookingsUpdated,
    siteAdminRecurringBookingsUpdated,
    siteAdminSyncCalendarInfoUpdated,
    siteAdminActivityUpdated,
    siteAdminSubscriptionInfoUpdated, SITE_ADMIN_UPDATE_SUBSCRIPTION_INFO,
    siteAdminUnloadAll,
    APPLY_ADMIN_SITE_ID,
} from '../reducers'
import {
    getSubscriptionInfo,
} from '../api'
import 'core-js/fn/object/values'
import { sortBy } from '../utils';

let siteId;
let bookingsListener, pendingBookingsListener, recurringBookingsListener, calendarSyncInfoListener, activityListener, usersListener, subscriptionStatusLoader;

export function* __firebaseSiteUsersListener(siteId) {
    let ref = firebase.database().ref(dbPath.siteAdminUsers(siteId))
    let channel = eventChannel(
        emit => {
            ref.on('value', snap => {
                let data = snap.val()
                console.log('site', siteId, 'users', data)
                if (!data) {
                    data = []
                } else if (typeof data === 'object') {
                    data = Object.values(data)
                }
                let users = {}
                data.forEach(user => {
                    if (!user || user.deleted) return
                    if (user.level === undefined) user.level = 0
                    users[user.id] = user
                })
                emit(users)
            })
            return () => ref.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)
        yield put(siteAdminUsersUpdated(data))
        yield delay(300)
    }
}

export function* __firebaseSiteBookingsListener(siteId) {
    let ref = firebase.database()
        .ref(dbPath.siteAdminBookings(siteId))
        .orderByChild('fromDate')
        .startAt(moment().subtract(3, 'month').format('YYYY-MM-DD'))
    let channel = eventChannel(
        emit => {
            ref.on('value', snap => {
                let data = snap.val()
                // console.log('site', siteId, 'bookings', data)
                emit(data || {})
            })
            return () => ref.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)
        yield put(siteAdminBookingsUpdated(data))
        yield delay(300)
    }
}

export function* __firebaseSitePendingBookingsListener(siteId) {
    let ref = firebase.database()
        .ref(dbPath.siteAdminBookings(siteId))
        .orderByChild('status')
        .equalTo(STATUS.PENDING)

    let channel = eventChannel(
        emit => {
            ref.on('value', snap => {
                let data = snap.val()
                // console.log('site', siteId, 'pending bookings', data)
                emit(data || {})
            })
            return () => ref.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)

        data = Object.values(data)

        yield put(siteAdminPendingBookingsUpdated(data))
        yield delay(300)
    }
}

const idPrefix = /^[^\d]+/
const cutPrefix = val => val.replace(idPrefix, '')

export function* __firebaseSiteRecurringBookingsListener(siteId) {
    let refRecurringUsers = firebase.database().ref(dbPath.siteAdminRecurringBookings(siteId))

    let channel = eventChannel(
        emit => {
            refRecurringUsers.on('value', snap => {
                snap = snap.val() || {}
                let data = {}
                for (let key in snap) {
                    let recurringId = cutPrefix(key)
                    data[recurringId] = snap[key]
                }
                // console.log('@@@ recurring users', data)

                emit(data)
            })
            return () => refRecurringUsers.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)
        yield put(siteAdminRecurringBookingsUpdated(data))
        yield delay(300)
    }
}

export function* __firebaseSiteCalendarSyncInfoListener(siteId) {
    let ref = firebase.database().ref(dbPath.siteAdminCalendarSyncInfo(siteId))

    let channel = eventChannel(
        emit => {
            ref.on('value', snap => {
                snap = snap.val() || {}
                let data = {}
                for (let key in snap) {
                    const value = snap[key]
                    if (value) data[key] = value
                }

                emit(data)
            })
            return () => ref.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)
        yield put(siteAdminSyncCalendarInfoUpdated(data))
        yield delay(300)
    }
}

export function* __firebaseActivityListener(siteId) {
    let ref = firebase.database().ref(dbPath.siteAdminActivity(siteId))
        .orderByChild('datetime')
        .limitToLast(200)

    let channel = eventChannel(
        emit => {
            ref.on('value', snap => {
                snap = snap.val() || {}
                let data = Object.keys(snap)
                    .map(key => ({ id: key, ...snap[key] }))
                    .sort(sortBy('datetime'))
                    .reverse()
                emit(data)
            })
            return () => ref.off()
        },
        buffers.sliding(1)
    )
    while (true) {
        let data = yield take(channel)
        yield put(siteAdminActivityUpdated(data))
        yield delay(300)
    }
}

function* getSubscriptionInfoWithRetry(siteId) {
    let timeout = 1
    while (true) {
        try {
            return yield call(getSubscriptionInfo, siteId)
        } catch (err) {
            console.log('Unable to load subscription info', err, '- try again later')
            yield delay(timeout * 1000)
            if (timeout < 64) timeout *= 2
        }
    }
}

export function* __loadSubscriptionInfo(siteId) {
    let subscriptionInfo = yield call(getSubscriptionInfoWithRetry, siteId)
    yield put(siteAdminSubscriptionInfoUpdated(subscriptionInfo))

    let newSubscriptionInfo = null
    while (true) {
        if (!newSubscriptionInfo)
            yield take(SITE_ADMIN_UPDATE_SUBSCRIPTION_INFO)

        newSubscriptionInfo = yield call(getSubscriptionInfoWithRetry, siteId)
        // console.log('Reload subscription status', { subscriptionStatus, newSubscriptionStatus })

        if (newSubscriptionInfo.subscriptionStatus === subscriptionInfo.subscriptionStatus) {
            // ignore it and try again little bit later
            // console.log('Subscription status is not changed. Wait a moment and try again')
            yield delay(1000)
        } else {
            subscriptionInfo = newSubscriptionInfo
            newSubscriptionInfo = null
            yield put(siteAdminSubscriptionInfoUpdated(subscriptionInfo))
        }
    }
}

export function* siteAdminLoader() {
    let user = null

    while (true) {
        let action = yield take([USER_UPDATED, APPLY_ADMIN_SITE_ID])
        let newSiteId
        if (action.type === APPLY_ADMIN_SITE_ID) {
            newSiteId = siteId = action.siteId
            if (!user) continue
        }
        if (action.type === USER_UPDATED) {
            user = action.user
            if (user && user.specialPermissionsInSites && !siteId) {
                siteId = Object.keys(user.specialPermissionsInSites)[0]
                console.log('autoset admin site id', siteId)
            }
        }

        const noPerms = (!user || !user.specialPermissionsInSites || !user.specialPermissionsInSites[siteId])

        if (bookingsListener && (noPerms || newSiteId)) {
            yield cancel(bookingsListener)
            yield cancel(pendingBookingsListener)
            yield cancel(recurringBookingsListener)
            yield cancel(calendarSyncInfoListener)
            yield cancel(activityListener)
            yield cancel(usersListener)
            yield cancel(subscriptionStatusLoader)
            bookingsListener = recurringBookingsListener = pendingBookingsListener = usersListener = subscriptionStatusLoader = null
            yield put(siteAdminUnloadAll())
        }

        if (noPerms) {
            siteId = null
            continue;
        }

        if (bookingsListener && !newSiteId) {
            continue;
        }

        bookingsListener = yield fork(__firebaseSiteBookingsListener, siteId)
        pendingBookingsListener = yield fork(__firebaseSitePendingBookingsListener, siteId)
        recurringBookingsListener = yield fork(__firebaseSiteRecurringBookingsListener, siteId)
        calendarSyncInfoListener = yield fork(__firebaseSiteCalendarSyncInfoListener, siteId)
        activityListener = yield fork(__firebaseActivityListener, siteId)
        usersListener = yield fork(__firebaseSiteUsersListener, siteId)
        subscriptionStatusLoader = yield fork(__loadSubscriptionInfo, siteId)
    }
}