import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet"
import { i18n } from 'shared/i18n'

export const Meta = connect(
    state => ({
        lang: state.ui.language,
    })
)(({ title, description = '', lang, canonical }) => {
    return <Helmet>
        <title lang={lang}>{i18n().t(title) + ' | BookingGood'}</title>
        <meta name="description" content={i18n().t(description)} />
        <body lang={lang} />
        {canonical && [
            <link rel="canonical" href={window.location.origin + (lang === 'en' ? '' : '/' + lang) + canonical} key="can" />,
            <link rel="alternate" hrefLang="en" key="en" href={window.location.origin + canonical} />,
            <link rel="alternate" hrefLang="bg" key="bg" href={window.location.origin + '/bg' + canonical} />,
            <link rel="alternate" hrefLang="ru" key="ru" href={window.location.origin + '/ru' + canonical} />,
        ]}
    </Helmet>
})