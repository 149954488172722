import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n'
// import './materialize.css';
import 'shared/materialize.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga, {
    authUpdateSaga,
    dataLoader,
    sitesLoader,
    bookingsLoader,
    siteAdminLoader,
    userUpcomingLoader,
    screenSizeWatcher,
    notificationsSaga,
    searchSaga,
    uiHeartbeat,
    locationSaga,
} from './sagas';
import {
    reducerAuth,
    reducerData,
    reducerSites,
    reducerBookings,
    reducerSiteAdmin,
    reducerUserProfile,
    reducerScreenSize,
    reducerUI,
    reducerSearch,
    applySiteId,
    // applyAdminSiteId,
    changeDaysOffset,
} from './reducers';
import {
    changeLanguage,
    newVersionAvailable,
} from './reducers';
import {
    recognizeLanguage,
    extractLangFromUrlPath,
} from 'shared/utils'
import firebase from 'firebase/app';
import moment from 'moment'
import 'moment/locale/bg.js'
import 'moment/locale/ru.js'
import * as GA from './utils/analytics'
import * as Sentry from '@sentry/browser'

var firebaseConfig
// let customConf = getCookie('FIREBASE_CLIENT_CONFIG')
// let customConf = window.spb_custom__conf__42
let customConf = process.env.REACT_APP_FIREBASE_CLIENT_CONFIG
if (customConf) {
    console.log('Use custom fb config')
    // console.log('!!!!!! conf', customConf)
    // console.log('!!!!!! conf', JSON.parse(customConf))
    firebaseConfig = JSON.parse(customConf)
    // } else if (process.env.NODE_ENV === `development`) {
    //     firebaseConfig = require('./utils/firebase-config.json')
} else {
    GA.event({ category: 'App', action: 'Unable to load sp-init' })

    if (window.confirm('Unable to connect to server, try again?')) {
        window.location.reload()
    }
}
console.log('Use firebase config for', firebaseConfig.databaseURL)
firebase.initializeApp(firebaseConfig)
if (!customConf) { // should be dev env only
    firebase.auth().settings.appVerificationDisabledForTesting = true;
}
const sagaMiddleware = createSagaMiddleware()

const reducers = combineReducers({
    auth: reducerAuth,
    data: reducerData,
    sites: reducerSites,
    bookings: reducerBookings,
    site_admin: reducerSiteAdmin,
    user_profile: reducerUserProfile,
    screen_size: reducerScreenSize,
    ui: reducerUI,
    search: reducerSearch,
})

const middlewares = [
    sagaMiddleware
];

if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);

    middlewares.push(logger);
} else {
    // PRODUCTION
    Sentry.init({ dsn: 'https://d459a575e20c46fd87a2a60079399299@sentry.io/1238541' })
}

const store = createStore(
    reducers,
    applyMiddleware(...middlewares)
)

let embedData = window.location.pathname.match(/^\/embed\/(club|facility)\/(\d+)/)
if (embedData) {
    // console.log('!!embedData', embedData)
    const siteId = embedData[2]
    store.dispatch(applySiteId(siteId))
}
// currently we don't put admin site id to url, but we could
// else {
//     let adminSiteIdData = window.location.pathname.match(/\/(\d+)\/admin\//)
//     if (adminSiteIdData) {
//         store.dispatch(applyAdminSiteId(adminSiteIdData[1]))
//     }
// }



if (window.location.search) {
    let res = window.location.search.match(/initial-date=(\d{4}-\d{2}-\d{2})/)
    if (res) {
        const dateStr = res[1];
        const initialDate = moment(dateStr)
        if (initialDate.isValid()) {
            const daysOffset = initialDate.diff(moment().startOf('day'), 'days')
            console.log('Set initial date', dateStr, daysOffset)
            store.dispatch(changeDaysOffset(daysOffset))
        }

    }
}

const applyLang = lang => {
    i18n.changeLanguage(lang)

    window.localStorage.setItem('pre_set_language', lang)
}

if (window.location.search) {
    let re = /lang=([a-z]+)/
    let res = window.location.search.match(re)
    if (res) {
        console.log('Detected lang from query args', res)
        const lang = recognizeLanguage(res[1])
        applyLang(lang)
    }

    res = window.location.search.match(/auth-token=(.+)/)
    if (res) {
        const token = res[1]
        console.log('auth token detected, sign in', token)
        firebase.auth().signInWithCustomToken(token)
            .then(() => {
                window.history.replaceState(null, null, window.location.pathname);
            })
            .catch(err => {
                console.log('Error signing-in with custom token:', err)
            })
    }
} else if (window.location.pathname !== '/') {
    const lang = extractLangFromUrlPath(window.location.pathname)
    if (lang) {
        applyLang(lang)
    }
}
i18n.on('languageChanged', lng => {
    const lang = recognizeLanguage(lng)
    console.log('languageChanged', lng, lang)
    moment.locale(lang)
    store.dispatch(changeLanguage(lang))
})

sagaMiddleware.run(rootSaga)
sagaMiddleware.run(locationSaga)
sagaMiddleware.run(authUpdateSaga)
sagaMiddleware.run(sitesLoader)
sagaMiddleware.run(dataLoader)
sagaMiddleware.run(bookingsLoader)
sagaMiddleware.run(siteAdminLoader)
sagaMiddleware.run(userUpcomingLoader)
sagaMiddleware.run(screenSizeWatcher)
sagaMiddleware.run(notificationsSaga)
sagaMiddleware.run(searchSaga)
sagaMiddleware.run(uiHeartbeat)

window.addEventListener('new-version', () => store.dispatch(newVersionAvailable()))

// window.Raven.context(() => {
ReactDOM.render((
    <Provider store={store}>
        <App />
    </Provider>
), document.getElementById('root'));
// })

// registerServiceWorker();
// Unregisters the service worker
// PWA is cool but it seems to complex for regular users
// https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
// E.g. requirement to close all browser tabs using old version of site if new one is available
// And there doesn't seem to be an easy way to overcome this.
// Seems like bad user experience for the moment, so I'm disabling PWA
unregister();