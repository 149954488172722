import {
    put,
    take,
} from 'redux-saga/effects'
import {
    countriesUpdated,
} from '../reducers'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/database'

export const __dataLoader = (fb = firebase) => emit => {
    let ref = fb.database().ref('translations/countries')

    ref
        .on('value', snapshot => {
            if (!snapshot) return

            let countries = snapshot.val()
            // console.log('countries:', countries)

            if (countries) emit({ countries })
        })

    return () => ref.off()
}

export function* dataLoader() {
    const channel = eventChannel(
        __dataLoader()
    )

    while (true) {
        let { countries } = yield take(channel)
        yield put(countriesUpdated(countries))
    }
}