import {
    put,
    call,
    take,
    delay,
} from 'redux-saga/effects'
import { i18n } from 'shared/i18n'
import {
    refreshAll,
    changeLocation,
    CHANGE_LOCATION,
} from '../reducers'
import {
    fetchIpInfo,
} from '../api'
import {
    country2lang,
} from 'shared/utils'

const SELECTED_COUNTRY = 'selected_country'
const SELECTED_CITY = 'selected_city'

export function* locationSaga() {
    const storedCountry = window.localStorage.getItem(SELECTED_COUNTRY)
    const storedCity = window.localStorage.getItem(SELECTED_CITY)
    if (storedCountry) {
        console.log('Country is already stored:', storedCountry)
        yield put(changeLocation(storedCountry, storedCity))
    } else {
        try {
            const { country, city } = yield call(fetchIpInfo)
            window.localStorage.setItem(SELECTED_COUNTRY, country)
            window.localStorage.setItem(SELECTED_CITY, city)
            console.log('Autodetected and stored', country, city)
            yield put(changeLocation(country, city))

            if (!window.localStorage.getItem('pre_set_language')) {
                const lang = country2lang(country)
                i18n().changeLanguage(lang)
            }
        } catch (err) {
            console.log('Error loading location info:', err)
            yield put(changeLocation('Bulgaria', 'Sofia'))
        }
    }

    while (true) {
        let action = yield take(CHANGE_LOCATION)
        const { country, city } = action
        console.log(action)
        window.localStorage.setItem(SELECTED_COUNTRY, country)
        if (city) {
            window.localStorage.setItem(SELECTED_CITY, city)
        } else {
            window.localStorage.removeItem(SELECTED_CITY)
        }
    }
}

export function* uiHeartbeat() {
    const PERIOD = 60 * 1000
    let nextSleep
    while (true) {
        nextSleep = PERIOD - Date.now() % PERIOD
        // console.log('NEXT sleep', nextSleep)
        yield delay(nextSleep)
        yield put(refreshAll())
    }
}