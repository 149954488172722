import React from 'react'
import cx from 'classnames'

export const BodyContainer = ({ className = '', children, wide, narrow }) => {
    const style = {
        padding: 10
    }
    let classes = cx({
        'wide-container': !!wide,
        'narrow-container': !!narrow,
        'container': !wide,
    })
    if (className) {
        classes = `${classes} ${className}`
    }
    return <div style={style} className={classes}>
        {children}
    </div>
}