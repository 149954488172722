import moment from 'moment'
import isEqual from 'lodash.isequal'
import {
    put,
    take,
    fork,
    select,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/database'
import {
    bookingsUpdated,
    bookingsLoadInProgress,
    LOAD_BOOKINGS_FOR_RANGE,
} from '../reducers'
import {
    dbPath
} from 'shared/firebase'
import {
    generateBookingFromRecurringRule,
} from 'shared/bookings'
import { STATUS } from 'shared/types'
import {
    extractDate,
    ObjectValues,
} from '../utils'
import {
    // Booking
} from '../models/booking'



const dateFrom = offsetDays => moment().add(offsetDays, 'd').format('YYYY-MM-DD')

let listenersByItemId = {}
let bookingsByItemId = {}

const clearAll = () => {
    ObjectValues(listenersByItemId).forEach(unsubscribe => unsubscribe())

    listenersByItemId = {}
    bookingsByItemId = {}
}

const isInProgress = () => {
    for (let itemId in bookingsByItemId) {
        let itemBookings = bookingsByItemId[itemId]
        if (!itemBookings) return true
    }
}
let itemToSharedItems = {}

let accumulateAndNotify;

function* firebaseLoader() {
    let channel = eventChannel(
        emit => {
            accumulateAndNotify = (siteId, itemId, data) => {

                // it's handled in the loop below
                emit({
                    siteId, itemId,
                    data,
                })
            }
            return clearAll
        }
    )

    while (true) {
        let event = yield take(channel)
        let { itemId, data } = event
        bookingsByItemId = {
            ...bookingsByItemId,
            [itemId]: data
        }
        const allLoaded = !isInProgress()
        if (allLoaded) yield put(bookingsUpdated(bookingsByItemId));
    }
}

// const idPrefix = /^[^\d]+/
// const cutPrefix = val => val.replace(idPrefix, '')

const createFirebaseListener = (siteId, itemId, fromDate, toDate) => {
    // console.log('subscribe to site', siteId, 'item', itemId, date)

    let itemIdToUse = itemToSharedItems[itemId] || itemId
    let bookingsRef = firebase.database().ref(dbPath.itemBookings(siteId, itemIdToUse))
        .orderByChild('fromDate')
        .startAt(fromDate)
        .endAt(toDate)

    let recurringRules, regular, overrideRecurringForDate = {};
    const notify = () => {
        if (!recurringRules || !regular) return;
        let bookings = [...regular]

        for (let key in recurringRules) {
            let ruleInfo = recurringRules[key]
            // let recurringId = ruleInfo.id // key.slice(1)

            let recBookings = generateBookingFromRecurringRule(ruleInfo, fromDate, toDate, siteId, itemIdToUse)

            for (let booking of recBookings) {
                let date = extractDate(booking.fromDate)
                if (!overrideRecurringForDate[`${booking.recurringId}-${date}`])
                    bookings.push(booking)
            }
        }

        // let timetable = {}
        // bookings.forEach(booking => {
        //     let quantity = (booking.quantity || 1) * (booking.originalItemMultiplier || 1)
        //     let datetime = moment(booking.fromDate)
        //     let hour = formatTime(datetime)
        //     let date = formatDate(datetime)
        //     timetable[date] = timetable[date] || {}
        //     timetable[date][hour] = timetable[date][hour] || []
        //     for (let i = 0; i < quantity; ++i) {
        //         timetable[date][hour].push(booking.id)
        //     }
        // })
        // // console.log('#######', bookings, timetable)
        // accumulateAndNotify(siteId, itemId, timetable)

        accumulateAndNotify(siteId, itemId, bookings)
    }

    let recurringRef = firebase.database().ref(dbPath.itemRecurringBookings(siteId, itemIdToUse))
        .orderByChild('validUntil')
        .startAt(fromDate)

    recurringRef.on('value', snapshot => {
        recurringRules = snapshot.val() || {}
        // console.log('recurring bookings rules', { siteId, itemId, fromDate, toDate }, recurringRules)
        let momentToDate = moment(toDate)
        for (let key in recurringRules) {
            let ruleInfo = recurringRules[key]
            if (momentToDate.isBefore(moment(ruleInfo.validFrom))) delete recurringRules[key]
        }
        notify()
    })

    bookingsRef.on('value', snapshot => {
        // console.log('bookings for ', siteId, itemIdToUse, 'dates range', fromDate, toDate, snapshot.val())
        let snap = snapshot.val() || {}

        let data = []
        for (let key in snap) {
            let booking = snap[key]
            if (booking.recurringId) {
                let date = extractDate(booking.fromDate)
                overrideRecurringForDate[`${booking.recurringId}-${date}`] = booking
            }
            if (booking.status === STATUS.CANCELED || booking.status === STATUS.REJECTED) {
                continue
            }
            data.push(booking)
        }
        regular = data.filter(it => it)
        notify()
    })



    return () => {
        // console.log('unsubscribed', siteId, 'item', itemId, date)
        bookingsRef.off()
        recurringRef.off()
    }
}

function* refreshSharedItemsInfo(siteId) {
    // extract shared items ids if there's any
    let site = yield select(state => state.sites.find(site => site.id === siteId))
    site.items.forEach(item => {
        if (item.sharedItemId) itemToSharedItems[item.id] = item.sharedItemId
    })
}

export function* bookingsLoader() {
    yield fork(firebaseLoader)

    let previous;

    while (true) {
        let action = yield take(LOAD_BOOKINGS_FOR_RANGE)
        let { siteId, itemIds, daysOffsetFrom, daysOffsetTo } = action

        if (!previous && daysOffsetTo < 4) { // load at least 4 days initially
            daysOffsetTo = 4
        }

        if (previous &&
            siteId === previous.siteId &&
            isEqual(itemIds, previous.itemIds) &&
            daysOffsetFrom >= previous.daysOffsetFrom &&
            daysOffsetTo <= previous.daysOffsetTo
        ) continue;

        yield put(bookingsLoadInProgress())
        clearAll()
        yield refreshSharedItemsInfo(siteId)

        let fromDate = dateFrom(daysOffsetFrom)
        let toDate = dateFrom(daysOffsetTo)

        for (let itemId of itemIds) {
            bookingsByItemId[itemId] = null
            listenersByItemId[itemId] = createFirebaseListener(siteId, itemId, fromDate, toDate)
        }
        previous = { siteId, itemIds, daysOffsetFrom, daysOffsetTo }
    }
}
