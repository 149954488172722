import React, { Component } from 'react'
import Collapsible from 'react-materialize/lib/Collapsible'
import Button from 'react-materialize/lib/Button'
import { Trans } from 'react-i18next';

export default class CollapsibleWithLoadMore extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: props.initialCount || 3
        }
    }
    render() {
        const { step = 5 } = this.props
        let children = this.props.children || []
        let { count } = this.state
        const showMore = () => this.setState({ count: count + step })
        // console.log('!!children', children)
        return <div>
            <Collapsible accordion>
                {children.slice(0, count)}
            </Collapsible>
            {count < children.length &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button flat onClick={showMore}><Trans>Show more</Trans>...</Button>
                </div>
            }
        </div>
    }
}