import {
    ajax_post, ajax_put,
    ajax_delete,
    ajax_get,
} from '../utils'

// const HOST = 'http://bookinggood.net'
export let HOST = process.env.REACT_APP_API_HOST
console.log('API HOST', HOST)

export const setGlobalAPIHost = host => {
    HOST = host
    console.log('Set API HOST', HOST)
}

const _prepareItemData = itemData => {
    const { schedule_exceptions, events, ...data } = itemData
    let item = { ...data };
    ['config', 'schedule', 'supplementaryTo'].forEach(key => {
        if (typeof item[key] === 'object') item[key] = JSON.stringify(item[key])
    })
    return item
}

export const saveItem = async item => {
    return ajax_put(HOST + `/api/admin/item/${item.id}`, _prepareItemData(item))
}
export const createItem = async data => ajax_post(HOST + '/api/admin/item', _prepareItemData(data))
export const deleteItem = async itemId => ajax_delete(HOST + '/api/admin/item/' + itemId)
export const saveItemExceptions = async (itemId, data) => ajax_post(HOST + '/api/admin/item/' + itemId + '/exceptions', data)
export const addItemEvent = async (itemId, data) => ajax_post(HOST + '/api/admin/item/' + itemId + '/event', data)
export const updateItemEvent = async (itemId, eventId, data) => ajax_put(HOST + '/api/admin/item/' + itemId + '/event/' + eventId, data)

const _prepareSiteData = siteData => {
    let { name, description, tags, country, city, location, timezone, hidden, ...details } = siteData
    let data = {
        name, description, tags, country, city, location, timezone,
    }
    if (hidden !== undefined) {
        data.active = !hidden
    }
    data.details = JSON.stringify(details)
    if (data.location) data.location = JSON.stringify(data.location)
    if (data.tags) data.tags = JSON.stringify(data.tags)
    return data
}
export const saveSite = async (siteId, siteData) => {
    let data = _prepareSiteData(siteData)
    return ajax_put(HOST + `/api/admin/site/${siteId}`, data)
}
export const createSite = async (data, promoCode) => ajax_post(HOST + '/api/register/owner', { site: _prepareSiteData(data), promoCode })
export const adminGetUsers = async siteId => ajax_get(HOST + `/api/admin/users/${siteId}`)
export const adminAddUser = async (siteId, user) => ajax_post(HOST + `/api/admin/users/${siteId}`, user)
export const adminEditUser = async (siteId, userId, user) => ajax_put(HOST + `/api/admin/users/${siteId}/${userId}`, user)
export const adminUserAcceptedInvitation = async (code) => ajax_post(HOST + `/api/user/invitation_accepted`, { code })
export const adminDeleteUser = async (siteId, userId) => ajax_delete(HOST + `/api/admin/users/${siteId}/${userId}`)

export const adminRedirectToken = async () => ajax_get(HOST + `/api/redirect-to-admin`)

export const adminLoadCalendarStatus = async (siteId) => ajax_get(HOST + `/api/admin/calendar/${siteId}/status`)
export const adminConnectCalendar = async (siteId, redirectUri) => ajax_post(HOST + `/api/admin/calendar/${siteId}/connect`, { redirectUri })
export const adminCreateCalendar = async (siteId, name) => ajax_post(HOST + `/api/admin/calendar/${siteId}/create`, { name })
export const adminLoadCalendars = async (siteId) => ajax_post(HOST + `/api/admin/calendar/${siteId}/calendars`)
export const adminDeleteCalendarCredentials = async siteId => ajax_post(HOST + `/api/admin/calendar/${siteId}/delete_credentials`, {})
export const adminLoadEvents = async (siteId, calendarIds, timeMin) => ajax_post(HOST + `/api/admin/calendar/${siteId}/events`, { calendarIds, timeMin })
export const adminImportCalendarPostRedirect = async query => ajax_post(HOST + `/api/admin/calendar/import/post_redirect${query}`)

export const adminStartSyncToCalendar = async (siteId, itemId, syncCalendarId) => ajax_post(HOST + `/api/admin/calendar/${siteId}/start_sync/${itemId}/${syncCalendarId}`)
export const adminStopSyncForItem = async (siteId, itemId) => ajax_post(HOST + `/api/admin/calendar/${siteId}/stop_sync/${itemId}`)

export const adminCreateBooking = async booking => ajax_post(HOST + '/api/admin/booking', booking)
export const adminChangeBooking = async booking => ajax_put(HOST + '/api/admin/booking/' + booking.id, booking)
export const adminCancelBooking = async bookingId => ajax_delete(HOST + '/api/admin/booking/' + bookingId)
export const adminAcceptBooking = async bookingId => ajax_post(HOST + '/api/admin/booking/' + bookingId + '/accept')
export const adminRejectBooking = async bookingId => ajax_post(HOST + '/api/admin/booking/' + bookingId + '/reject')
export const adminChangeStatus = async (bookingId, status) => ajax_post(HOST + '/api/admin/booking/' + bookingId + '/status', { status })

export const adminCheckCapacityForRecurring = async (ruleInfo, ignoreRecurringId) => ajax_post(HOST + `/api/admin/booking/recurring/check_possibility/${ignoreRecurringId || ''}`, ruleInfo)
export const adminCreateRecurringBooking = async ruleInfo => ajax_post(HOST + '/api/admin/booking/recurring', ruleInfo)
export const adminCancelRecurringOccurrence = async (siteId, recurringId, date) => ajax_post(HOST + `/api/admin/booking/recurring/cancel_occasion/${siteId}/${recurringId}/${date}`)
export const adminModifyRecurringOccurrence = async (siteId, recurringId, date, data) => ajax_post(HOST + `/api/admin/booking/recurring/modify_occasion/${siteId}/${recurringId}/${date}`, data)
export const adminModifyRecurringBooking = async (siteId, recurringId, followingDate, data) => ajax_post(HOST + `/api/admin/booking/recurring/modify/${siteId}/${recurringId}/${followingDate}`, data)
export const adminCancelRecurringAllOccurrences = async (siteId, recurringId, date) => ajax_post(HOST + `/api/admin/booking/recurring/cancel_all_occasions/${siteId}/${recurringId}/${date}`)

export const adminCreateSiteUser = async (siteId, data) => ajax_post(HOST + `/api/admin/site/${siteId}/users`, data)
export const adminSaveSiteUser = async (siteId, userId, data) => ajax_put(HOST + `/api/admin/site/${siteId}/users/${userId}`, data)
export const adminDeleteSiteUser = async (siteId, userId) => ajax_delete(HOST + `/api/admin/site/${siteId}/users/${userId}`)

export const adminDeleteSite = async (siteId) => ajax_post(HOST + `/api/admin/site/${siteId}/delete`)

export const adminBulkImport = async (siteId, itemId, data) => ajax_post(HOST + '/api/admin/booking/bulk_import', { siteId, itemId, data })
export const adminBulkDelete = async (siteId, itemId, data) => ajax_post(HOST + '/api/admin/booking/bulk_delete', { siteId, itemId, data })
export const adminGetBulkImportResults = async (siteId, itemId) => ajax_get(HOST + `/api/admin/booking/bulk_import_results/${siteId}/${itemId}`)
export const adminCancelImport = async (siteId, itemId) => ajax_get(HOST + `/api/admin/booking/bulk_import_cancel/${siteId}/${itemId}`)

export const adminGetSiteStats = async (siteId, fromDate, toDate) => ajax_get(HOST + `/api/admin/site/${siteId}/stats/from/${fromDate}/to/${toDate}`)

export const getClientToken = () => ajax_get(HOST + '/api/admin/payment/client_token')
export const getSubscriptionInfo = siteId => ajax_get(HOST + `/api/admin/payment/status/${siteId}`)
export const createSubscription = (payment_method_nonce, siteId, planId) => ajax_post(HOST + '/api/admin/payment/create_subscription', { payment_method_nonce, siteId, planId })
// export const activateFreeTrial = async (siteId, promoCode) => await ajax_post(HOST + '/api/admin/payment/free_trial', { siteId, promoCode })
export const cancelSubscription = async (siteId) => await ajax_post(HOST + '/api/admin/payment/cancel_subscription', { siteId })


export const syncUserBookingsForSite = async siteId => ajax_post(HOST + '/api/booking/sync_upcoming_bookings/' + siteId, {})
export const createBooking = async booking => ajax_post(HOST + '/api/booking', booking)
export const cancelBooking = async bookingId => ajax_delete(HOST + '/api/booking/' + bookingId)
export const cancelRecurringOccurrence = async (siteId, recurringId, date) => ajax_post(HOST + `/api/booking/recurring/cancel_occasion/${siteId}/${recurringId}/${date}`)

export const storeNotificationsToken = async (token, old_token) => ajax_post(HOST + '/api/notifications/token', { token, old_token })
export const storeNotificationsPreferences = async (token, preferences) => ajax_post(HOST + '/api/notifications/token', { token, preferences })

export const watchResource = async (fromDate, toDate, items) => ajax_post(HOST + '/api/watch/bulk', { fromDate, toDate, items })
export const stopWatchResource = async (groupId) => ajax_delete(HOST + '/api/watch/' + encodeURIComponent(groupId))

export const postConsent = async data => ajax_post(HOST + '/api/user/consent', data)
export const deleteAccount = async () => ajax_post(HOST + '/api/user/delete_account')


let countryNames = require('../utils/country_names.json')

const countryToCurrency = country => {
    switch (country) {
        case 'BG': return 'BGN'
        case 'US': return 'USD'
        default: return 'EUR'
    }
}

export const fetchIpInfo = async () => {
    let resp = await fetch('https://ipinfo.io/geo?token=f281079ec6ca21')
    let info = await resp.json()
    console.log('received ip info', info, typeof info)

    let { country, city } = info
    let countryFullName = countryNames[country] || country
    let currency = countryToCurrency(country)

    return { country: countryFullName, city, currency }
}

export const fetchCoordinatesByAddress = async (address, googleApiKey) => {
    let resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleApiKey}`)
    let info = await resp.json()
    // console.log('resp', info)
    let result = info.results[0]
    if (!result) return {}
    return result.geometry.location
}
