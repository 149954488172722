import React from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { i18n } from 'shared/i18n'
import CollapsibleItem from 'react-materialize/lib/CollapsibleItem'
import {
    createSelector
} from 'reselect'
import {
    BookingStatus,
    CancelButtonWithConfirmation,
    ToastNotification,
} from './common'
import CollapsibleWithLoadMore from './CollapsibleWithLoadMore'
import {
    cancelBooking,
    cancelRecurringOccurrence,
} from '../api'
import {
    formatDatetime,
    extractDate,
    getTimeNowForSite,
    convertToTimezone,
} from '../utils'
import './MyBookings.css'
import {
    SitePhone,
    SiteEmail,
} from './SiteElements';


const byFromDate = (l, r) => {
    if (l.fromDate === r.fromDate) return 0
    return l.fromDate < r.fromDate ? -1 : 1
}

const getUpcoming = createSelector(
    [
        state => state.sites,
        state => state.user_profile.bookings,
        state => state.ui.heartbeat
    ],
    (sites, bookings, uiHeartbeat) => {
        let dateNow = formatDatetime(new Date())// (new Date()).toISOString()
        let upcoming = bookings.filter(bb => bb.status !== 'CANCELED' && bb.toDate > dateNow).sort(byFromDate)
        return upcoming
    }
)

export const onCancelBooking = async (bookingId, recurringId, occurrenceDatetime, siteId) => {
    try {
        if (!recurringId) {
            await cancelBooking(bookingId)
        } else {
            await cancelRecurringOccurrence(siteId, recurringId, extractDate(occurrenceDatetime))
        }
        ToastNotification.success('Booking canceled')
    } catch (err) {
        ToastNotification.error('Could not cancel')
        return true
    }
}

const bookingInfo = (booking, site) => {
    const hour = dd => dd.format('HH:mm')
    const date = dd => dd.format('dddd, D.MM')
    const fromDate = moment(booking.fromDate)
    const toDate = moment(booking.toDate)
    const item = site.items.find(it => it.id === booking.itemId) || {}
    let supplDetails = ''
    if (booking.supplItemId) {
        let supplItem = site.supplementaryItems.find(it => it.id === booking.supplItemId) || {}
        supplDetails = <span> <Trans>with</Trans> {supplItem.name}</span>
    }
    let hoursDetails = ''
    if (booking.quantity > 1) {
        hoursDetails = <span>, <Trans>slots</Trans>: {booking.quantity}</span>
    }
    return <div>
        <div>
            {`${hour(fromDate)} - ${hour(toDate)}`}{hoursDetails}
        </div>
        <div style={{ textTransform: 'capitalize' }}>
            {date(fromDate)}
        </div>
        <div style={{ marginTop: 10 }}>
            {site.name}, {<Trans>{item.name}</Trans>} {supplDetails}
        </div>
        <BookingStatus status={booking.status} />
    </div>
}

const hasPassed = (datetime, site, threshold_minutes) => {
    const timeNow = getTimeNowForSite(site)
    const startDatetime = moment(datetime).toDate()
    const thresholdDatetime = moment(convertToTimezone(startDatetime, site.timezoneOffset)).subtract(threshold_minutes, 'minutes')
    return moment(timeNow).isAfter(thresholdDatetime)
}

const needsWarning = (booking, site, item) => {
    if (!item.config) return false
    const { late_cancellation_warning, late_cancellation_warning_if_less_than } = item.config
    if (!late_cancellation_warning || late_cancellation_warning_if_less_than <= 0) return false

    return hasPassed(booking.fromDate, site, late_cancellation_warning_if_less_than)
}
const tooLateToCancel = (booking, site, item) => {
    if (!item.config) return false
    const { late_cancellation_forbid, late_cancellation_forbid_if_less_than } = item.config
    if (!late_cancellation_forbid || late_cancellation_forbid_if_less_than <= 0) return false

    return hasPassed(booking.fromDate, site, late_cancellation_forbid_if_less_than)
}

export const MyBookingsCollapsible = ({ bookings, sites, embed, onCancelBooking }) => {
    // console.log('my bookings', bookings, sites)
    const findSiteById = siteId => sites.find(ss => ss.id === siteId)
    if (!bookings || !sites || !bookings.length || !sites.length) return <div />
    return <div className="my-bookings">
        {!embed && <h1><Trans>My Bookings</Trans></h1>}
        <CollapsibleWithLoadMore>
            {bookings.map((booking, index) => {
                let site = findSiteById(booking.siteId)
                if (!site) return ''
                let item = site.items.find(it => it.id === booking.itemId)
                if (!item) return ''
                let supplItem
                if (booking.supplItemId) {
                    supplItem = site.supplementaryItems.find(it => it.id === booking.supplItemId)
                }
                return <CollapsibleItem key={`${booking.id}-${index}`}
                    icon={!booking.recurringId ? 'query_builder' : 'repeat'}
                    header={<div className="booking-info">
                        {bookingInfo(booking, site)}
                    </div>}
                >
                    {(tooLateToCancel(booking, site, item) || (supplItem && tooLateToCancel(booking, site, supplItem)))
                        ? <div className="too-late-to-cancel">
                            <div className="description"><Trans>Contact the place for more details</Trans></div>
                            <div><SitePhone withIcon site={site} /></div>
                            <div><SiteEmail withIcon site={site} /></div>
                        </div>
                        : <div style={{ textAlign: 'right' }}>
                            <CancelButtonWithConfirmation onConfirm={async () => {
                                if (needsWarning(booking, site, item) || (supplItem && needsWarning(booking, site, supplItem))) {
                                    if (!window.confirm(i18n().t('We respect your need to cancel the booking but note that if you cancel so late too often your access to the platform will be limited! Are you sure you want to proceed?'))) {
                                        return true
                                    }
                                }
                                return await onCancelBooking(booking.id, booking.recurringId, booking.fromDate, booking.siteId)
                            }} />
                        </div>
                    }
                </CollapsibleItem>
            })}
        </CollapsibleWithLoadMore>
    </div>
}

export default connect(state => {
    return {
        sites: state.sites,
        bookings: getUpcoming(state),
        language: state.ui.language, // to force update if language changes
    }
}, dispatch => {
    return {
        onCancelBooking,
    }
})(MyBookingsCollapsible)