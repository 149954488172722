import React from 'react'
import Button from 'react-materialize/lib/Button'

export const ButtonWithDoubleClickProtection = props => {
  const { onClick, disabled, ...otherProps } = props;
  const [pending, setPending] = React.useState(false);

  const _onClick = (e) => {
    setPending(true);
    
    setTimeout(() => onClick(e), 50);
    
    setTimeout(() => setPending(false), 3000);
  }
  return <Button {...otherProps} disabled={pending || disabled} onClick={_onClick}/>
}