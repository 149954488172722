export class User {
    id = null
    uid = null

    displayName = ''
    phoneNumber = ''
    photoURL = ''
    email = ''

    autogenerated = false

    specialPermissionsInSites = null

    hasPassword = false
}

export const instantiateUser = (uid, displayName, phoneNumber, photoURL, email) => {
    let user = new User()
    user.uid = uid
    user.displayName = displayName
    user.phoneNumber = phoneNumber
    user.photoURL = photoURL
    user.email = email
    return user
}