import React from 'react';
// import { useTranslation } from 'react-i18next'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import Dropdown from 'react-materialize/lib/Dropdown'
import {
    changeLanguage,
} from '../../reducers'
import {
    lang2country,
    stripLangFromUrlPath,
} from 'shared/utils'
import './LangSwitch.css'

const flag = country => <img alt={country} src={`/static/assets/img/flags/${country}.png`} />


const linkToLang = lang => {
    let prefix = (lang !== 'en' ? `/${lang}` : '')
    let path = (typeof window !== 'undefined') ? stripLangFromUrlPath(window.location.pathname) : '/'
    let url = prefix + path
    // if (lang === 'en') {
    //     url += '?lang=en'
    // }
    return url
}

const __LangSwitch = withNamespaces()(({ i18n, language, dropdown, onChange }) => {
    // const { t, i18n } = useTranslation()
    const changeLanguage = lang => e => {
        e.preventDefault();
        i18n.changeLanguage(lang)
        onChange(lang)
    }
    const navlink = (lang, country) =>
        <li key={lang}>
            <a
                href={linkToLang(lang)}
                onClick={changeLanguage(lang)}
            >
                <div className="ls-option">
                    {flag(country)} {country}
                </div>
            </a>
        </li>

    if (dropdown) {
        return <span className="ls-dropdown">
            <Dropdown

                trigger={
                    <button className="ls-button link-button nav-button" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}>
                        {flag(lang2country(language))}
                    </button>
                }
                options={{
                    belowOrigin: true
                }}
            >
                {navlink('en', 'US')}
                {navlink('bg', 'BG')}
                {navlink('ru', 'RU')}
            </Dropdown>
        </span>
    }

    const link = (lang, country) => <a
        key={lang}
        href={linkToLang(lang)}
        onClick={changeLanguage(lang)}
        className="ls-option"
    >
        {flag(country)}
    </a>
    return <div className="ls-panel">
        {link('en', 'US')}
        {link('bg', 'BG')}
        {link('ru', 'RU')}
    </div>
})

export const LangSwitch = connect(
    (state, props) => {
        let language = state.ui.language
        return {
            language,
            ...props,
        }
    },
    dispatch => {
        return {
            onChange: lang => dispatch(changeLanguage(lang))
        }
    }
)(__LangSwitch)