import {
    put,
    take,
    fork, cancel, cancelled,
    select,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/database'
import {
    USER_UPDATED,
    sitesUpdated, SITES_UPDATED,
    changeLocation, CHANGE_LOCATION,
} from '../reducers'
import {
    dbPath
} from 'shared/firebase'
import {
    Site,
    Item,
} from '../models'
// import mtz from 'moment-timezone'
import {
    getTimeNowForSite, formatDate,
    sortBy,
} from '../utils'
let mtz

export const objectToSitesList = (data, filterSiteIds) => {
    let sites = []

    for (let siteId in data) {
        if (filterSiteIds && filterSiteIds.indexOf(siteId) === -1)
            continue
        sites.push(toSite(siteId.toString(), data[siteId]))
    }
    return sites
}

const copyDefinedProps = (toObj, fromObj) => {
    for (let prop in fromObj) {
        if (fromObj[prop] !== undefined) toObj[prop] = fromObj[prop];
    }
}

export const toSite = (id, data) => {
    let { name, address, website, description, tags, full_description, country, city, location, timezone, currency, phone, email, logoURL, gallery, hosting, hidden, items } = data
    let sharedItems = data['shared-items']
    let supplementaryItems = data['supplementary-items'] || []
    let site = new Site(id, name)

    let time = mtz()
    if (timezone) time.tz(timezone)
    let timezoneOffset = time.utcOffset()
    let timezoneOffsetSuffix = time.format('Z')

    copyDefinedProps(site, { address, website, description, tags, full_description, country, city, location, timezone, timezoneOffset, timezoneOffsetSuffix, currency, phone, email, logoURL, gallery, hosting, hidden })

    if (site.location) site.location = JSON.parse(site.location)
    if (!site.tags) site.tags = []

    site.items = []
    for (let itemId in items) {
        site.items.push(toItem(itemId, id, items[itemId]))
    }

    if (sharedItems) {
        site.sharedItems = []
        for (let itemId in sharedItems) {
            site.sharedItems.push(toItem(itemId, id, sharedItems[itemId]))
        }
    }

    if (supplementaryItems) {
        site.supplementaryItems = []
        for (let itemId in supplementaryItems) {
            site.supplementaryItems.push(toItem(itemId, id, supplementaryItems[itemId]))
        }
    }
    return site
}
export const toItem = (id, siteId, data) => {
    const { name, capacity, schedule, schedule_exceptions, events, config, sharedItemId, sharedItemMultiplier } = data
    let item = new Item(id, name)
    item.capacity = capacity !== undefined ? capacity : 1
    if (schedule) item.schedule = JSON.parse(schedule);
    if (config) item.config = JSON.parse(config);
    if (data.supplementaryTo) {
        if (Array.isArray(data.supplementaryTo)) {
            item.supplementaryTo = data.supplementaryTo
        } else {
            //temp
            let ss = data.supplementaryTo.replace(/["\\]/g, '')
            item.supplementaryTo = (JSON.parse(ss)).map(id => id.toString())
        }
    }
    if (sharedItemId) {
        item.sharedItemId = sharedItemId.toString()
        item.sharedItemMultiplier = sharedItemMultiplier || 1
    }
    if (schedule_exceptions) {
        item.schedule_exceptions = Object.keys(schedule_exceptions)
            .map(key => schedule_exceptions[key])
    }
    if (events) {
        item.events = Object.keys(events)
            .map(key => events[key])
    }
    return item
}

const normalizeOutdatedScheduleExceptions = (item, date) => {
    if (!item.schedule_exceptions) return item
    item.schedule_exceptions = item.schedule_exceptions
        .filter(ex => ex.to_date >= date)
        .sort(sortBy('from_date'))
    for (let ex of item.schedule_exceptions) {
        if (typeof ex.schedule === 'string') {
            ex.schedule = JSON.parse(ex.schedule)
        }
    }
}

export const loadMtz = async () => {
    try {
        const m = await import('moment-timezone')
        mtz = m.default
    } catch(err) {
        console.log('Error loading moment-timezone', err)
        throw err
    }
}

export const __sitesLoader = (siteId, country, showHiddenItems, fb = firebase) => emit => {
    let filterSiteIds
    let ref
    if (siteId) {
        if (Array.isArray(siteId)) {
            ref = fb.database().ref(dbPath.sites())
            filterSiteIds = siteId
        } else {
            ref = fb.database().ref(dbPath.sites()).orderByKey().equalTo(siteId)
        }
    } else if (country) {
        ref = fb.database().ref(dbPath.sites()).orderByChild('country').equalTo(country)
    } else {
        ref = fb.database().ref(dbPath.sites())
    }

    loadMtz()
        .then(() => {
            ref
                .on('value', snapshot => {
                    if (!snapshot) return

                    let data = snapshot.val()
                    // console.log('sites:', data)

                    let sites = objectToSitesList(data, filterSiteIds)
                        .filter(site => site.country && site.city && site.name)
                    // .filter(site => !site.hidden)

                    sites.forEach(site => {
                        if (!site.logoURL) site.logoURL = '/static/assets/img/demo_logo.jpg'

                        if (!showHiddenItems) {
                            site.items = site.items.filter(it => !it.config || !it.config.hidden_from_clients)
                            if (site.supplementaryItems) {
                                site.supplementaryItems = site.supplementaryItems.filter(it => !it.config || !it.config.hidden_from_clients)
                            }
                        }

                        let date = formatDate(getTimeNowForSite(site))

                        site.items.forEach(it => normalizeOutdatedScheduleExceptions(it, date))
                        site.supplementaryItems && site.supplementaryItems.forEach(it => normalizeOutdatedScheduleExceptions(it, date))
                        site.sharedItems && site.sharedItems.forEach(it => normalizeOutdatedScheduleExceptions(it, date))
                    })
                    emit(sites)
                })
        })
        .catch(err => {
            alert('There was a critical error loading the site. Please try to refresh the page')
        })

    return () => ref.off()
}

export function createSiteLoadingChannel(siteId, country, showHiddenItems) {
    console.log('createSiteLoadingChannel', { siteId, country, showHiddenItems })
    const listener = eventChannel(
        __sitesLoader(siteId, country, showHiddenItems)
    )
    return listener
}

const showHiddenSites = (typeof window !== 'undefined' ? (window.location.search.indexOf('preview') !== -1) : false)

function* _sitesLoader(siteId, country, showHiddenItems) {
    let channel = createSiteLoadingChannel(siteId, country, showHiddenItems)
    try {
        while (true) {
            let sites = yield take(channel)

            if (!siteId && !showHiddenSites) {
                sites = sites.filter(site => !site.hidden)
            }

            yield (put(sitesUpdated(sites)))
        }
    } finally {
        if (yield cancelled()) {
            yield channel.close()
        }
    }
}

const filterSitesByCountry = (sites, country) => sites.filter(site => site.country === country)

function* autosetCountryCityWithMostClubs() {
    let country, city
    while (true) {
        let action = yield take([CHANGE_LOCATION, SITES_UPDATED])

        if (action.type === CHANGE_LOCATION) {
            country = action.country
            city = action.city
        } else {
            let { sites } = action
            if (city || !country || !sites || !sites.length) continue

            sites = filterSitesByCountry(sites, country)

            let clubsCountByCity = {}
            for (let site of sites) {
                let count = clubsCountByCity[site.city] || 0
                clubsCountByCity[site.city] = count + 1
            }

            let maxCount = -1
            for (let cc in clubsCountByCity) {
                let count = clubsCountByCity[cc]
                if (count > maxCount) {
                    city = cc
                    maxCount = count
                }
            }

            if (city) {
                yield put(changeLocation(country, city))
            }
        }
    }
}

export function* sitesLoader() {
    yield fork(autosetCountryCityWithMostClubs)

    const stateUi = yield select(state => state.ui)
    const applySiteId = stateUi.applySiteId
    let selectedCountry = stateUi.country
    let adminSiteId
    console.log('sites loader initial', { selectedCountry, applySiteId })

    if (applySiteId) {
        yield fork(_sitesLoader, applySiteId)
        return
        // in this case we don't care about user or location
    }

    let loader

    while (true) {
        let action = yield take([USER_UPDATED, CHANGE_LOCATION])
        let { user, country } = action
        // console.log('sites loader action:', action)

        switch (action.type) {
            case USER_UPDATED: {

                let siteId
                if (user && user.specialPermissionsInSites) {
                    const perms = Object.keys(user.specialPermissionsInSites)
                    siteId = (perms.length === 1 ? perms[0] : perms)
                }

                if (loader && adminSiteId === siteId) continue
                adminSiteId = siteId
                break
            }
            case CHANGE_LOCATION: {
                if (loader && selectedCountry === country) continue
                selectedCountry = country
                break
            }
            default:
                break
        }

        if (adminSiteId || selectedCountry) {
            if (loader) yield cancel(loader)

            // console.log('sites loader fork', { adminSiteId, selectedCountry })
            // TEMP load sites for all countries
            // it's to assure that any site accessed by link will be loadable
            // in future we need to take info from the link and load site info if it's missing
            loader = yield fork(_sitesLoader, adminSiteId, /*selectedCountry*/null, !!adminSiteId)
        }
    }
}

export function* sitesLoaderClient() {
    yield fork(autosetCountryCityWithMostClubs)

    const stateUi = yield select(state => state.ui)
    let selectedCountry = stateUi.country
    console.log('client sites loader initial', { selectedCountry })

    let loader = yield fork(_sitesLoader, null, selectedCountry)

    while (true) {
        let action = yield take([CHANGE_LOCATION])
        let { country } = action
        // console.log('sites loader action:', action)

        if (loader && selectedCountry === country) continue
        selectedCountry = country

        if (loader) yield cancel(loader)

        loader = yield fork(_sitesLoader, null, selectedCountry)
    }
}
