module.exports.STATUS = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    CANCELED: 'CANCELED',
    MISSED: 'MISSED',
}

module.exports.CONFIG = {
    require_confirmation: 'require_confirmation',
    confirmation_if_less_than: 'confirmation_if_less_than',
    notification_pending_confirmation: 'notification_pending_confirmation',
    notification_canceled_booking: 'notification_canceled_booking',
    notification_canceled_booking_if_less_than: 'notification_canceled_booking_if_less_than',
    notification_new_booking: 'notification_new_booking',
    notification_new_booking_if_less_than: 'notification_new_booking_if_less_than',
}

module.exports.LIMITS = {
    level0: 1,
    level1: 10,
    level3: 0xEFFFFFFF,
}

module.exports.activityType = {
    USER_NEW_BOOKING: 'USER_NEW_BOOKING',
    USER_CANCEL_BOOKING: 'USER_CANCEL_BOOKING',
    USER_CANCEL_OCCASION: 'USER_CANCEL_OCCASION',
    ADMIN_NEW_BOOKING: 'ADMIN_NEW_BOOKING',
    ADMIN_UPDATE_BOOKING: 'ADMIN_UPDATE_BOOKING',
    ADMIN_CANCEL_BOOKING: 'ADMIN_CANCEL_BOOKING',
    ADMIN_NEW_RECURRENCE: 'ADMIN_NEW_RECURRENCE',
    ADMIN_CANCEL_OCCASION: 'ADMIN_CANCEL_OCCASION',
    ADMIN_MODIFY_OCCASION: 'ADMIN_MODIFY_OCCASION',
    ADMIN_CANCEL_ALL_OCCASIONS: 'ADMIN_CANCEL_ALL_OCCASIONS',
    ADMIN_MODIFY_RECURRENCE: 'ADMIN_MODIFY_RECURRENCE',
    ADMIN_CONFIRM_BOOKING: 'ADMIN_CONFIRM_BOOKING',
    ADMIN_REJECT_BOOKING: 'ADMIN_REJECT_BOOKING',
    ADMIN_CHANGE_BOOKING_STATUS: 'ADMIN_CHANGE_BOOKING_STATUS',
    ADMIN_BULK_IMPORT: 'ADMIN_BULK_IMPORT',
    ADMIN_BULK_IMPORT_FINISHED: 'ADMIN_BULK_IMPORT_FINISHED',
    ADMIN_BULK_IMPORT_CANCEL: 'ADMIN_BULK_IMPORT_CANCEL',
    ADMIN_BULK_DELETE: 'ADMIN_BULK_DELETE',
    ADMIN_ADD_SITE: 'ADMIN_ADD_SITE',
    ADMIN_CHANGE_SITE: 'ADMIN_CHANGE_SITE',
    ADMIN_DELETE_SITE: 'ADMIN_DELETE_SITE',
    ADMIN_ADD_ITEM: 'ADMIN_ADD_ITEM',
    ADMIN_CHANGE_ITEM: 'ADMIN_CHANGE_ITEM',
    ADMIN_REMOVE_ITEM: 'ADMIN_REMOVE_ITEM',
    ADMIN_ADD_CLIENT: 'ADMIN_ADD_CLIENT',
    ADMIN_CHANGE_CLIENT: 'ADMIN_CHANGE_CLIENT',
    ADMIN_REMOVE_CLIENT: 'ADMIN_REMOVE_CLIENT',
    ADMIN_CALENDAR_SYNC: 'ADMIN_CALENDAR_SYNC',
    ADMIN_CALENDAR_SYNC_STOP: 'ADMIN_CALENDAR_SYNC_STOP',
    ADMIN_CREATE_SUBSCRIPTION: 'ADMIN_CREATE_SUBSCRIPTION',
    ADMIN_CANCEL_SUBSCRIPTION: 'ADMIN_CANCEL_SUBSCRIPTION',
    ADMIN_CREATE_ITEM_EVENT: 'ADMIN_CREATE_ITEM_EVENT',
    ADMIN_CHANGE_ITEM_EVENT: 'ADMIN_CHANGE_ITEM_EVENT',
    SYSTEM_INITIAL_IMPORT_STARTED: 'SYSTEM_INITIAL_IMPORT_STARTED',
    SYSTEM_NEW_BOOKING: 'SYSTEM_NEW_BOOKING',
    SYSTEM_NEW_RECURRENCE: 'SYSTEM_NEW_RECURRENCE',
    SYSTEM_CANCEL_OCCASION: 'SYSTEM_CANCEL_OCCASION',
    SYSTEM_CANCEL_BOOKING: 'SYSTEM_CANCEL_BOOKING',
    SYSTEM_DELETE_RECURRENCE: 'SYSTEM_DELETE_RECURRENCE',
    SYSTEM_UPDATE_CLIENT: 'SYSTEM_UPDATE_CLIENT'
}