const NextI18Next = require('next-i18next/dist/commonjs')
// import NextI18Next from 'next-i18next'
// // import Backend from 'i18next-xhr-backend';
// // import LanguageDetector from 'i18next-browser-languagedetector';
// // import LocalStorageBackend from 'i18next-localstorage-backend';
// import Backend from 'i18next-chained-backend';
// import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
// import XHR from 'i18next-xhr-backend'; // fallback xhr load

// language is saved as cookie and sent to server, 

const NextI18NextInstance = new NextI18Next({
    localeSubpaths: 'foreign',

    browserLanguageDetection: true,
    serverLanguageDetection: true,
    // backend: {
    //     backends: [
    //         LocalStorageBackend,  // primary
    //         XHR                   // fallback
    //     ]
    // },
    use: [
        // Backend,
        // LanguageDetector,
        // LocalStorageBackend,
    ],
    defaultLanguage: 'en',
    otherLanguages: ['bg', 'ru'],
    fallbackLng: 'en',
    // otherLanguages: ['en', 'ru'],
    // defaultLanguage: 'bg',
    // otherLanguages: ['en'],
    // fallbackLng: 'bg',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',


    keySeparator: '#!@',

    // debug: true,
    // debug: false,

    load: 'languageOnly',
    // preload: ['en', 'bg'],

    // interpolation: {
    //     escapeValue: false, // not needed for react!!
    // },

    // react: {
    //     wait: true
    // }

})

if (typeof window !== 'undefined') {
    // NextI18NextInstance.i18n.use(LocalStorageBackend)
    // NextI18NextInstance.i18n.use(LanguageDetector)
}
module.exports = NextI18NextInstance
// export default NextI18NextInstance

// /* Optionally, export class methods as named exports */
// export const {
//     appWithTranslation,
//     withNamespaces,
//     i18n,
// } = NextI18NextInstance