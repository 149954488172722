import React from 'react'
import { Trans } from "react-i18next";
import cx from 'classnames'
import './SwitchInput.css'

export const SwitchInput = ({ active, disabled, onChange, label = '' }) => {
    return <div className={cx({
        'activate-switch': true,
        'disabled': disabled
    })}>
        <div className="switch">
            <label>
                <input type="checkbox"
                    disabled={!!disabled}
                    checked={active}
                    onChange={e => onChange(e.target.checked)}
                />
                <Trans>{label}</Trans>
                <span className="lever"></span>
            </label>
        </div>
    </div>
}