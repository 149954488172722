import { i18n } from 'shared/i18n'
const isClient = typeof window !== 'undefined'
let Materialize
if (isClient) {
    require('materialize-css/js/hammer.min.js')
    require('materialize-css/js/jquery.hammer.js')
    Materialize = require('materialize-css')
}

export class ToastNotification {
    static notificationWithoutTranslation(text) {
        Materialize.toast(text, 10 * 1000, 'blue-grey lighten-4 z-depth-5 grey-text text-darken-4')
    }
    static success(text) {
        Materialize.toast(i18n().t(text), 3000, 'teal accent-4')
    }
    static warning(text) {
        Materialize.toast(i18n().t(text), 3000, 'orange darken-2')
    }
    static error(text = "Operation has failed, try again later") {
        Materialize.toast(i18n().t(text), 6000, 'red darken-1')
    }
}