import {
    put,
    take,
    actionChannel,
    select,
    delay,
} from 'redux-saga/effects'
import {
    buffers,
} from 'redux-saga'
import {
    APPLY_SEARCH,
    applySitesFilter,
} from '../reducers'
import {
    createSelector
} from 'reselect'
import {
    nameToTag,
} from 'shared/sites'

const _siteTags = sites => {
    let obj = {}
    for (let ss of sites) {
        for (let it of ss.items) {
            obj[it.name] = true
        }
        if (ss.tags) {
            for (let tag of ss.tags) {
                obj[tag] = true
            }
        }
    }
    let tags = Object.keys(obj)
    if (!tags.length) tags = ['Tennis', 'Squash', 'Badminton']

    tags.sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a == b) return 0;
        if (a > b) return 1;
        return -1;
    })

    return tags
}

export const selectorCountrySites = createSelector(
    state => state.sites,
    state => state.ui.country,
    (sites, country) => {
        if (!country) return sites
        if (!sites) return []
        return sites.filter(site => site.country === country)
    }
)
const selectorCitySites = createSelector(
    state => state.sites,
    state => state.ui.country,
    state => state.ui.city,
    (sites, country, city) => {
        if (!sites) return []
        return sites.filter(site => site.country === country && site.city === city)
    }
)
export const selectorSitesWithItemInCity = createSelector(
    selectorCitySites,
    (_, itemName) => itemName,
    (citySites, itemName) => {
        let matching = []
        citySites.forEach(site => {
            let item = site.items.find(item => item.name === itemName)
            if (item) {
                matching.push([site, item])
            }
        })
        return matching
    }
)


export const selectorUniqueTags = createSelector(
    state => selectorCountrySites(state),
    sites => _siteTags(sites)
)
export const selectorCityUniqueTags = createSelector(
    state => selectorCountrySites(state),
    (_, city) => city,
    (sites, city) => _siteTags((sites||[]).filter(ss => !city || ss.city === city))
)
export const selectorUniqueCities = createSelector(
    state => selectorCountrySites(state),
    state => state.ui.city,
    (sites, city) => {
        let obj = {}
        if (city) {
            obj[city] = true
        }
        for (let ss of sites) {
            if (ss.city) obj[ss.city] = true
        }
        let cities = Object.keys(obj)
        return cities
    }
)

export const selectorFilterSitesCoaches = createSelector(
    state => selectorCountrySites(state),
    (state, tag) => tag,
    state => state.search.sitesFilter,
    state => state.ui.city,
    (allSites, tag, sitesFilter, city) => {
        if (!sitesFilter) sitesFilter = {}
        let { tags, cities, wildcard, coaches } = sitesFilter

        let reCity, reTags, reWildcard, reCoach;
        if (cities || city) {
            reCity = new RegExp(`^(${cities ? cities.join('|') : city})$`, 'i')
        }
        if (tag) {
            reTags = new RegExp(`^${tag}$`, 'i')
        } else if (tags) {
            reTags = new RegExp(`^(${tags.join('|')})$`, 'i')
        }
        if (wildcard) {
            reWildcard = new RegExp(`(${wildcard.join('|')})`, 'i')
        }
        if (coaches) {
            reCoach = new RegExp(`^(${coaches.join('|')})$`, 'i')
        }

        const sites = allSites
            .filter(site => {
                if (reCity && !site.city.match(reCity))
                    return false
                if (reTags) {
                    for (let item of site.items) {
                        if (item.name.match(reTags) || nameToTag(item.name).match(reTags)) {
                            return true
                        }
                    }
                    if (site.tags) {
                        for (let tag of site.tags) {
                            if (tag.match(reTags) || nameToTag(tag).match(reTags)) {
                                return true
                            }
                        }
                    }
                    return false
                }
                if (!cities && !tags && reWildcard) {
                    if (!site.name.match(reWildcard))
                        return false
                }
                return true
            })
            .sort((l, r) => {
                if (!l.items.length && r.items.length) return 1
                if (!r.items.length && l.items.length) return -1
                return +l.id < +r.id ? -1 : 1
            })


        let allCoaches = []
        for (let site of allSites) {
            if (site.supplementaryItems) {
                for (let coach of site.supplementaryItems) {
                    allCoaches.push({
                        coach,
                        site,
                        items: site.items.filter(item => coach.supplementaryTo.indexOf(item.id) !== -1),
                    })
                }
            }
        }
        const filteredCoaches = allCoaches
            .filter(({ coach, items, site }) => {
                // all coach items are hidden
                if (items.every(it => it.config && it.config.hiddenFromClients)) {
                    return false
                }

                if (reCity && !site.city.match(reCity)) {
                    return false
                }
                if (reCoach && !coach.name.match(reCoach))
                    return false
                if (reTags) {
                    for (let item of items) {
                        if (item.name.match(reTags) || nameToTag(item.name).match(reTags)) {
                            return true
                        }
                    }
                    return false
                }
                // console.log('test', coach)
                if (!reCoach && !reTags && reWildcard) {
                    // console.log('test', coach, reWildcard)
                    if (!coach.name.match(reWildcard) && !site.name.match(reWildcard))
                        return false
                }
                return true
            })

        return {
            sites,
            coaches: filteredCoaches,
        }
    }
)

const findFromList = (search, list) => {
    // search: tennis in sofia
    // list: Squash,Tennis,Volleyball
    // result: Tennis
    let matches = []
    for (let item of list) {
        if (search.search(new RegExp(item, 'i')) !== -1) {
            matches.push(item)
        }
    }
    console.log('regex results:', matches, 'list:', list)
    return matches.length ? matches : null
}
// const findOneFromList = (search, list) => {
//     let res = findFromList(search, list)
//     if (!res) return null
//     return res[0]
// }

const parseSearchAndPrepareFilter = (search, tags, cities, allSites) => {
    let matchingTags = findFromList(search, tags)
    let matchingCities = findFromList(search, cities)
    let wildcard = search.split(/\s/).filter(ss => ss.length >= 2)
    // siteNames = null // findFromList(search, allSites.map(ss => ss.name.toLowerCase()))

    if (cities) { // temporary accept only one city
        cities = cities.slice(0, 1)
    }
    if (!wildcard.length) {
        wildcard = null
    }
    return {
        tags: matchingTags,
        cities: matchingCities,
        wildcard,
        // siteNames,
    }
}

export function* searchSaga() {
    let channel = yield actionChannel(APPLY_SEARCH, buffers.sliding(1))
    while (true) {
        let { search } = yield take(channel)
        if (!search) {
            yield put(applySitesFilter(null))
            continue
        }

        let state = yield select()
        let sites = state.sites
        let tags = selectorUniqueTags(state)
        let cities = selectorUniqueCities(state)

        let sitesFilter = parseSearchAndPrepareFilter(search, tags, cities, sites)
        if (sitesFilter) {
            yield put(applySitesFilter(sitesFilter))
        }

        yield delay(500)
    }
}