import React from 'react'
import { Trans } from 'react-i18next';
import { i18n } from 'shared/i18n'
import Button from 'react-materialize/lib/Button'
import './CancelButtonWithConfirmation.css'

export class CancelButtonWithConfirmation extends React.Component {
    state = {
        confirm: false,
        inProgress: false
    }
    render() {
        const { onConfirm } = this.props
        const title = this.props.title || 'Cancel booking'
        const { confirm, inProgress } = this.state
        const setConfirm = () => this.setState({ confirm: true })
        const clearConrirm = () => this.setState({ confirm: false })
        const doConfirm = async () => {
            // protection agains super fast clickers
            if (this.inProgress) return
            this.inProgress = true

            this.setState({ inProgress: true })
            if (await onConfirm()) {
                this.setState({ inProgress: false, confirm: false })
            }

            this.inProgress = false
        }

        const style = {
            padding: '0 0.5rem',
            margin: '0 0.2rem',
        }

        if (inProgress) return <Button flat disabled><Trans>Cancelling</Trans>...</Button>;

        return <span>
            {!confirm
                ? <Button className="cancel-button" style={style} flat onClick={setConfirm}><Trans>{title}</Trans></Button>
                : <span>
                    <Button style={style} className="cancel-confirm-button" onClick={doConfirm}><Trans>Confirm cancel</Trans></Button>
                    <Button style={style} className="cancel-do-nothing-button" flat onClick={clearConrirm}><Trans>Do nothing</Trans></Button>
                </span>
            }
        </span>
    }
}

export class CancelRecurringButtonWithConfirmation extends React.Component {
    state = {
        confirm: false,
        inProgress: false,
        allOccurrences: false,
    }
    render() {
        const { onConfirm } = this.props
        const title = this.props.title || 'Cancel occurrence'
        const { confirm, inProgress, allOccurrences } = this.state
        const setConfirm = () => this.setState({ confirm: true })
        const clearConrirm = () => this.setState({ confirm: false })
        const toggleAllOccurrences = () => this.setState(state => ({ allOccurrences: !state.allOccurences }))
        const doConfirm = async () => {
            // protection agains super fast clickers
            if (this.inProgress) return
            this.inProgress = true

            if (allOccurrences && !window.confirm(i18n().t('Cancel this and all following occasions?'))) return
            this.setState({ inProgress: true })
            if (await onConfirm(allOccurrences)) {
                this.setState({ inProgress: false, confirm: false })
            }

            this.inProgress = false
        }

        const style = {
            padding: '0 0.5rem',
            margin: '0 0.2rem',
        }

        if (inProgress) return <Button flat disabled>Cancelling...</Button>;

        return <span>
            {!confirm
                ? <Button className="cancel-button" style={style} flat onClick={setConfirm}><Trans>{title}</Trans></Button>
                : <span>
                    <div className="cancel-recurring-checkbox">
                        <input id="cancel-recurring-input" type="checkbox" value={allOccurrences} onChange={toggleAllOccurrences} />
                        <label htmlFor="cancel-recurring-input"><Trans>All occurrences</Trans></label>
                    </div>
                    <Button style={style} className="cancel-confirm-button" onClick={doConfirm}><Trans>Confirm cancel</Trans></Button>
                    <Button style={style} className="cancel-do-nothing-button" flat onClick={clearConrirm}><Trans>Do nothing</Trans></Button>
                </span>
            }
        </span>
    }
}