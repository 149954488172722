import {
    put,
    take,
    call,
    cancel,
    fork
} from 'redux-saga/effects'
// import { eventChannel } from 'redux-saga'
import firebase from 'firebase/app'
import 'firebase/messaging'
import {
    USER_UPDATED,
    NOTIFICATIONS_ENABLED, NOTIFICATIONS_DISABLED,
    askAboutNotifications,
    notificationsPreferencesLoaded,
} from '../reducers'
import {
    ToastNotification,
} from '../components/common'
import { i18n } from 'shared/i18n'
import * as api from '../api'
import {
    checkUserNotificationsPermissions,
    disableNotifications,
    storeLocally,
} from '../utils/notifications'


const askForNotificationPermission = user => {
    const messaging = firebase.messaging()
    return messaging.requestPermission()
        .then(() => {
            console.log('notifications permission granted')
            return true
        })
        .catch(err => {
            console.log('notifications permission denied', err)
            return false
        })
}



const onForegroundMessage = payload => {
    console.log("######### Message received. ", payload);
    let { title, body } = payload.notification
    let element = `<div>
        <div><strong>${i18n().t(title)}</strong></div>
        <div>${body || ''}</div>
    </div>`
    ToastNotification.notificationWithoutTranslation(element)
}

let currentNotificationsToken

const fetchAndStoreToken = (user) => {
    const messaging = firebase.messaging()
    let old_token_info = checkUserNotificationsPermissions(user)
    messaging.getToken()
        .then(token => {
            currentNotificationsToken = token
            console.log('notifications token', token)
            if (!old_token_info || token !== old_token_info.token || !old_token_info.storedOnServer) {
                storeLocally(user, token, false)
                return api.storeNotificationsToken(token, old_token_info && old_token_info.token)
                    .then(() => storeLocally(user, token, true))
            }
        })
        .catch(err => {
            console.log('error getting and storing token', err)
            //what to store locally?
        })
}

let firebaseUnsubsriber;
const listenToFirebaseMessages = (user) => {
    try {
        const messaging = firebase.messaging()
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a sevice worker
        //   `messaging.setBackgroundMessageHandler` handler.
        let unsubMsgListener = messaging.onMessage(onForegroundMessage);
    
        fetchAndStoreToken(user)
    
        let unsubTokenListener = messaging.onTokenRefresh(() => {
            console.log('!!!!!!!!! token refresh detected')
            fetchAndStoreToken(user)
        })
        return () => {
            unsubMsgListener()
            unsubTokenListener()
        }
    } catch(err) {
        console.log('Error starting messaging listener:', err)
        return () => {}
    }
}

function* handleUserNotifications(user) {
    console.log('handleUserNotifications', user)
    // if (!user.specialPermissionsInSites) {
    //     //temporarily for admin users only
    //     return
    // }

    var isSupported = ('PushManager' in window)
    if (!isSupported) {
        yield put(notificationsPreferencesLoaded(false))
        return
    }

    let initialPerms = checkUserNotificationsPermissions(user)
    yield put(notificationsPreferencesLoaded(true, initialPerms ? true : initialPerms, initialPerms && typeof initialPerms === 'object' ? initialPerms.preferences : null))
    if (initialPerms) {
        currentNotificationsToken = initialPerms.token
        firebaseUnsubsriber = listenToFirebaseMessages(user)
    }
    // if (initialPerms === null) {
    //     yield put(askAboutNotifications())
    // }
    while (true) {
        let action = yield take([NOTIFICATIONS_ENABLED, NOTIFICATIONS_DISABLED])
        if (action.type === NOTIFICATIONS_ENABLED) {
            let granted = yield call(askForNotificationPermission, user)
            if (!granted) {
                return alert('You chose to receive notifications but they are blocked in your browser. You can enable them in Site Settings -> Notifications. On Chrome you can find it by pressing the secure button/icon on the left of the address bar.')
            }
            firebaseUnsubsriber = listenToFirebaseMessages(user)
        } else {
            disableNotifications(user)
            firebaseUnsubsriber && firebaseUnsubsriber()
            firebaseUnsubsriber = null
        }
        let prefs = ''
        if (action.type === NOTIFICATIONS_ENABLED) {
            // TODO read these from some common place
            prefs = 'NEW,CANCELED,PENDING,PENDING_COACH,CONFIRMED'
        }
        yield call(api.storeNotificationsPreferences, currentNotificationsToken, prefs)
    }
    //todo handle the cancel
}

export function* notificationsSaga() {
    let currentUser;
    let userSaga;
    while (true) {
        let action = yield take(USER_UPDATED)
        console.log('##########UserUpdated', action)
        let user = action.user
        if (userSaga && (!user || currentUser.uid !== user.uid)) {
            yield cancel(userSaga)
            userSaga = null
        }
        if (user && !userSaga) {
            currentUser = user
            userSaga = yield fork(handleUserNotifications, user)
        }
    }

}
