import React from 'react'
import { Trans } from 'react-i18next';
import Icon from 'react-materialize/lib/Icon'
import './SiteUnderConstruction.css'

export const SiteUnderConstruction = () => {
    return <div className="site-under-construction">
        <Icon>build</Icon>
        <span><Trans>Under construction</Trans></span>
    </div>
}