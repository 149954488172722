import React from 'react'
import { Trans } from 'react-i18next'
import { i18n } from 'shared/i18n'
import moment from 'moment'
import {
    NotificationsBadge,
} from './common/NotificationsPanel'
import cx from 'classnames'
import Icon from 'react-materialize/lib/Icon'
import {
    STATUS,
} from 'shared/types'
import {
    isPassed,
} from '../utils'
import './ScheduleTable.css'


const offsetToDate = offset => moment().add(offset, 'days')
const dateToStr = date => moment(date).format('YYYY-MM-DD')
const formatDate = date => moment(date, 'YYYY-MM-DD').format('dddd, DD.MM')
const formatHour = hour => hour.slice(0, 5)// + ':' + hour.slice(2)

export const CellContent = ({ timeslot, date, hour, passed, ignoreClickOnUnavailable, showFreeGradient, showPending, showOverbooked, hideFreeSlotsLeft, onCellClick, currency, backgroundColor, children }) => {
    if (!timeslot) return <span></span>;

    let { free, capacity } = timeslot
    let className = 'available'
    if (passed) {
        className = 'passed'
    } else if (!free) {
        className = 'all-busy'
    }
    if (free < 0) {
        if (showOverbooked) {
            className = 'overbooked'
        } else {
            free = 0
            className = 'all-busy'
        }
    }

    let freeLevelClass = ''
    if (showFreeGradient && free && free < capacity) {
        let freeLevel = Math.ceil(3 * free / capacity)
        freeLevelClass = 'free-' + freeLevel
    }
    let info = [
        formatHour(hour),
    ]
    if (!hideFreeSlotsLeft && capacity) {
        info.push(`${free} ${i18n().t('left')}`)
    }
    if (timeslot.price) {
        info.push(`${timeslot.price} ${currency}`)
    }
    let style
    if (timeslot.backgroundColor) {
        style = {
            backgroundColor: timeslot.backgroundColor
        }
    }

    return <div style={style} className={`content-cell ${className} ${freeLevelClass}`} onClick={e => (free || !ignoreClickOnUnavailable) && onCellClick(date, hour, timeslot)}>
        <div>{info.join(' / ')}</div>
        {!!timeslot.title && <div>{timeslot.title}</div>}
        {showPending && timeslot.pending !== 0 && <NotificationsBadge number={timeslot.pending} />}
        {children}
    </div>
}

export const ScheduleTable = ({ timetable, daysOffset = 0, daysCount = 7, renderCell, renderStat }) => {
    if (!renderCell) throw new Error('renderCell is mandatory property')
    // console.log('ScheduleTable', timetable)
    if (!timetable) return <div>No data</div>;

    let weekdayClass = {}
    let dates = []
    for (let offset = daysOffset; offset < daysOffset + daysCount; ++offset) {
        let date = offsetToDate(offset)
        let dateStr = dateToStr(date)
        weekdayClass[dateStr] = 'weekday-' + date.day()
        dates.push(dateStr)
    }
    const hoursObj = {}
    dates.forEach(date => {
        for (let hour in timetable[date]) {
            hoursObj[hour] = true
        }
    })
    return <div className="z-depth-2">
        <table className="schedule">
            <thead><tr>
                {dates.map(date => {
                    return <th className={weekdayClass[date]} key={date}>{formatDate(date)}</th>
                })}
            </tr></thead>
            <tbody>
                {Object.keys(hoursObj).sort().map(hour => (
                    <tr key={hour}>
                        {dates.map(date => (
                            <td className={weekdayClass[date]} key={date}>
                                {timetable[date] && renderCell(date, hour, timetable[date][hour])}
                            </td>
                        ))}
                    </tr>
                ))}
                {!!renderStat && <tr>
                    {dates.map(date => (
                        <td className="total-stat" key={date}>
                            {!!timetable[date] && renderStat(date, timetable[date])}
                        </td>
                    ))}
                </tr>}
            </tbody>
        </table >
    </div >
}



const formatBooking = (booking, user = {}) => {
    return <div key={booking.id}>
        {booking.user.displayName}
        {booking.supplItem && <span> <Trans>with</Trans> {booking.supplItem.name}</span>}
    </div>
}

export const OverviewTable = ({ timetableForDay, timeNow, width, disallowMergeTop, wrapHours, showBorder, originalItemId, date, highlightFreeRanges, highlightSupplId, onCellClick }) => {
    // console.log('!!@@', { timetableForDay, originalItemId, highlightFreeRanges, onCellClick })
    let style = { width }
    if (wrapHours) {
        style.display = 'flex'
        style.flexWrap = 'wrap'
    }
    if (showBorder) {
        style.border = '1px dashed #777'
        if (width) style.width = width + 2
    }
    let prevHoursBookings
    return <div className="ov-timetable" style={style}>
        {Object.keys(timetableForDay).sort().map(hour => {
            let tt = timetableForDay[hour]
            //multiply occurrences of bookings with quantity > 1
            let bookings = tt.bookings;

            let virtualFreeOffset = 0
            if (originalItemId) {
                // move this item bookings to the beginning
                bookings = bookings.filter(b => b.originalItemId === originalItemId).concat(bookings.filter(b => b.originalItemId !== originalItemId))
            }

            bookings = bookings.reduce((arr, booking) => {
                return arr.concat(Array((booking.quantity || 1) * (booking.originalItemMultiplier || 1)).fill(booking))
            }, [])

            if (highlightSupplId) {
                bookings = bookings.filter(booking => booking.supplItemId === highlightSupplId)
            }

            let capacity = tt.capacity
            let unmapped = tt.unmapped

            let rowLimit = capacity

            let overbooked
            if (tt.free < 0) {
                overbooked = true
                // console.log('OVERBOOKED', bookings, capacity, originalItemId, tt)
                rowLimit = bookings.length
            }

            let forceBusyHour
            if (highlightSupplId) {
                if (bookings.length) {
                    rowLimit = bookings.length
                } else {
                    rowLimit = 1
                    if (tt.free <= 0) {
                        highlightHour = false
                        forceBusyHour = true
                    }
                }
            }

            let highlightHour;
            if (highlightFreeRanges) {
                highlightHour = highlightFreeRanges.some(range => {
                    if (range.start <= tt.start && range.end >= tt.end) return true
                    return false
                })
                if (highlightHour && highlightSupplId) {
                    // this way only suppl booking will be visible, while the rest will be dimmed
                    if (bookings.some(bb => bb.supplItem && bb.supplItem.id === highlightSupplId))
                        highlightHour = false
                }
            }

            let slots = []
            for (let i = 0; i < rowLimit; ++i) {
                let info
                let booking = bookings[i - virtualFreeOffset]
                let bookingId, busy = false, debugInfo
                const clickHandler = (hour, booking, timeslot) => (() => onCellClick(hour, booking, timeslot))
                let onClick = clickHandler(hour, booking ? booking.id : null, tt)

                if (booking) {
                    // console.log('!!!', booking)
                    if (!originalItemId || booking.originalItemId === originalItemId) {
                        bookingId = booking.id
                        info = formatBooking(booking)
                    } else if (tt.free > virtualFreeOffset) {
                        // we have shown all bookings for this item, but there are still free slots
                        // so we show them as free and push other bookings potentially blocking the slots to the end
                        virtualFreeOffset++
                        onClick = clickHandler(hour, null, tt)
                    } else {
                        busy = true
                        onClick = undefined
                    }
                    // debugInfo = JSON.stringify({ booking, bookingId })
                } else if (forceBusyHour) {
                    busy = true
                    onClick = undefined
                }

                let highlight
                if (highlightHour !== undefined) {
                    highlight = highlightHour
                    if (bookingId) {
                        if (highlightSupplId && booking && booking.supplItem && booking.supplItem.id === highlightSupplId) {
                            highlight = null // no highlight no dim either
                        } else {
                            highlight = false
                        }
                    } else if (highlight === false) {
                        onClick = undefined
                    }
                }

                let cell = <div key={hour + bookingId + i}
                    className={cx({
                        "ov-cell": true,
                        'ov-free': !bookingId && !busy,
                        'ov-booking': !!bookingId,
                        // 'z-depth-1': !!bookingId,
                        'ov-busy': busy,
                        'ov-passed': isPassed(date, hour, timeNow),
                        'ov-pending': bookingId && booking.status === STATUS.PENDING,
                        'ov-missed': bookingId && booking.status === STATUS.MISSED,
                        'ov-highlight': highlight === true,
                        'ov-overbooked': overbooked === true || unmapped === true,
                        'ov-dim': highlight === false,
                        'ov-merge-left': i && bookings[i - 1] && bookings[i - 1].id === bookingId,
                        'ov-merge-right': bookings[i + 1] && bookings[i + 1].id === bookingId,
                        'ov-merge-top': !disallowMergeTop && width === undefined && prevHoursBookings && prevHoursBookings[i] && prevHoursBookings[i].id === bookingId,
                    })}
                    onClick={onClick}
                >
                    <div className="ov-hour">{(formatHour(hour))}</div>
                    {bookingId && booking.recurringId && <div className="recurring-icon" title={i18n().t('Recurring booking')}>
                        <Icon>repeat</Icon>
                    </div>}
                    {tt.title && <div className="ov-ts-title">{tt.title}</div>}
                    {info && <div className="ov-info">{info}</div>}
                    <span>{debugInfo}</span>
                </div>
                slots.push(cell)
            }

            prevHoursBookings = bookings

            return <div key={hour} className="ov-hour-slots">
                {slots}
            </div>
        })}
    </div>
}