const i18nInst = require('fm-client-web/i18n')
const _inst = {
    i18n: i18nInst.i18n
}
// console.log('Initial i18n inst', _inst.i18n)
const instance = {
    i18n: () => {
        // console.log('get i18n inst', _inst)
        return _inst.i18n
    },
    setInstance: inst => {
        console.log('Set i18n inst', inst)
        _inst.i18n = inst
    },
}

module.exports = instance
