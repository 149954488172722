import React from 'react'

export const FullScreenOverlay = props => {
    return <div style={{
        position: 'fixed',
        top: 0, bottom: 0, left: 0, right: 0,
        zIndex: 2002,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <div style={{
            zIndex: 2003,
            backgroundColor: 'initial',
        }}>
            {props.children}
        </div>
        <div style={{
            position: 'absolute',
            top: 0, left: 0,
            backgroundColor: 'black',
            opacity: props.dark ? 0.5 : 0.3,
            width: '100%',
            height: '100%',
        }} />
    </div>
}