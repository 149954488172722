import React from 'react';
import { Trans } from 'react-i18next';
import { i18n } from 'shared/i18n';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    NavLink
} from 'react-router-dom';
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'
import MediaBox from 'react-materialize/lib/MediaBox'
import {
    BodyContainer,
    Loading,
    Popup,
    CloseButton,
    ToastNotification,
    ScrollToTopOnMount,
    // ClientLogin,
} from '../../components/common'
import { ItemsTabs } from '../../components/ItemsTabs'
import { ScheduleTable, CellContent } from '../../components/ScheduleTable'
import { TimeRangeControl } from '../../components/TimeRangeControl'
import { DaySwitcher } from '../../components/DaySwitcher'
import { SelectCoach } from '../../components/SelectCoach'
import { SiteUnderConstruction } from '../../components/SiteUnderConstruction'
import { SetOrChangePhoneNumber } from '../../components/SetOrChangePhoneNumber'
import {
    SiteShortDescription,
    SiteWebsite,
    SitePhone,
    SiteAddress,
    SiteEmail,
    generateSiteDescription,
} from '../../components/SiteElements'
import { Meta } from '../../components/Meta'
import {
    GoogleMaps,
} from '../../components/GoogleMaps'
import { WatcherConfigPopup } from '../../components/WatcherConfig'
import {
    loadBookingsForRange, unloadBookings,
} from '../../reducers/bookings'
import {
    scheduleDaysToShow,
} from '../../reducers/screen_size'
import {
    createBooking,
} from '../../api'
import {
    ObjectFilter,
    ObjectMap,
    ArrayToObjectByKey,
    getTimeNowForSite,
    isDatetimePassed,
    extractTime,
    formatDatetime,
    isPassed,
    extractDate,
} from '../../utils'
import {
    siteURL,
    tagURL,
} from 'shared/sites'
import {
    getSupplementaryItems,
    getSharedItemCapacity,
    // generateRecurringRule,
    generateWorktimeFromScheduleForRange,
    mapByAvailSupplementary,
} from 'shared/bookings'
import { STATUS, LIMITS } from 'shared/types'
import {
    selectorTimetable,
    selectorMapBookingsToTimetable,
} from '../../selectors/bookings'
import {
    Booking
} from '../../models/booking'
import moment from 'moment'
import cx from 'classnames'
import './SiteDetail.css'
// import { ClientLogin } from '../../components/Login'
import { AsyncLoader } from '../../components/AsyncLoader'
import { MyBookingsCollapsible, onCancelBooking } from '../../components/MyBookings'
import HowItWorksHint from '../../components/HowItWorksHint'
import { NotificationsNotSupported } from '../../components/HelpHints'
const ClientLogin = props => <AsyncLoader name="ClientLogin" provider={() => import(/* webpackChunkName: "Login" */ '../../components/Login')} {...props} />

const formatHour = date => moment(date).format('HH:mm')
const formatDate = date => moment(date).format('dddd, DD.MM')

const MAX_DAYS_TO_SHOW = 8

let autoLoginPopup = window.location.search.indexOf('?mode=select') === 0

const LoginPopup = ({ onClose, onSignedIn, embedded }) => {
    return <Popup auto onClose={onClose}>
        <ClientLogin onSignedIn={onSignedIn} embedded={embedded} />
    </Popup>
}


const PhonePopup = ({ onClose }) => {
    return <Popup auto onClose={onClose}>
        <SetOrChangePhoneNumber showLabels={true} />
    </Popup>
}

const LimitReachedPopup = ({ site, onClose }) => {
    return <Popup auto onClose={onClose}>
        <div className="limit-site">
            <h3><Trans>You have reached your limit of upcoming bookings</Trans></h3>
            <HowItWorksHint title="What does it mean?">
                <Trans>You have a limit of upcoming bookings at given place until you build a history of at least 5 successful bookings. This measure is to prevent fraud and unfair competition.</Trans>
            </HowItWorksHint>
            <div className="limit-site-contacts">
                <div className="contact-the-place-text">
                    <Trans>Contact the place for more details</Trans>
                </div>
                <SitePhone withIcon site={site} />
                <SiteEmail withIcon site={site} />
            </div>
        </div>
    </Popup>
}

class PostCompleteMessage extends React.Component {
    state = {
        whyOpened: false,
    }
    render() {
        const { whyOpened } = this.state

        const clickWhy = e => {
            e.preventDefault()
            this.setState({ whyOpened: true })
        }

        return <div className="post-complete-message fadein-animation">
            <Trans>If you cannot make it please cancel the reservation at least 24 hours earlier.</Trans>
            {!whyOpened && <button className="link-button" onClick={clickWhy}>&nbsp;<Trans>Why?</Trans></button>}
            <div>
                {whyOpened && <Trans>Sport clubs have limited resources and it's really important for them to utilize them well. Clients not coming or cancelling too late don't give the club enough time to find someone else to use this hour.</Trans>}
            </div>
        </div>
    }
}

const NotificationsNotSupportedIfNeeded = connect(
    state => ({
        notificationsNotSupported: !state.user_profile.notifications.supported,
    })
)(
    ({ notificationsNotSupported }) => {
        if (notificationsNotSupported) {
            return <div style={{ margin: '1em 0' }}>
                <NotificationsNotSupported />
            </div>
        }
        return ''
    }
)

class BookingConfirm extends React.Component {
    constructor(props) {
        super(props)
        const { coachId, availSupplItems } = props
        const savedCoach = coachId ? (availSupplItems.find(it => it.id === coachId) || coachId) : null
        this.state = {
            savedCoach,
        }
    }
    render() {
        const { ts, freeCount, coachId, availSupplItems, timeRangeControl, site, item, bookingsLimit, showSelectCoach, completedStatus, inProgress, onChange, onConfirm, onClose, onShowWatcher } = this.props
        const { savedCoach } = this.state
        const onCoachSelect = val => onChange({ coachId: val })

        const notEnoughCapacity = (freeCount < 1 && !completedStatus && !inProgress)
        const bookingAllowed = bookingsLimit > 0
        let title = ''// i18n().t('Confirm booking')
        // if (completedStatus) {
        //     title = '' // i18n().t('Booking completed!')
        // }

        let actions = [
            <Button flat modal="close"><Trans>Cancel</Trans></Button>,
            <Button disabled={inProgress || notEnoughCapacity} onClick={() => onConfirm()}><Trans>Confirm</Trans></Button>
        ]
        if (!bookingAllowed) {
            actions = [
                <Button flat modal="close"><Trans>Close</Trans></Button>,
            ]
        }
        if (!!completedStatus) {
            actions = [
                <Button flat modal="close"><Trans>Ok, got it</Trans></Button>,
            ]
        }

        const __onCoachSelect = val => {
            console.log('onCoachSelect', val)
            this.setState({
                savedCoach: availSupplItems.find(it => it.id === val) || val
            })
            onCoachSelect(val)
        }

        let coachControl = '';
        if (showSelectCoach) {
            if (!completedStatus) {
                coachControl = <SelectCoach
                    supplementaryItems={availSupplItems}
                    selected={coachId}
                    onSelect={__onCoachSelect}
                />
            } else if (savedCoach) {
                coachControl = <div>{
                    savedCoach === '__any__'
                        ? <Trans>coach</Trans>
                        : <span><Trans>coach</Trans> {savedCoach.name}</span>
                }</div>
            }
        }

        let bookingStatus = '';
        if (completedStatus) {
            if (completedStatus === STATUS.PENDING) {
                bookingStatus = <div className="booking-confirm booking-confirm-pending fadein-animation">
                    <Trans>Booking is waiting confirmation</Trans>
                </div>
            } else {
                bookingStatus = <div className="booking-confirm booking-confirm-success fadein-animation">
                    <Trans>Booking successful</Trans>
                </div>
            }
        }
        return <div>
            <Popup auto
                // when overflowAuto is true, coach dropdown is not shown good.
                // when false, potencially longer text with post-complete message is not scrollable and stay ugly on phone
                overflowAuto={!!completedStatus}
                header={title}
                actions={actions}
                onClose={onClose}
            >
                {notEnoughCapacity && <div className="not-enough-capacity-banner"><Trans>Not enough capacity!</Trans></div>}
                {notEnoughCapacity && <div className="watch-suggestion">
                    <Trans>We can notify you if it becomes available.</Trans>
                    <button className="link-button" onClick={onShowWatcher}><Trans>See how</Trans></button>
                </div>}
                <div className={cx({
                    "booking-detail": true,
                    "completed": !!completedStatus,
                })}>
                    <div>
                        <div>{site.name}, <Trans>{item.name}</Trans></div>
                        <div>{formatDate(ts.start)}</div>
                        {timeRangeControl || <div>{formatHour(ts.start)} - {formatHour(ts.end)}</div>}
                        {ts.description && <div className="description">{ts.description}</div>}
                    </div>
                    <div>
                        {coachControl}
                    </div>
                </div>
                {bookingStatus}
                {completedStatus === STATUS.PENDING && <div className="pending-status-clarification">
                    <HowItWorksHint title="What does it mean?">
                        <div>
                            <Trans>This booking is not yet finalized - it needs to be additionally confirmed by the place. Check again later if you don't receive a notification.</Trans>
                        </div>
                        <div>
                            <Trans>Contact the place for more details</Trans>
                        </div>
                        <div className="site-contacts">
                            <SitePhone withIcon site={site} />
                            <SiteEmail withIcon site={site} />
                        </div>
                    </HowItWorksHint>
                    <NotificationsNotSupportedIfNeeded />
                </div>}
                {completedStatus && <PostCompleteMessage />}
            </Popup>
        </div>
    }
}

const findIntersectingValues = arrayOfArrays => {
    let temp;
    for (let arr of arrayOfArrays) {
        if (!temp) {
            temp = {}
            for (let val of arr) {
                temp[val] = 1
            }
        } else {
            for (let val of arr) {
                if (!temp[val]) return
                ++temp[val]
            }
        }
    }
    let count = arrayOfArrays.length
    return Object.keys(temp).filter(val => temp[val] === count)
}

class MediaBoxWithThumbnail extends React.Component {
    state = {
        expanded: false,
    }
    render() {
        const { thumbnail_url, url, ...rest } = this.props
        const { expanded } = this.state
        return <div onClick={() => this.setState({ expanded: true })}>
            <MediaBox
                src={expanded ? url : thumbnail_url}
                {...rest}
            />
        </div>
    }
}

const SiteInfo = ({ site }) => {
    const address = site.address
    const hasDescription = !!site.full_description
    const descriptionIsLong = hasDescription && site.full_description.length > 500

    const { gallery = [] } = site

    let map = ''
    if (address || site.location) {
        const fullAddress = `${site.country},${site.city},${address},${site.name}`
        map = <div className="site-map">
            <SiteAddress withIcon site={site} />
            <GoogleMaps address={fullAddress} coordinates={site.location} />
            {/* <GoogleMapsEmbed address={fullAddress} coordinates={site.location} /> */}
        </div>
    }
    let description = ''
    if (hasDescription) {
        description = <div className="site-full-description">{site.full_description}</div>
    }

    let right = description
    let left = map
    if (address && !descriptionIsLong) {
        right = map
        left = description
    }

    return <div>
        <div className="site-info">
            <div className={cx({
                "site-details": true,
                "with-description": hasDescription && !descriptionIsLong,
                "with-map": map && left === map,
            })}>
                <div className="contacts">
                    <div className="site-short-description">
                        <SiteShortDescription site={site} />
                    </div>
                    <SiteWebsite withIcon site={site} />
                    <SitePhone withIcon site={site} />
                    <SiteEmail withIcon site={site} />
                </div>
                <br />
                {left}
            </div>
            {right}
        </div>
        <div className="site-gallery">
            {gallery.map(({ thumbnail_url, url }) => <div className="z-depth-3">
                <MediaBoxWithThumbnail
                    thumbnail_url={thumbnail_url || url}
                    url={url}
                    caption={site.name}
                    height={75}
                />
            </div>)}
        </div>
    </div>
}

const SiteMeta = ({ site }) => {
    const supportsOnlineBooking = site.items.length > 0
    return <Meta
        title={`${site.name} - ${i18n().t(supportsOnlineBooking ? 'book online' : 'book now')}`}
        description={site.description || generateSiteDescription(site)}
        canonical={siteURL(site)}
    />
}

const Tags = ({ site }) => {
    let tags = [
        ...site.items.map(item => item.name),
        ...(site.tags || []),
    ]
    return <div className="site-tags">
        <ul>{tags.map(tag => <li key={tag}>
            <NavLink to={tagURL(tag)}>
                <Trans>{tag}</Trans>
            </NavLink>
        </li>)}</ul>
    </div>
}

const ExistingBookingsPopup = ({ canAddMore, bookings, site, onClose, onAddAnotherBooking }) => {
    let actions = [
        <CloseButton />,
    ]
    if (canAddMore) {
        actions.unshift(<Button id="add-another-booking-button" onClick={onAddAnotherBooking}><Trans>Add more</Trans></Button>)
    }
    return <Popup auto onClose={onClose}
        actions={actions}
    >
        <MyBookingsCollapsible
            sites={[site]}
            bookings={bookings}
            onCancelBooking={onCancelBooking}
        />
    </Popup>
}

const getWatchersByDate = createSelector(
    state => state.user_profile.watchers || {},
    watchers => {
        let watchersByDate = {}
        for (let groupId in watchers) {
            const watch = watchers[groupId]
            const date = extractDate(watch.fromDate)
            let arr = watchersByDate[date] || []
            arr.push(watch)
            watchersByDate[date] = arr
        }
        return watchersByDate
    }
)
const getWatcherForDateHour = (watchersByDate, date, hour, siteId, itemId) => {
    // console.log('!!!!!!!', watchersByDate, date, hour, itemId)
    const watchersArr = watchersByDate[date]
    if (!watchersArr) return null
    const datetime = date + ' ' + hour
    const filtered = watchersArr.filter(ww => {
        return (ww.active
            && datetime >= ww.fromDate
            && datetime < ww.toDate
            && ww.items.some(it => it.siteId === siteId && it.cond === itemId)
        )
    })
    // console.log('!!!!', filtered, datetime)
    return filtered[0]
}


const selectorUserBookingsByDate = createSelector(
    bookings => bookings,
    (bookings, itemId) => itemId,
    (bookings, itemId) => {
        const bookingsByDate = {}
        bookings.forEach(booking => {
            if (booking.itemId !== itemId) return
            switch (booking.status) {
                case STATUS.PENDING:
                case STATUS.CONFIRMED: {
                    let date = extractDate(booking.fromDate)
                    if (!bookingsByDate[date]) {
                        bookingsByDate[date] = []
                    }
                    bookingsByDate[date].push(booking)
                }
            }
        })
        // console.log('selectorUserBookingsByDate', bookings, itemId)
        return bookingsByDate
    }
)
const getUserBookingsForDateTimeslot = (bookingsByDate, start, end) => {
    let date = extractDate(start)
    let bookings = bookingsByDate[date]
    if (!bookings) return null
    bookings = bookings.filter(booking => booking.fromDate < end && booking.toDate > start)
    return bookings.length ? bookings : null
}

class SiteDetail extends React.Component {
    state = {
        selectedDateHour: null,
        coachId: null,
        addMoreBookings: false,
        completedStatus: false,
        inProgress: false,
        selectedFilterSupplIds: null,
        daysOffset: 0,
    }
    constructor(props) {
        super(props)
        this.siteId = null
        this.itemId = null
        if (props.preselectedSupplItemId) {
            this.state.selectedFilterSupplIds = props.preselectedSupplItemId
        }
    }
    componentDidMount() {
        console.log('componentDidMount', this.props)
        let { site, item } = this.props
        if (!item)
            item = site.items[0] || {}
        this.changeLoadDataParameters(site, item)
    }
    componentWillReceiveProps(props) {
        let { site, item } = props
        if (!item)
            item = site.items[0] || {}
        if (this.siteId !== site.id || this.itemId !== item.id || this.supplItemsCount !== site.supplementaryItems.length) {
            this.setState({ selectedDateHour: null, coachId: null, selectedFilterSupplIds: null })
            this.changeLoadDataParameters(site, item)
        }
    }
    changeLoadDataParameters(site, item) {
        this.siteId = site.id
        this.itemId = item.id
        this.supplItemsCount = site.supplementaryItems.length

        if (!item.id) // must be info tab
            return

        let supplementaryItemsIds = getSupplementaryItems(site, item.id).map(it => it.id)
        this.props.loadData(site.id, [item.id, ...supplementaryItemsIds]);
    }
    componentWillUnmount() {
        this.props.onUnmount()
    }
    render() {
        console.log('SiteDetail render')
        const { site, embedded, hideItems, loadingInProgress, bookingsByItemId, daysToShow, userSignedIn, userHasPhone, bookingsLimit, showInfoInTab, watchersByDate, userUpcomingBookings, onItemSelect, onCreateBooking } = this.props
        let { item } = this.props
        const { selectedDateHour, endHour, coachId, selectedFilterSupplIds, daysOffset, completedStatus, inProgress, showWatcher, addMoreBookings } = this.state
        console.log('SiteDetail render - bookings limit', { bookingsLimit })

        let items = site.items
        if (!embedded && showInfoInTab) {
            items = [
                {
                    id: 'info',
                    name: 'Info',
                },
                ...items,
            ]
        }
        if (!item) {
            item = items[0] || {}
        }
        const isInfoTab = () => item.id === 'info'

        let itemBookings = bookingsByItemId[item.id] || []

        const userBookingsByDate = selectorUserBookingsByDate(userUpcomingBookings, item.id)

        const timeNow = getTimeNowForSite(site)

        let supplementaryItems = getSupplementaryItems(site, item.id)
        let supplementaryItemsById = ArrayToObjectByKey(supplementaryItems, 'id')

        let supplementaryItemsWorkRangesById = ObjectMap(
            supplementaryItemsById,
            it => generateWorktimeFromScheduleForRange(
                it.schedule,
                it.schedule_exceptions,
                daysOffset, daysOffset + daysToShow
            )
        )

        let timetable = selectorTimetable({ item, daysOffset, daysToShow })
        let timetableWithBookings = selectorMapBookingsToTimetable({
            timetable,
            bookings: itemBookings,
            sharedItemMultiplier: item.sharedItemMultiplier,
            sharedItemCapacity: item.sharedItemId && getSharedItemCapacity(site, item.sharedItemId),
            originalItemId: item.sharedItemId && item.id,
        })

        let filteredTimetableWithBookings;
        if (selectedFilterSupplIds) {
            let supplIds = selectedFilterSupplIds === '__any__' ? supplementaryItems.map(it => it.id) : selectedFilterSupplIds
            filteredTimetableWithBookings = mapByAvailSupplementary(
                timetableWithBookings,
                ObjectFilter(supplementaryItemsWorkRangesById, (_, supplItemId) => supplIds.indexOf(supplItemId) !== -1),
                ObjectFilter(bookingsByItemId, (_, supplItemId) => supplIds.indexOf(supplItemId) !== -1)
            )
        }

        let showSelectCoach = (supplementaryItems.length > 0)

        let ts, availSupplItems, selectedFreeCount, followingEndTimes;
        if (selectedDateHour) {
            let { date, hour } = selectedDateHour
            try {
                ts = timetableWithBookings[date][hour]
                selectedFreeCount = ts.free

                let hours = [hour]
                let timetableSubset = {
                    [date]: {
                        [hour]: timetableWithBookings[date][hour]
                    }
                }

                let dateHours = Object.keys(timetableWithBookings[date]).sort()
                if (endHour) {
                    for (let hh of dateHours) {
                        if (hh > hour && hh < endHour) {
                            hours.push(hh)
                            timetableSubset[date][hh] = timetableWithBookings[date][hh]
                            selectedFreeCount = Math.min(selectedFreeCount, timetableSubset[date][hh].free)
                        }
                    }
                }

                if (item.config && item.config.booking_multiple_slots) {
                    const maxBookingDurationMin = item.config.booking_multiple_slots_max_duration
                    followingEndTimes = []
                    const maxEnd = formatDatetime(moment(ts.start).add(maxBookingDurationMin, 'minutes'))
                    // need to check if continuous?
                    for (let hh of dateHours) {
                        let eh = timetableWithBookings[date][hh].end
                        if (hh >= hour && eh <= maxEnd) { // should be <= because it's controlling end hour
                            followingEndTimes.push(extractTime(eh))
                        }
                    }
                }



                let filteredTimetableSubset = mapByAvailSupplementary(
                    timetableSubset,
                    supplementaryItemsWorkRangesById,
                    ObjectFilter(bookingsByItemId, (_, id) => !!supplementaryItemsById[id]) //  .find(it => it.id === supplItemId)),
                )
                // console.log('!!!filteredTimetableSubset', filteredTimetableSubset, timetableSubset)

                availSupplItems = []
                let availSupplItemsTemp = [];
                for (let hh of hours) {
                    if (!filteredTimetableSubset[date][hh] || !filteredTimetableSubset[date][hh].availSupplIds) {
                        availSupplItemsTemp = null
                        break
                    }
                    availSupplItemsTemp.push(filteredTimetableSubset[date][hh].availSupplIds)
                }
                if (availSupplItemsTemp) {
                    availSupplItems = findIntersectingValues(availSupplItemsTemp).map(id => supplementaryItemsById[id])
                }

                if (coachId) { // while selected in popup - if available coaches change, change the selection too
                    if (!availSupplItems.length ||
                        (coachId !== '__any__' && !availSupplItems.find(it => it.id === coachId))
                    ) {
                        setImmediate(() => this.setState({ coachId: null }))
                    } else if (coachId === '__any__' && availSupplItems.length === 1) {
                        setImmediate(() => this.setState({ coachId: availSupplItems[0].id }))
                    }
                }
            } catch (err) {
                console.error(err)
            }
        }

        const onFilterCoachSelect = selectedFilterSupplIds => {
            this.setState({ selectedFilterSupplIds })
        }
        const onChange = data => this.setState(data)

        const onPrev = () => {
            let newVal = daysOffset - daysToShow
            if (newVal < 0) newVal = 0
            this.setState({ daysOffset: newVal })
        }
        const onNext = () => {
            let newVal = daysOffset + daysToShow
            if ((newVal + daysToShow) > MAX_DAYS_TO_SHOW) newVal = MAX_DAYS_TO_SHOW - daysToShow
            this.setState({ daysOffset: newVal })
        }
        const onToday = () => this.setState({ daysOffset: 0 })

        const onClose = (keepSelected) => {
            if (!keepSelected) {
                // it's autohidden when component is unmount - in our case it should be valid 
                // for login and set phone popups showing when trying to book hour
                // preventing clearing selectedDateHour we make sure booking popup will be 
                // shown after login/set_phone are completed
                this.setState({
                    selectedDateHour: null,
                    showWatcher: false,
                    coachId: null,
                    addMoreBookings: false,
                    endHour: null,
                    inProgress: false,
                    completedStatus: false,
                })
                this.keepSelected = false
            }
        }

        const onConfirm = async () => {
            let supplItemId
            if (coachId) {
                supplItemId = coachId
                if (coachId === '__any__') {
                    let coach = availSupplItems[0]
                    if (!coach) {
                        return alert('Coach not found') // should never happen
                    }
                    supplItemId = coach.id
                }
            }
            const fromDate = ts.start
            const toDate = endHour ? `${selectedDateHour.date} ${endHour}` : ts.end
            try {
                this.setState({ inProgress: true })
                let resp = await onCreateBooking(site.id, item.id, fromDate, toDate, supplItemId)

                this.setState({ completedStatus: resp.status || STATUS.CONFIRMED, inProgress: false })
            } catch (err) {
                ToastNotification.error('Could not save')
                this.setState({ inProgress: false })
            }
        }

        const showSingleItemLabel = (items.length === 1 && item.name && item.id !== 'info' &&
            site.name.toLowerCase().indexOf(item.name.toLowerCase()) === -1 // to avoid duplicating sport name if already exists in site name
        )

        let existingBookingsList
        if (selectedDateHour) {
            const { date, hour } = selectedDateHour
            const start = `${date} ${hour}`
            const end = endHour ? `${date} ${endHour}` : (ts ? ts.end : date + ' 23:00:00')

            existingBookingsList = getUserBookingsForDateTimeslot(userBookingsByDate, start, end)
        }

        const bookingsLimitReached = !bookingsLimit

        let showPopup
        if (selectedDateHour) {
            showPopup = 'confirm'
            if (!userSignedIn) {
                showPopup = 'signin'
            } else if (!userHasPhone) {
                showPopup = 'phone'
            } else if (completedStatus || inProgress) {
                // leave confirm, should be before watch/limit
            } else if (existingBookingsList && !addMoreBookings) {
                showPopup = 'existing_bookings'
            } else if (showWatcher) {
                showPopup = 'watch'
            } else if (bookingsLimitReached) {
                showPopup = 'limit'
            }
        }

        if (autoLoginPopup) {
            if (!userSignedIn) {
                console.log('!!!! autoLoginPopup')
                showPopup = 'signin'
            } else {
                autoLoginPopup = false
            }
        }

        // it's to fix a glitch after cancelling the last booking and my bookings popup disappearing
        // for a moment either watch or confirm popups appear
        if (showPopup && this.prevShowPopup === 'existing_bookings' && !this.keepSelected) {
            if (showPopup != 'existing_bookings') {
                showPopup = undefined
            }
        }

        this.prevShowPopup = showPopup

        return <BodyContainer wide={embedded || daysToShow <= 2} className="short-fadein-animation">
            <ScrollToTopOnMount />
            <SiteMeta site={site} />
            {!embedded && <div className="site-heading">
                <h1>
                    <img className="site-logo-big" alt='' src={site.logoURL} />
                    &nbsp;
                    {site.name}
                    {!!site.hidden && <div style={{ display: 'inline-block' }}><SiteUnderConstruction /></div>}
                </h1>
                {!hideItems && showSingleItemLabel && <h2><Trans>{item.name}</Trans></h2>}
            </div>}
            <div className="site-detail">
                {!hideItems && items.length > 1 && <ItemsTabs
                    items={items}
                    activeItemId={item.id}
                    onItemSelect={onItemSelect}
                />}

                {!embedded && showInfoInTab && <div style={{ display: !isInfoTab() ? 'none' : 'block' }} className="short-fadein-animation">
                    <SiteInfo site={site} />
                </div>}

                {!isInfoTab() && !!item.id && <div className="control-panel">
                    <div>
                        {daysToShow < MAX_DAYS_TO_SHOW &&
                            <DaySwitcher
                                compact
                                disablePrev={daysOffset <= 0}
                                disableNext={(daysOffset + daysToShow >= MAX_DAYS_TO_SHOW)}
                                onPrev={onPrev}
                                onNext={onNext}
                                onToday={onToday}
                            />
                        }
                    </div>
                    <div>
                        {supplementaryItems.length > 0 &&
                            <SelectCoach
                                // className="right"
                                supplementaryItems={supplementaryItems}
                                title="Filter by coach"
                                selected={selectedFilterSupplIds}
                                onSelect={onFilterCoachSelect}
                            />
                        }
                    </div>
                </div>}

                {!isInfoTab() && !!item.id && (loadingInProgress
                    ? <Loading />
                    : <div className="short-fadein-animation">
                        <ScheduleTable
                            timetable={filteredTimetableWithBookings || timetableWithBookings}
                            daysCount={daysToShow}
                            daysOffset={daysOffset}
                            renderCell={(date, hour, data) => {
                                if (!data || isPassed(date, hour, timeNow)) return ''
                                const notEnoughCapacity = data.free <= 0
                                let hasWatcher = false
                                if (notEnoughCapacity && getWatcherForDateHour(watchersByDate, date, hour, site.id, item.id)) {
                                    hasWatcher = true
                                }
                                const userBookings = getUserBookingsForDateTimeslot(userBookingsByDate, data.start, data.end)
                                return <CellContent
                                    timeslot={data}
                                    hour={hour}
                                    date={date}
                                    currency={site.currency}
                                    hideFreeSlotsLeft={item.config && item.config.hide_free_slots_left}
                                    // ignoreClickOnUnavailable
                                    onCellClick={(date, hour, timeslot) => {
                                        this.setState({ selectedDateHour: { date, hour }, coachId: selectedFilterSupplIds, showWatcher: notEnoughCapacity })
                                    }}
                                >
                                    {notEnoughCapacity && <div>
                                        <div
                                            className={cx({
                                                "link-button": true,
                                                "watch-button": true,
                                                "has-watcher": hasWatcher,
                                                "z-depth-1": true,
                                            })}
                                            onClick={() => {
                                                this.setState({
                                                    selectedDateHour: { date, hour }, showWatcher: true,
                                                    addMoreBookings: !!userBookings, // it's to trigger showing watcher config instead of my bookings
                                                })
                                            }}
                                        >
                                            <Icon>{hasWatcher ? 'notifications' : 'add_alert'}</Icon>
                                        </div>
                                    </div>}
                                    {userBookings && <div
                                        className="user-booking-icon"
                                        title={i18n().t('Your booking')}
                                    >
                                        {/* <Icon>event</Icon> */}
                                        {/* <Icon>star</Icon> */}
                                        <Icon>account_box</Icon>
                                    </div>}
                                </CellContent>
                            }}
                        />

                    </div>
                )}

                {!embedded && !showInfoInTab && <div className="site-info-section z-depth-1">
                    <h2><Trans>About</Trans></h2>
                    <SiteInfo site={site} />
                </div>}

                {site.items.length === 0 && <div className="no-online-booking">
                    <div><Trans>We don't have online booking support with this club at the moment.</Trans></div>
                    <div><SitePhone withIcon site={site} /></div>
                </div>}

                {!embedded && <Tags site={site} />}
            </div>
            <div>
                {showPopup === 'signin' && <LoginPopup onClose={onClose} embedded={embedded} />}
                {showPopup === 'phone' && <PhonePopup onClose={onClose} />}
                {showPopup === 'limit' && <LimitReachedPopup site={site} onClose={onClose} />}
                {showPopup === 'watch' && <WatcherConfigPopup onClose={onClose}
                    date={selectedDateHour.date}
                    initialHour={selectedDateHour.hour}
                    initialEndHour={endHour || extractTime(ts.end)}
                    site={site}
                    item={item}
                    activeWatcher={getWatcherForDateHour(watchersByDate, selectedDateHour.date, selectedDateHour.hour, site.id, item.id)}
                />}
                {showPopup === 'existing_bookings' && <ExistingBookingsPopup onClose={() => onClose(this.keepSelected)}
                    canAddMore={!bookingsLimitReached}
                    bookings={existingBookingsList}
                    site={site}
                    onAddAnotherBooking={() => {
                        this.keepSelected = true
                        this.setState({ addMoreBookings: true })
                    }}
                >
                </ExistingBookingsPopup>}
                {showPopup === 'confirm' && <BookingConfirm
                    site={site} item={item}
                    ts={ts}
                    freeCount={selectedFreeCount}
                    coachId={coachId}
                    availSupplItems={availSupplItems}
                    showSelectCoach={showSelectCoach}
                    bookingsLimit={bookingsLimit}
                    completedStatus={completedStatus}
                    inProgress={inProgress}
                    timeRangeControl={
                        item.config && item.config.booking_multiple_slots && <TimeRangeControl
                            startTime={selectedDateHour.hour}
                            endTime={endHour || extractTime(ts.end)}
                            followingEndTimes={followingEndTimes}
                            onChangeEndTime={endHour => {
                                console.log('on change end time', endHour)
                                this.setState({ endHour })
                            }}
                        />
                    }
                    onChange={onChange}
                    onConfirm={onConfirm}
                    onClose={onClose}
                    onShowWatcher={() => this.setState({ showWatcher: true })}
                />}
            </div>
        </BodyContainer >
    }
}

const getBookingsLimit = createSelector(
    state => state.user_profile,
    (state, site) => site,
    (state, site, timeNow) => timeNow,
    (user_profile, site, timeNow) => {
        let level = user_profile.sitesLimits[site.id] || 0
        let userBookings = user_profile.bookings

        if (level === -1) return 0
        let limit = 0
        if (level === 0) {
            limit = LIMITS.level0
        } else {
            limit = LIMITS.level1
        }

        let bookings = userBookings
            .filter(bb => bb.siteId === site.id && bb.status !== STATUS.CANCELED && bb.status !== STATUS.REJECTED && !bb.recurringId)
            .filter(bb => !isDatetimePassed(bb.fromDate, timeNow))
        let remaining = limit - bookings.length
        if (remaining < 0) remaining = 0
        // console.log('!!!!!', { bookings, userBookings, remaining, limit, level })
        return remaining
    }
)

export default connect((state, props) => {
    console.log('Connect site detail', state, props)
    const { site, item, embedded, hideItems } = props
    const { loadingInProgress, bookingsByItemId } = state.bookings
    const { screen_size } = state
    const watchersByDate = getWatchersByDate(state)
    const userUpcomingBookings = state.user_profile.bookings || []
    const daysToShow = scheduleDaysToShow(screen_size.size)
    let showInfoInTab = (screen_size.width <= 992)
    const userSignedIn = (state.auth && state.auth.user)
    const userHasPhone = (state.auth && state.auth.user && !!state.auth.user.phoneNumber)
    let bookingsLimit = 0
    if (userSignedIn) {
        let timeNow = getTimeNowForSite(site)
        bookingsLimit = getBookingsLimit(state, site, timeNow)
    }
    const uiHearbeat = state.ui.heartbeat
    if (state)
        return {
            site,
            item,
            embedded,
            hideItems,
            loadingInProgress,
            bookingsByItemId,
            daysToShow,
            userSignedIn,
            userHasPhone,
            bookingsLimit,
            uiHearbeat,
            showInfoInTab,
            watchersByDate,
            userUpcomingBookings,
        }
}, (dispatch, props) => {
    return {
        onItemSelect: props.onItemSelect,
        loadData: (siteId, itemIds) => dispatch(loadBookingsForRange(siteId, itemIds, 0, MAX_DAYS_TO_SHOW)),
        onUnmount: () => dispatch(unloadBookings()),
        onCreateBooking: async (siteId, itemId, fromDate, toDate, supplItemId) => {
            let booking = new Booking(fromDate, toDate, siteId, itemId)
            if (supplItemId) {
                booking.supplItemId = supplItemId;
            }
            return await createBooking(booking)
        }
    }
})(SiteDetail)