import ReactGA from 'react-ga';
import {
    isServer,
} from 'shared/utils'

let GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

export const setTrackingId = id => {
    GA_TRACKING_ID = id
    console.log('Set GA Tracking ID', GA_TRACKING_ID)
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

if (GA_TRACKING_ID && !isServer()) {
    setTrackingId(GA_TRACKING_ID)
}

export const event = ev => {
    if (!GA_TRACKING_ID) {
        console.log('Ignore analytics event on dev', ev)
        return
    }
    ReactGA.event(ev)
}
export const pageview = path => {
    if (!GA_TRACKING_ID) {
        console.log('Ignore analytics pageview on dev', path)
        return
    }
    ReactGA.pageview(path)
}