import React, { Component, Suspense } from 'react';

import { BrowserRouter, Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as GA from './utils/analytics'
import { siteURL, siteIdFromSlug, siteHasEnabledNotifications } from 'shared/sites'
import { STATUS } from 'shared/types'

import firebase from 'firebase/app';
import 'firebase/auth'

// import { withTranslation, Trans } from 'react-i18next';
import { withNamespaces, Trans } from 'react-i18next';
// import { i18n } from 'shared/i18n'

import {
  Navigation,
  AdminSitesSelector,
  Loading,
  PageNotFound,
  SiteLogoName,
  // PostLoginForm,
  // AskConsent,
  FullScreenOverlay,
  SiteFooter,
  // TermsOfService,
  BodyContainer,
  NewVersionAvailable,
  ScrollToTopOnMount,
  // RegisterButton,
  // AsyncLoader,
  getNotifications,
} from './components/common';
import Button from 'react-materialize/lib/Button'
import { AskAboutNotifications } from './components/AskAboutNotifications'
import { AsyncLoader } from './components/AsyncLoader'
import { ContainerWithSideNav } from './components/SideNavigation'
import { Meta } from './components/Meta'
import Partners from './components/Partners'
import MyBookings from './components/MyBookings'
import {
  RegisterSection,
} from './components/WhyBookingGood'
// import { RegisterButton } from './components/WhyBookingGood'

// import LoginPage from './pages/login'

import Dashboard from './pages/_/Dashboard'
import SiteDetail from './pages/_/SiteDetail'
// import Profile from './pages/_/Profile'
// import RegisterSite from './pages/_/RegisterSite'
// import Pricing from './pages/_/Pricing'
// import WhyBookingGood from './pages/_/WhyBookingGood'

import { adminImportCalendarPostRedirect } from './api'
import { adminRedirectToken } from './api'
import {
  capitalize,
  stripLangFromUrlPath,
} from 'shared/utils';

import {
  applyAdminSiteId,
} from './reducers'

// import AdminDashboard, { AdminOverview } from './pages/admin/Dashboard'
// import AdminSite from './pages/admin/Site'
// import AdminFacilities from './pages/admin/Facilities'
// import AdminFacilityImport from './pages/admin/FacilityImport'
// import AdminPersonnel from './pages/admin/Personnel'
// import AdminClients from './pages/admin/Clients'
// import AdminProfile from './pages/admin/Profile'
// import AdminNotifications from './pages/admin/Notifications'
const AdminDashboard = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminDashboard" */ './pages/admin/Dashboard')}  {...props} />
const AdminOverview = props => <AsyncLoader name="AdminOverview" provider={() => import(/* webpackChunkName: "AdminDashboard" */ './pages/admin/Dashboard')}  {...props} />
const AdminSite = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminSite" */ './pages/admin/administration/Site')}  {...props} />
const AdminSubscription = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminSubscription" */ './pages/admin/administration/Subscription')}  {...props} />
const AdminFacilities = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminFacilities" */ './pages/admin/administration/Facilities')}  {...props} />
const AdminCalendar = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminCalendar" */ './pages/admin/administration/Calendar')}  {...props} />
const AdminPersonnel = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminPersonnel" */ './pages/admin/administration/Personnel')}  {...props} />
const AdminUsers = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminUsers" */ './pages/admin/administration/Users')}  {...props} />
const AdminClients = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminClients" */ './pages/admin/Clients')}  {...props} />
const AdminProfile = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminProfile" */ './pages/admin/Profile')}  {...props} />
const AdminSettings = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminSettings" */ './pages/admin/Settings')}  {...props} />
const AdminNotifications = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminNotifications" */ './pages/admin/Notifications')}  {...props} />
const AdminHelp = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminHelp" */ './pages/admin/Help')}  {...props} />
const AdminStatistics = props => <AsyncLoader provider={() => import(/* webpackChunkName: "AdminStatistics" */ './pages/admin/Statistics')}  {...props} />

const LoginPage = props => <AsyncLoader provider={() => import(/* webpackChunkName: "LoginPage" */ './pages/login')}  {...props} />
const InvitationLoginPage = props => <AsyncLoader name="InvitationLoginPage" provider={() => import(/* webpackChunkName: "LoginPage" */ './pages/login')}  {...props} />
const Profile = props => <AsyncLoader provider={() => import(/* webpackChunkName: "Profile" */ './pages/_/Profile')}  {...props} />
const Settings = props => <AsyncLoader provider={() => import(/* webpackChunkName: "Settings" */ './pages/_/Settings')}  {...props} />
const RegisterSite = props => <AsyncLoader provider={() => import(/* webpackChunkName: "RegisterSite" */ './pages/_/RegisterSite')}  {...props} />
const Pricing = props => <AsyncLoader provider={() => import(/* webpackChunkName: "Pricing" */ './pages/_/Pricing')}  {...props} />
const WhyBookingGood = props => <AsyncLoader provider={() => import(/* webpackChunkName: "WhyBookingGood" */ './pages/_/WhyBookingGood')}  {...props} />

const PostLoginForm = props => <AsyncLoader name="PostLoginForm" provider={() => import(/* webpackChunkName: "PostLoginForm" */ './components/PostLoginForm')}  {...props} />
const TermsOfService = props => <AsyncLoader name="TermsOfService" provider={() => import(/* webpackChunkName: "TermsOfService" */ './components/TermsOfService')} />

// const AskConsentIfNeeded = connect(
//   state => {
//     let { auth, user_profile } = state
//     return {
//       auth,
//       user_profile,
//     }
//   }
// )(
//   ({ auth, user_profile }) => {
//     if (auth && auth.user && user_profile && user_profile.consent === null) {
//       return <div>
//         <FullScreenOverlay dark>
//           <AskConsent />
//         </FullScreenOverlay>
//       </div>
//     }
//     return ''
//   }
// )


const OurPartners = () => {
  return <div className="our-partners">
    <h1><Trans>Our partners</Trans></h1>
    <Partners />
  </div>
}


const adminOptions = [
  ['/admin/site/general', 'General'],
  ['/admin/site/facilities', 'Facilities'],
  ['/admin/site/personnel', 'Coaches'],
  ['/admin/site/users', 'Users and Roles'],
  // ['/admin/site/subscription', 'Subscription'],
  ['/admin/site/calendar', 'Calendar'],
]

let waitingGoogleRedirectResponse = false

const AdminSitesSelectorConnected = connect(
  null,
  dispatch => {
    return {
      onSelect: siteId => dispatch(applyAdminSiteId(siteId)),
    }
  }
)(AdminSitesSelector)

const MainAdmin = (user, sites, askAboutNotifications, notifications, applyAdminSiteId, user_profile) => {

  // return <div>
  //   <Navigation
  //     options={[]}
  //     profile={user}
  //     onSignOut={() => firebase.auth().signOut()}
  //   />
  //   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: 250 }}>
  //     <p className="flow-text"><Trans>This functionality is now accessible on</Trans> admin.bookinggood.net</p>
  //     <Button onClick={async e => {
  //       try {
  //         const { token } = await adminRedirectToken()
  //         // console.log('token', token)
  //         window.location.href = `https://fm-management.firebaseapp.com/?auth-token=${token}`
  //       } catch (err) {
  //         console.log('Error getting redirect token', err)
  //         alert('Could not redirect. Please try again')
  //       }
  //     }}><Trans>Go to</Trans> admin.bookinggood.net</Button>
  //   </div>
  //   <SiteFooter />
  // </div>

  const siteId = applyAdminSiteId || Object.keys(user.specialPermissionsInSites)[0]
  const site = sites.find(s => s.id === siteId)
  const sitesArr = sites.filter(site => !!user.specialPermissionsInSites[site.id])
  // console.log('MainAdmin', site, siteId, applyAdminSiteId)
  if (!site) return <div>
    <Navigation
      options={[]}
      profile={user}
      brand={<AdminSitesSelectorConnected key="site-selector"
        sites={sitesArr}
      />}
      onSignOut={() => firebase.auth().signOut()}
    />
    <PageNotFound />
    <NewVersionAvailable />
    <SiteFooter />
  </div>
  const defaultItem = site.items[0]
  const itemWithId = id => site.items.find(it => it.id === id)
  const defaultSupplItem = site.supplementaryItems ? site.supplementaryItems[0] : null
  const supplItemWithId = id => site.supplementaryItems && site.supplementaryItems.find(it => it.id === id)

  const is = {}
  user.specialPermissionsInSites[siteId].permissions.forEach(role => is[role] = true)

  let askAboutNotificationPopup;
  const notificationsNotAsked = user_profile.notifications && user_profile.notifications.supported && user_profile.notifications.enabled === null
  // let notificationsToken = checkUserNotificationsPermissions(user)
  if (askAboutNotifications || (notificationsNotAsked && siteHasEnabledNotifications(site))) {
    askAboutNotificationPopup = <AskAboutNotifications
      description="We use notifications for bookings waiting confirmation and cancelled bookings."
    />
  }

  let navOptions = [];
  if (defaultItem) {
    if (is.admin || is.front_office)
      navOptions.push(['/admin/dashboard', 'Dashboard']);
    if (is.admin || is.front_office || is.coach)
      navOptions.push(['/admin/overview', 'Overview']);
  }
  if (is.admin || is.front_office) {
    navOptions.push(['/admin/statistics', 'Statistics']);
    navOptions.push(['/admin/clients', 'Clients']);
    navOptions.push(['/admin/help', 'Help']);
  }

  // if (is.admin)
  //   navOptions.push(['/admin/personnel', 'Personnel']);
  // if (is.admin)
  //   navOptions.push(['/admin/facilities', 'Facilities']);
  // if (is.admin)
  //   navOptions.push(['/admin/site', 'Admin']);

  let titlePrefix = ''
  if (notifications && notifications.length) {
    titlePrefix = `(${notifications.length}) `
  }
  const hasSingleSite = sites.length === 1;

  return <div className="inner-main" key={site.id}>
    <Meta
      title={`${titlePrefix}${site.name} - Console`}
    />
    <Navigation
      options={navOptions}
      adminOptions={is.admin && adminOptions}
      adminPathBase="/admin/site"
      profile={user}
      brand={<NavLink to="/" className={hasSingleSite ? "" : "brand-logo-navlink-multiple-sites"}>{
        hasSingleSite
          ? <SiteLogoName site={site} />
          : [
            <SiteLogoName key="logo" site={site} />,
            <AdminSitesSelectorConnected key="site-selector"
              sites={sitesArr}
              currentId={site.id}
            />
          ]
      }</NavLink>
      }
      notifications={notifications}
      onSignOut={() => firebase.auth().signOut()}
    />
    {askAboutNotificationPopup && <FullScreenOverlay dark>
      {askAboutNotificationPopup}
    </FullScreenOverlay>}
    <Switch>
      {/* Google Calendar SignIn */}
      <Route exact path="/google_auth_redirect" render={props => {
        console.log('google_auth_redirect', props)
        if (!waitingGoogleRedirectResponse) {
          waitingGoogleRedirectResponse = true
          adminImportCalendarPostRedirect(props.location.search)
            .then(data => {
              console.log('adminImportCalendarPostRedirect result', data)
              let redir = data.redir.replace(/^https?:\/\/[^/]+/, '')
              redir = stripLangFromUrlPath(redir)
              console.log('redirect to', redir)
              props.history.push(redir)
            })
            .catch(err => {
              console.log('Error handling google sign in', err)
              alert('There was an error with Google Sign In. Please try again')
              props.history.push('/')
            })
        }
        return <Loading />
      }} />
      {/* //// */}
      {(is.admin || is.front_office) && <Route exact path="/admin/dashboard/"
        render={_ => {
          if (!defaultItem) {
            return <Redirect to={`/admin/site/general`} />
          }
          return <Redirect to={`/admin/dashboard/i/${defaultItem.id}`} />

        }}
      />}
      {(is.admin || is.front_office) && <Route exact path="/admin/dashboard/i/:itemId" render={props => {
        const item = itemWithId(props.match.params.itemId)
        if (!item) {
          if (!defaultItem) {
            return <Redirect to={`/admin/site/general`} />
          }
          return <Redirect to={`/admin/dashboard/i/${defaultItem.id}`} />
        }

        return <AdminDashboard site={site} item={item}
          onItemSelect={itemId => {
            props.history.push(`/admin/dashboard/i/${itemId}`)
          }}
        />
      }} />}

      <Route exact path="/admin/overview" render={props => {
        if (!defaultItem) {
          return <Redirect to={`/admin/site/general`} />
        }
        return <AdminOverview key={site.id} site={site} />
      }} />

      {is.admin && <Route path="/admin/site/general" render={props => {
        return <ContainerWithSideNav options={adminOptions}>
          <AdminSite site={site} />
        </ContainerWithSideNav>
      }} />}

      {is.admin && <Route path="/admin/site/subscription" render={props => {
        return <ContainerWithSideNav options={adminOptions} wide>
          <AdminSubscription />
        </ContainerWithSideNav>
      }} />}

      {is.admin && <Route exact path="/admin/site/facilities"
        render={props => {
          if (defaultItem) return <Redirect to={`/admin/site/facilities/i/${defaultItem.id}`} />
          return <ContainerWithSideNav options={adminOptions}>
            <AdminFacilities site={site}
              onItemCreate={() => props.history.push(`/admin/site/facilities/new`)}
            />
          </ContainerWithSideNav>
        }}
      />}
      {is.admin && <Route exact path="/admin/site/facilities/i/:itemId" render={props => {
        let showExperimental = (props.location.search.indexOf('show_experimental') !== -1)
        const item = itemWithId(props.match.params.itemId)
        if (!item) return <Redirect to={`/admin/site/facilities`} />;
        return <ContainerWithSideNav options={adminOptions}>
          <AdminFacilities key={site.id + '-' + item.id} site={site} item={item}
            onItemSelect={itemId => props.history.push(`/admin/site/facilities/i/${itemId}`)}
            onItemCreate={() => props.history.push(`/admin/site/facilities/new`)}
            showExperimental={showExperimental}
          />
        </ContainerWithSideNav>
      }} />}
      {is.admin && <Route exact path="/admin/site/facilities/new" render={props => {
        return <ContainerWithSideNav options={adminOptions}>
          <AdminFacilities key={site.id + '-new'} site={site} createNew={true}
            onItemSelect={itemId => itemId
              ? setTimeout(() => props.history.push(`/admin/site/facilities/i/${itemId}`), 1500)
              : props.history.push(`/admin/site/facilities`)
            }
          />
        </ContainerWithSideNav>
      }} />}

      {is.admin && <Route exact path="/admin/site/calendar"
        render={props => {
          let showExperimental = (props.location.search.indexOf('show_experimental') !== -1)
          return <ContainerWithSideNav options={adminOptions}>
            {/* <div><Trans>You need to add at least one Facility</Trans></div> */}
            <AdminCalendar site={site} showExperimental={showExperimental} />
          </ContainerWithSideNav>
        }}
      />}
      {/* {is.admin && <Route exact path="/admin/site/import"
        render={props => {
          if (defaultItem) return <Redirect to={`/admin/site/import/i/${defaultItem.id}`} />
          return <ContainerWithSideNav options={adminOptions}>
            <div><Trans>You need to add at least one Facility</Trans></div>
          </ContainerWithSideNav>
        }}
      />}
      {is.admin && <Route exact path="/admin/site/import/i/:itemId" render={props => {
        let showExperimental = (props.location.search.indexOf('show_experimental') !== -1)
        const item = itemWithId(props.match.params.itemId)
        if (!item) return <Redirect to={`/admin/site/facilities`} />;
        return <ContainerWithSideNav options={adminOptions}>
          <AdminFacilityImport key={site.id + '-' + item.id} site={site} item={item}
            showExperimental={showExperimental}
            onItemSelect={itemId => props.history.push(`/admin/site/import/i/${itemId}`)}
          />
        </ContainerWithSideNav>
      }} />} */}

      {is.admin && <Route exact path="/admin/site/personnel"
        render={props => {
          if (defaultSupplItem) return <Redirect to={`/admin/site/personnel/i/${defaultSupplItem.id}`} />
          return <ContainerWithSideNav options={adminOptions}>
            <AdminPersonnel site={site}
              onItemCreate={() => props.history.push(`/admin/site/personnel/new`)}
            />
          </ContainerWithSideNav>
        }}
      />}
      {is.admin && <Route exact path="/admin/site/personnel/i/:itemId" render={props => {
        const item = supplItemWithId(props.match.params.itemId)
        if (!item) return <Redirect to={`/admin/site/personnel`} />;
        return <ContainerWithSideNav options={adminOptions}>
          <AdminPersonnel key={site.id + '-' + item.id} site={site} item={item}
            onItemSelect={itemId => props.history.push(`/admin/site/personnel/i/${itemId}`)}
            onItemCreate={() => props.history.push(`/admin/site/personnel/new`)}
          />
        </ContainerWithSideNav>
      }} />}
      {is.admin && <Route exact path="/admin/site/personnel/new" render={props => {
        return <ContainerWithSideNav options={adminOptions}>
          <AdminPersonnel key={site.id + '-new'} site={site} createNew={true}
            onItemSelect={itemId => itemId
              ? setTimeout(() => props.history.push(`/admin/site/personnel/i/${itemId}`), 1500)
              : props.history.push(`/admin/site/personnel`)
            }
          />
        </ContainerWithSideNav>
      }} />}

      {is.admin && <Route exact path="/admin/site/users"
        render={props => {
          return <ContainerWithSideNav options={adminOptions}>
            <AdminUsers site={site}
            />
          </ContainerWithSideNav>
        }}
      />}

      <Route exact path="/admin/statistics" render={props => {
        return <AdminStatistics key={site.id} site={site} />
      }} />

      <Route exact path="/admin/clients" render={props => {
        return <AdminClients
          siteId={siteId}
          onSelect={id => {
            props.history.push(`/admin/clients/id/${id}`)
          }}
        />
      }} />
      <Route exact path="/admin/clients/id/:userId" render={props => {
        // const item = supplItemWithId(props.match.params.itemId)
        const userId = props.match.params.userId

        // if (!item) return <PageNotFound />;
        return <AdminClients
          siteId={siteId}
          userId={userId}
          onBack={() => {
            // props.history.push(`/admin/clients`)
            props.history.goBack()
          }}
        />
      }} />

      <Route exact path="/admin/help" component={AdminHelp} />
      <Route exact path="/admin/help/:topic" component={AdminHelp} />
      <Route path="/admin/help/:topic/:subtopic" component={AdminHelp} />

      <Route exact path="/upgrade" render={props => {
        return <Pricing />
      }} />

      <Route exact path="/profile" component={AdminProfile} />
      <Route exact path="/settings" component={AdminSettings} />
      <Route exact path="/notifications" component={AdminNotifications} />

      <Route exact path="/register_club" render={props => {
        return <RegisterSite onCompleted={() => window.location.href = '/'} />
      }} />

      {is.coach
        ? <Redirect to="/admin/overview" />
        : <Redirect to="/admin/dashboard" />
      }
    </Switch>
    <NewVersionAvailable />
    <SiteFooter />
  </div>
}

const autosetPlacesCanonical = () => {
  const pathname = stripLangFromUrlPath(window.location.pathname)
  if (pathname) {
    const rePlacesUrl = /(\/places\/[^/]+-\d+\/)(\d+\/)?/
    const res = rePlacesUrl.exec(pathname)
    if (res) {
      return res[1]
    }
  }
  return null
}

const DefaultMeta = ({ canonical }) => <Meta
  title='Online reservations for sport'
  description='Find sport clubs and coaches and book online'
  canonical={canonical || autosetPlacesCanonical()}
/>

const MainRegular = (user, sites, askAboutNotifications, user_profile) => {
  let options = [
    // ['/dashboard', 'Dashboard'],
  ]
  if (user === null) {
    options.push(['/login', 'Sign In'])
  }
  let askAboutNotificationPopup;
  if (user) {
    const { notifications, watchers, bookings } = user_profile
    if (notifications.supported && notifications.enabled === null) {
      if (
        (watchers && Object.keys(watchers).length) ||
        (bookings && bookings.some(booking => booking.status === STATUS.PENDING))
      ) {
        askAboutNotifications = true
      }
    }
  }
  if (askAboutNotifications) {
    askAboutNotificationPopup = <AskAboutNotifications
      description="We inform you when some of your bookings get confirmed (if needed) and when some of the resources that you are watching gets available."
    />
  }
  return <div className="inner-main">
    <Navigation
      options={options}
      profile={user}
      onSignOut={() => firebase.auth().signOut()}
    />
    <DefaultMeta />
    {user && !user.displayName && <FullScreenOverlay dark>
      <PostLoginForm />
    </FullScreenOverlay>}
    {askAboutNotificationPopup && <FullScreenOverlay dark>
      {askAboutNotificationPopup}
    </FullScreenOverlay>}
    <Switch>
      {/* <Route exact path="/" render={() => {
        return <div>
          <DefaultMeta canonical="/" />
          <Intro hidden />
          <Dashboard key="dash" />
          <RegisterSection key="register-section" />
          <OurPartners key="partners" />
        </div>
      }} />

      <Route exact path="/tag/:tag" render={props => {
        const { tag } = props.match.params

        return <div>
          <Meta
            title={capitalize(tag)} // TODO restore real tag name
            description={`${i18n().t(capitalize(tag))} ${i18n().t('clubs and coaches')}`}
            canonical={`/tag/${tag}/`}
          />
          <ScrollToTopOnMount />
          <Intro hidden />
          <Dashboard key="dash" tag={tag} />
        </div>
      }} /> */}

      <Route path="/login/:tab" component={LoginPage} />
      <Route path="/login" render={() => <LoginPage hidePhone />} />
      <Route path="/login_after_invitation/:code" component={InvitationLoginPage} />


      {/* {["/places/:slug", "/places/:slug/:itemId"].map(path =>
        <Route exact path={path} key={path} render={props => {
          console.log('props', props)
          let coachId
          if (props.location.search) {
            let res = props.location.search.match(/coachId=(\d+)/)
            if (res) {
              coachId = res[1]
            }
          }
          const { slug, itemId } = props.match.params
          const siteId = siteIdFromSlug(slug)

          const site = sites.find(s => s.id === siteId)
          if (!site) {
            console.log('site not found:', siteId)
            return <Redirect to="/" />;
          }
          let item
          if (itemId && itemId !== 'info') {
            item = site.items.find(it => it.id === itemId)
            if (!item) {
              console.log('site', siteId, 'item not found:', itemId)
              return <Redirect to={siteURL(site)} />
            }
          }
          return <SiteDetail site={site} item={item} preselectedSupplItemId={coachId} onItemSelect={itemId => {
            props.history.push(siteURL(site, itemId))
          }} />
        }} />
      )}

      <Route exact path="/s/:siteId" render={props => {
        console.log('render with siteId', props)
        const siteId = props.match.params.siteId
        let site = sites.find(site => site.id === siteId)
        if (!site) {
          console.log('site not found :', siteId)
          return <Redirect to="/" />;
        }
        return <Redirect to={siteURL(site)} />
      }} />

      <Route exact path="/s/:siteId/:itemId" render={props => {
        const { siteId, itemId } = props.match.params
        const site = sites.find(s => s.id === siteId)
        if (!site) {
          console.log('site not found:', siteId)
          return <Redirect to="/" />;
        }
        return <Redirect to={siteURL(site, itemId)} />
      }} />

      {user && <Route exact path="/profile" component={Profile} />}
      {user && <Route exact path="/settings" component={Settings} />}

      <Route exact path="/register_club/free_trial" render={props => {
        if (!user) {
          // if free trial consumed
          return <Redirect to="/pricing" />
        }
        return <RegisterSite onCompleted={() => { window.location = '/' }} />
      }} />
      <Route exact path="/register_club/signup" render={props => {
        if (!user) {
          return <LoginPage {...props}
            returnUrl={window.location.origin + '/pricing'}
            hidePhone={true}
          />
        }
        return <Redirect to="/pricing" />
      }} />

      <Route exact path="/register_club" render={props => {
        let promoRes = props.location.search.match(/promo=([^&]+)/)
        if (promoRes && promoRes[1]) {
          window.sessionStorage.setItem('promoCode', promoRes[1])
        }
        return <Pricing />
      }} />
      <Route exact path="/pricing" render={props => {
        return <Pricing />
      }} />
      <Route exact path="/why-booking-good" render={props => {
        return <WhyBookingGood
        // registerAction={
        //   <RegisterButton />
        // }
        />
      }} /> */}

      {/* <Redirect to="/" /> */}
      <Redirect to="/login" />
    </Switch>
    <NewVersionAvailable />
    <SiteFooter />
  </div>
}

// const MainEmbed = (user, sites) => {
//   console.log('Main embed', user, sites)

//   return <div>
//     {user && !user.displayName && <FullScreenOverlay dark>
//       <PostLoginForm />
//     </FullScreenOverlay>}
//     <Switch>

//       <Route exact path="/embed/club/:siteId" render={props => {
//         console.log('render with siteId', props)
//         const siteId = props.match.params.siteId
//         let site = sites.find(site => site.id === siteId)
//         if (!site || !site.items.length) {
//           console.log('site not found or empty items:', siteId)
//           return <Redirect to="/embed/notfound" />;
//         }
//         return <Redirect to={`/embed/club/${siteId}/${site.items[0].id}`} />
//       }} />

//       <Route exact path="/embed/club/:siteId/:itemId" render={props => {
//         const { siteId, itemId } = props.match.params
//         console.log('render with siteId, itemId', props)
//         const site = sites.find(s => s.id === siteId)
//         if (!site || !site.items.length) {
//           console.log('site not found or empty items:', siteId)
//           return <Redirect to="/embed/notfound" />;
//         }
//         const item = site.items.find(it => it.id === itemId)
//         if (!item) {
//           console.log('site', siteId, 'item not found:', itemId)
//           return <Redirect to={`/embed/club/${siteId}/${site.items[0].id}`} />
//         }
//         return <SiteDetail embedded site={site} item={item} onItemSelect={itemId => {
//           props.history.push(`/embed/club/${siteId}/${itemId}`)
//         }} />
//       }} />

//       <Route exact path="/embed/facility/:siteId/:itemId" render={props => {
//         const { siteId, itemId } = props.match.params
//         console.log('render with siteId, itemId', props)
//         const site = sites.find(s => s.id === siteId)
//         const item = site && site.items.find(it => it.id === itemId)
//         if (!site || !site.items.length || !item) {
//           console.log('club or facility not found:', siteId, itemId)
//           return <Redirect to="/embed/notfound" />;
//         }
//         return <SiteDetail embedded hideItems site={site} item={item} onItemSelect={() => { }} />
//       }} />

//       <Route exact path="/embed/user/upcoming" render={props => {
//         if (!user) return <div />
//         return <MyBookings embed />
//       }} />

//       <Route exact path="/embed/notfound" render={props => {
//         return <div>Sorry, old sport. This facility is not found</div>
//       }} />

//       <Redirect to="/embed/notfound" />
//     </Switch>
//   </div>
// }

const Spinner = () => <div style={{
  flex: '1 0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 150
}}>
  <Loading />
</div>

// const LoadingScreen = () => <div className="inner-main" >
//   <Spinner />
// </div>

const Intro = ({ hidden }) => <div className="intro" style={{
  display: hidden ? 'none' : undefined,
}}>
  <div>

    <div className="intro-description line-1">Online reservations made easy.</div>
    <div className="intro-description line-2">Any sport, any place.</div>
  </div>
</div>

const MetaNavFooter = ({ children, meta }) => <div className="inner-main">
  {meta || <DefaultMeta />}
  <Navigation options={[]} />
  {children}
  <SiteFooter />
</div>

let historyListenerAttached = false

const TOS = () => <MetaNavFooter
  meta={<Meta
    title={`Terms of service`}
  />}
>
  <BodyContainer>
    <TermsOfService />
  </BodyContainer>
</MetaNavFooter>

const Main = ({ auth, sites, site_admin, applyAdminSiteId, askAboutNotifications, notifications, location, history, introFinished, user_profile }) => {
  // console.log('main', location, match)
  // sites = null
  if (!historyListenerAttached) {
    history.listen(it => {
      GA.pageview(it.pathname)
    })
    historyListenerAttached = true
  }

  let embedded = !!location.pathname.match(/^\/embed/)
  let terms = !!location.pathname.match(/^\/terms/)
  let isMain = location.pathname === '/'
  const showIntro = (isMain && !introFinished)

  if (terms) {
    return <TOS />
  }

  if (showIntro || auth === null || !sites) {
    if (embedded) {
      return <div className="inner-main" >
        <Spinner />
      </div>
    }
    if (showIntro) {
      return <MetaNavFooter>
        <Intro />
        <RegisterSection key="register-section" />
      </MetaNavFooter>
    }
    return <MetaNavFooter>
      <Spinner />
    </MetaNavFooter>
  }

  const user = auth.user

  // if (embedded) {
  //   return MainEmbed(user, sites)
  // }

  if (user && user.specialPermissionsInSites) {
    if (!site_admin)
      return <MetaNavFooter>
        <Spinner />
      </MetaNavFooter>
    return MainAdmin(user, sites, askAboutNotifications, notifications, applyAdminSiteId, user_profile)
  }

  // regular user or not logged
  if (user === null || !user.specialPermissionsInSites) {
    return MainRegular(user, sites, askAboutNotifications, user_profile)
  }
}
const MainConnected = withNamespaces()(withRouter(connect(
  state => ({
    auth: state.auth,
    sites: state.sites,
    site_admin: state.site_admin,
    applyAdminSiteId: state.ui.applyAdminSiteId,
    askAboutNotifications: state.user_profile && state.user_profile.askAboutNotifications,
    user_profile: state.user_profile,
    // consent: state.user_profile ? state.user_profile.consent : undefined,
    notifications: getNotifications(state),
  })
)(Main)))


class App extends Component {
  state = {
    introFinished: false,
  }
  componentDidMount() {
    setTimeout(() => this.setState({ introFinished: true }), 2700)
  }
  render() {
    const { language } = this.props
    const { introFinished } = this.state
    let basename = (language === 'en' ? '' : `/${language}`)
    return (
      <BrowserRouter key={basename} basename={basename}>
        <Suspense fallback={<span>...</span>}>
          <MainConnected introFinished={introFinished} />
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default connect(
  state => {
    const language = state.ui.language
    return {
      language,
    }
  }
)(App);
