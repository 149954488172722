export const LOAD_BOOKINGS_FOR_RANGE = 'LOAD_BOOKINGS_FOR_RANGE'
export const loadBookingsForRange = (siteId, itemIds, daysOffsetFrom, daysOffsetTo) => {
    return {
        type: LOAD_BOOKINGS_FOR_RANGE,
        siteId, itemIds, daysOffsetFrom, daysOffsetTo,
    }
}

export const UNLOAD_BOOKINGS = 'UNLOAD_BOOKINGS'
export const unloadBookings = () => {
    return {
        type: UNLOAD_BOOKINGS,
    }
}

export const BOOKINGS_UPDATED = 'BOOKINGS_UPDATED'
export const bookingsUpdated = (bookingsByItemId) => {
    return {
        type: BOOKINGS_UPDATED,
        bookingsByItemId,
    }
}

export const BOOKINGS_LOAD_IN_PROGRESS = 'BOOKINGS_LOAD_IN_PROGRESS'
export const bookingsLoadInProgress = () => {
    return {
        type: BOOKINGS_LOAD_IN_PROGRESS,
    }
}

const defaultState = {
    // loadingInProgress: false,
    loadingInProgress: true,
    bookingsByItemId: {}
}
export const reducerBookings = (state = defaultState, action) => {
    switch (action.type) {
        case BOOKINGS_UPDATED:
            return {
                ...state,
                loadingInProgress: false,
                bookingsByItemId: action.bookingsByItemId
            }
        case BOOKINGS_LOAD_IN_PROGRESS:
            return {
                ...state,
                loadingInProgress: true,
                bookingsByItemId: {}
            }
        default:
            return state
    }
}