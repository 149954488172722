import React, { Component, PureComponent } from 'react'
import { connect } from 'react-redux'
import Button from 'react-materialize/lib/Button'
import Icon from 'react-materialize/lib/Icon'
import { Trans } from 'react-i18next'
import {
    ToastNotification,
    Popup,
} from './common'
import IncreaseDecreaseControl from '../components/IncreaseDecreaseControl'
import HowItWorksHint from '../components/HowItWorksHint'
import { NotificationsNotSupported } from '../components/HelpHints'
import { selectorSitesWithItemInCity } from "../sagas/search";
import {
    watchResource,
    stopWatchResource,
} from "../api";
import { capitalize, extractTime } from 'shared/utils'
import moment from 'moment'
import "./WatcherConfig.css"



const formatDate = date => capitalize(moment(date).format('dddd, l'))
const formatTime = timeStr => timeStr.substr(0, 5)

const addMinutes = (time, stepMinutes) => moment(`2000-01-01 ${time}`).add(stepMinutes, 'minutes').format('HH:mm')

const DateTimePicker = ({ label, date, hour, min, max, onChange }) => {
    return <div>
        <Trans>{label}</Trans>
        <IncreaseDecreaseControl
            value={formatTime(hour)}
            min={min}
            max={max}
            onDecrease={() => onChange({ hour: addMinutes(hour, -60) })}
            onIncrease={() => onChange({ hour: addMinutes(hour, 60) })}
        />
    </div>
}


class __WatcherConfig extends Component {
    constructor(props) {
        super(props)
        let { site, date, initialHour, initialEndHour } = props
        let selectedSites = {
            [site.id]: true,
        }

        this.state = {
            selectedSites,
            fromDate: date,
            toDate: date,
            fromHour: initialHour,
            toHour: initialEndHour,
            inProgress: false,
        }
    }
    render() {
        // console.log('watcher config props', this.props)
        let { onWatch, onStopWatching, onClose, site, item,
            sitesWithItem,
            activeWatcher,
            notificationsNotSupported,
        } = this.props
        const {
            selectedSites,
            fromDate, fromHour,
            toDate, toHour,
            inProgress,
        } = this.state
        const sites = sitesWithItem
            .sort(l => l[0] === site ? -1 : 0)//prop site first
            .map(([site]) => site)

        // const selectedSiteItems = sitesWithItem.filter(([site]) => !!selectedSites[site.id])
        const selectedSiteItems = [[site, item]]

        const customMessageHtml = item.config ? item.config.custom_unavailability_dialog_message : undefined;

        const watch = async () => {
            try {
                this.setState({ inProgress: true })
                await onWatch(`${fromDate} ${fromHour}`, `${toDate} ${toHour}`, selectedSiteItems)
                ToastNotification.success('Saved')
                onClose()
            } catch (err) {
                console.log('Error setting watcher', err)
                ToastNotification.error('Error subscribing for notification. Please try again later')
            } finally {
                this.setState({ inProgress: false })
            }
        }
        const stopWatching = async () => {
            try {
                this.setState({ inProgress: true })
                await onStopWatching(activeWatcher.groupId)
                ToastNotification.success('Done')
                onClose()
            } catch (err) {
                console.log('Error stopping watcher', err)
                ToastNotification.error('Error stopping watching. Please try again later')
            } finally {
                this.setState({ inProgress: false })
            }
        }

        return <Popup auto
            onClose={onClose}
            maxWidth={500}
            actions={[
                <Button flat className="modal-close"><Trans>Close</Trans></Button>,
            ]}
        // fixedFooter
        >
            <h2>
                <Icon left small>notifications</Icon>
                <Trans i18nKey="watch-availability" values={{ name: item.name }}>Watch {{ name: item.name }} availability</Trans>
            </h2>
            <div className="watcher-config">
                <div className="watcher-description" >
                    {!customMessageHtml
                        ? <Trans>There is no free slot right now. Watch to get notified when something becomes available.</Trans>
                        : <div dangerouslySetInnerHTML={{ __html: customMessageHtml }} />
                    }
                </div>
                <div className="watcher-panes">
                    <div className="watcher-params">
                        <div className="date">
                            {formatDate(fromDate)}
                        </div>
                        {!activeWatcher && <div className="time-controls">
                            <DateTimePicker
                                label='From'
                                date={fromDate}
                                hour={fromHour}
                                min='07:00'
                                max={addMinutes(toHour, -60)}
                                onChange={({ hour = fromHour, date = fromDate }) => this.setState({ fromHour: hour, fromDate: date })}
                            />
                            <DateTimePicker
                                label='To'
                                date={toDate}
                                hour={toHour}
                                min={addMinutes(fromHour, 60)}
                                max='23:00'
                                onChange={({ hour = toHour, date = toDate }) => this.setState({ toHour: hour, toDate: date })}
                            />
                        </div>}
                        {!!activeWatcher && <div className="watcher-time-period">
                            <div>{formatTime(extractTime(activeWatcher.fromDate))}</div>
                            <div>-</div>
                            <div>{formatTime(extractTime(activeWatcher.toDate))}</div>
                        </div>}
                        <div className="watcher-controls">
                            {!activeWatcher
                                ? <Button className="start-watch-button" onClick={watch} disabled={inProgress || !selectedSiteItems.length}>
                                    <Icon left>visibility</Icon>
                                    <Trans>Watch</Trans>
                                </Button>
                                : <Button className="stop-watch-button" style={{ backgroundColor: 'red' }} onClick={stopWatching} disabled={inProgress}>
                                    {/* <Icon left>clear</Icon> */}
                                    <Icon left>visibility_off</Icon>
                                    <Trans>Stop watching</Trans>
                                </Button>}
                        </div>
                        {notificationsNotSupported && <div style={{ margin: '1em 0' }}>
                            <NotificationsNotSupported />
                        </div>}
                    </div>
                    {/* <div className="watcher-clubs">
                        <ul>
                            {sites.map(ss => <li key={ss.id}>
                                <input type="checkbox" id={ss.id}
                                    checked={!!selectedSites[ss.id]}
                                    onChange={e => {
                                        console.log('e', e, e.target.checked)
                                        this.setState({
                                            selectedSites: {
                                                ...selectedSites,
                                                [ss.id]: e.target.checked,
                                            }
                                        })
                                    }}
                                />
                                <label htmlFor={ss.id}>
                                    <img className="logo-small" alt={`${ss.name} logo`} src={ss.logoURL} />
                                    <span>{ss.name}</span>
                                </label>
                            </li>)}
                        </ul>
                    </div> */}
                </div>
                <HowItWorksHint>
                    <Trans i18nKey="watchlist-hint"></Trans>
                </HowItWorksHint>
            </div>
        </Popup>
    }
}

export const WatcherConfigPopup = connect(
    (state, props) => {
        const { site, item } = props
        const { notifications } = state.user_profile
        const notificationsNotSupported = !notifications.supported
        return {
            site, item,
            sitesWithItem: selectorSitesWithItemInCity(state, item.name),
            notificationsNotSupported,
        }
    },
    () => {
        return {
            onWatch: async (fromDatetime, toDatetime, sitesItems) => {
                console.log('Watch', { fromDatetime, toDatetime, sitesItems })
                const watchItems = sitesItems.map(([site, item]) => ({
                    siteId: site.id,
                    cond: item.id.toString(),
                }))
                await watchResource(fromDatetime, toDatetime, watchItems)
            },
            onStopWatching: async (groupId) => {
                console.log('Stop watching', { groupId })
                await stopWatchResource(groupId)
            },
        }
    }
)(__WatcherConfig)
