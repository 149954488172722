import React, { PureComponent } from 'react'
import { Trans } from 'react-i18next'
import './HowItWorksHint.css'

export default class HowItWorksHint extends PureComponent {
    state = {
        expanded: false,
    }
    render() {
        const { expanded } = this.state
        const { children, title } = this.props
        if (!expanded) return <div className="how-it-works-hint">
            <button className="link-button" onClick={() => this.setState({ expanded: true })}><Trans>{title || 'How it works?'}</Trans></button>
        </div>
        return <div className="how-it-works-hint expanded">
            {children}
            &nbsp;<button className="link-button" onClick={() => this.setState({ expanded: false })}>OK</button>
        </div>
    }
}